.js-bg-change:has(.earth) {
  $opacity: 0.6;

  @include section-noise($opacity);
 
  &::after {
    opacity: #{'calc('$opacity' * var(--opacity, 0))'};
  }
}

.earth {
  @include make-section;
  position: relative;
  z-index: 3;
  &__wrapper {
    position: relative;
  }
  &-img {
    position: absolute;
    top: 43%;
    max-width: 573px;
    width: 50%;
    right: 0;
    @include media-breakpoint-up(md) {
      top: 50%;
    }
    
    @include media-breakpoint-down(md) {
      max-width: 250px;
    }
    
    @include media-breakpoint-down(sm) {
      max-width: 140px;
    }
    
    @include media-breakpoint-down(sm) {
      transform: rotate(-20deg);
      right: -17px;
    }
    &::before {
      content: '';
      display: block;
      padding-bottom: 85%;
    }
    img {
      position: absolute;
      width: 50%;
      transition: transform 0.8s;
    }
    &__bottom {
      bottom: 0;
      left: 0;
    }
    &__top {
      top: 0;
      right: 0;
      transform: translate(-73%, 36%);
      .is-active & {
        transform: translate(0, 0);
      }
    }
    @include media-breakpoint-down(sm) {
      &__top {
        transform: none;
      }
      &__bottom {
        transform: translate(73%, -36%);
        .is-active & {
          transform: translate(0, 0);
        }
      }
    }
  }
  &__title {
    @include make-responsive-title(200px, 42px, 1.02);
    @include media-breakpoint-down(sm) {
      max-width: 73%;
    }
    
    span {
      display: block;
  
      @include media-breakpoint-up(lg) {
        display: inline;
      }
    }
  }
  &__subtitle {
    @include make-responsive-title(140px, 34px, 1);
  }
  &__subtitle {
    color: $retro-red;
  }

  .earth-posters {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-top: 230px;
    @include media-breakpoint-down(sm) {
      margin-top: 60px;
    }
    &__item {
      flex-shrink: 0;
      flex-basis: 50%;
      padding: 0 7%;
      @include media-breakpoint-down(sm) {
        flex-basis: 100%;
        padding: 0 50px;
        max-width: 475px;
      }
      img {
        width: 100%;
        height: auto;
        mask-image: url(../img/mask-poster-1.svg);
        mask-size: cover;
        mask-repeat: round;
        transform: rotate(-20deg);
        backface-visibility: hidden;
      }
    }
  }
}