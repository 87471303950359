.fr-article {
	@extend .section;
	@include section-margin(top);
	min-height: 95vh;
	color: $white;

	.section {
		&__background {
			background-color: $primary-dark;
			@include section-noise(0.6);
			@include section-mask(top);
		}

	}

	&__title {
		@extend .section__title;
		color: $white;
		margin-bottom: 0.6em;
	}
}

.fr-article__grid {
	display: flex;
	align-items: center;
	@include media-breakpoint-down(md) {
		flex-direction: column;
		justify-content: center;
	}

}
.fr-article__content {
	width: calc(70% - 80px);
	p {
		margin-top: 50px;
	}
	@include media-breakpoint-down(xl) {
		width: calc(60% - 60px);
	}
	@include media-breakpoint-down(md) {
		width: 100%;
		p {
			margin-top: 20px;
		}
	}
}
//.fr-article__image {
//	margin-left: 80px;
//	width: 30%;
//	display: flex;
//	justify-content: right;
//	& img {
//		height: 100%;
//		object-fit: cover;
//	}
//	.js-scale-anim {
//		opacity: 0;
//		transform: scale(0.5);
//	}
//	@include media-breakpoint-down(xl) {
//		margin-left: 60px;
//		width: 40%;
//		& img {
//			height: auto;
//		}
//	}
//	@include media-breakpoint-down(md) {
//		width: 90%;
//		margin-top: 40px;
//		margin-left: 0;
//		display: flex;
//		justify-content: center;
//	}
//}

.fr-article__image {
	margin-left: auto;
	overflow: hidden;
	@include media-breakpoint-down(md) {
		margin-right: auto;
		margin-top: 40px;
		width: 100%;
		text-align: center;

		img {
			width: 100%;
			max-width: 480px;
		}
	}
}

.fr-article__text {
	@include media-breakpoint-up(lg) {
		max-width: 80%;
	}
}