section.light {
  background-color: $cream;
  min-height: 100vh;
  padding-top: 140px;
  padding-bottom: 160px;
}

h2.title {
  font-size: 100px;
  color: $primary-dark;
  &.large {
    font-size: 15vmin;
    max-width: 1720px;
  }
}

.subtitle {
  font-size: 10vmin;
  color: $retro-red;
}

.sbs-block {
  display: flex;
  align-items: flex-start;
  &:not(:first-child) {
    margin-top: 80px;
    @include media-breakpoint-down(sm) {
      margin-top: 30px;
    }
  }
  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;
  }
}