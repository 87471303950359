.plane {
	color: $white;
	position: relative;
	z-index: 5;
	min-height: 70vh;
	
	@extend .section;
	@include section-margin(both);
	
	@include media-breakpoint-up(lg) {
		@include section-fullheight-mask(both);
		min-height: 100vh;
	}
	
	.section__background {
		@include section-mask(both);
		@include section-paper();
		@include section-noise();

		background-color: $primary-dark;
	}

	&__image {
		position: absolute;
		width: 50%;
		height: 50%;

		@include media-breakpoint-up(lg) {
			height: calc(50% + #{"min(#{get-vw(220px)}, #{get-vh(220px)})"});
			max-height: calc(50vh + #{"min(#{get-vw(220px)}, #{get-vh(220px)})"});
		}

		&--plane {
			right: 0;
			top: 0;
			width: 60%;
			text-align: right;
		}

		&--camera {
			left: 0;
			bottom: 0;
			text-align: left;
		}

		img {
			height: 100%;
			object-fit: contain;
		}
	}

	&__inner {
		position: relative;
		z-index: 1;
	}

	&__title {
		@include make-responsive-title(220px, 56px, 1);

		margin: auto;
		
		@include media-breakpoint-up(sm) {
			width: em(1720, 220px);
		}

		.align-left {
			display: block;
			text-align: left;
		}

		.align-right {
			display: block;
			text-align: right;
		}
	}
}