.image-slider {
	@extend .section;
	display: flex;
	flex-direction: column-reverse;
	padding-bottom: 0;
	margin-bottom: 13vw;

	@include media-breakpoint-down(sm) {
		padding-top: 0;
	}

	@include media-breakpoint-up(lg) {
		display: block;
	}

	&__title, &__subtitle {
		text-align: center;

		@include media-breakpoint-up(lg) {
			text-align: left;
		}
	}
	
	.section__background {
		@include media-breakpoint-up(sm) {
			background-color: $dark-sand;
		}
	}

	.section__foreground {
		@include media-breakpoint-down(sm) {
			display: flex;
			flex-direction: column-reverse;
		}
	}

	&__title {
		margin-bottom: 20px;
		@include make-section-title(97px);

		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
			max-width: 91%;
			&:after {
				content: '';
				display: inline-block;
				width: 60%;
				height: 0.1em;
			}
		}
		
		strong {
			font-weight: 900;
			
			@include media-breakpoint-up(lg) {
				display: block;
			}
		}
	}

	&__subtitle {
		
		@include media-breakpoint-up(lg) {
			width: 40%;
			margin: em(-105) get-vw(147, 1920) 0 auto !important;
		}
	}

	&__images-wrap {
		margin-bottom: 86px;

		@include media-breakpoint-up(md) {
			margin-bottom: 0;
			transform: translateY(50%);
		}
	}

	&-images {
		display: flex;
		align-items: center;

		&__item {
			height: auto;
			flex-shrink: 0;
			flex-basis: 60%;
			margin: 0 5%;

			@include media-breakpoint-up(lg) {
				flex-basis: get-abstract-width(511px);
				margin: 0 get-abstract-width(30px);
			}

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}
}