.fr-medicine-wrap {
	position: relative;
    z-index: 1;
}

.fr-medicine {
	@include section-margin(both);
	// @include section-margin(top);
	overflow: hidden;
	position: relative;
	@include media-breakpoint-down(sm) {
		max-height: none;
	}

	img {
		aspect-ratio: var(--aspect-ratio);
	}
	
	&--inner {
		@extend .section;
		min-height: 100vh;
		background-color: $cream;
		@include section-mask(both);
		@include media-breakpoint-down(sm) {
			min-height: initial;
		}
	}

	.section__background {
		background-color: $cream;
		@include section-mask(both);
		z-index: -1;
	}

	.section__background-helper {
		position: absolute;
		left: 0;
		height: 160px;
		width: 100%;
		z-index: -1;

		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: var(--after-background);
			opacity: var(--after-opacity);
		}

		&--top {
			top: -1px;
		}
		
		&--bottom {
			bottom: -1px;
		}

		// &.disabled {
		// 	display: none;
		// }
	}
}
.fr-medicine__grid {
	display: flex;
	align-items: center;
	@include media-breakpoint-down(sm) {
		flex-direction: column;
	}
}
.fr-medicine__title {
	// font-size: 5vmax;
	@include make-section-title();
}
.fr-medicine__content {
	margin-right: 50px;
	width: calc(65% - 50px);
	@include media-breakpoint-down(sm) {
		width: 100%;
		margin-right: auto;
	}
}
.fr-medicine__images {
	position: absolute;
	right: 0;
	top: 0;
	width: 35%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	overflow: hidden;
	//align-items: center;
	@include media-breakpoint-down(sm) {
		position: relative;
		width: 100%;
	}
}
.vertical-scroll {
	backface-visibility: hidden;

	&--first {
		width: 60%;
		@include media-breakpoint-down(sm) {
			display: flex;
			width: 100%;
		}
	}

	&--second {
		width: calc(40% - 20px);
		margin-left: 20px;
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	&__item{
		margin-bottom: 40px;
		&:last-child {
			margin-bottom: 0;
		}
		img {
			@include mask-image(3, 0);
			width: 20vw;
			max-width: 500px;
			object-fit: contain;
		}
		@include media-breakpoint-down(xl) {
			margin-bottom: 20px;
			width: 22vw;
		}
		@include media-breakpoint-down(sm) {
			margin-right: 20px;
			width: 190px;
			margin-top: 40px;
			margin-bottom: 0;
			img {
				width: 190px;
				height: 100%;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
}