// Header common styles.
.header {
	@include make-section-typography(24px, 14px);
	font-family: $ff-CeraPro;
	text-transform: uppercase;
	font-weight: $fw-bold;
	position: absolute;
	z-index: 6;
	left: 0;
	top: 0;
	width: 100%;
	padding: 18px 0;
	color: $retro-red;
	&__inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	
	@include media-breakpoint-up(lg) {
		padding: 5vh 0;
	}
	
	p {
		margin-bottom: 0;
	}
	
	&__label {
		display: flex;
		align-items: center;
		font-family: inherit;
		font-size: inherit;
		margin: 0;
		flex-shrink: 0;
		
		img {
			display: block;
			margin-right: 12px;
		}
	}
	&__arrow {
		height: 2px;
		background-color: $retro-red;
		margin-left: 60px;
		margin-right: 65px;
		position: relative;
		display: flex;
		align-items: center;
		flex-grow: 1;
		justify-content: flex-end;
		@include media-breakpoint-down(sm) {
			display: none;
		}
		img {
			position: relative;
			right: -2px;
		}
	}
}