// Grid.
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px
);
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px
);
//$grid-gutter-width: 20px;

$container-padding: 15px;
$container-padding-desktop: 5%;

// Font families.
$ff-Brice: 'Brice';
$ff-CabinetGrotesk: 'Cabinet Grotesk';
$ff-CeraPro: 'Cera Pro';

// Font sizes.
$fs-xs: 18px;
//$fs-sm: 14px;
$fs-md: 32px;
//$fs-lg: 18px;
//$fs-xl: 20px;

$browser-context: $fs-xs;

$ff-title: $ff-Brice;

// Font weights.
$fw-thin: 100;
$fw-extralight: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 700;
$fw-extrabold: 800;
$fw-black: 900;

// Line heights.
$lh: 1.4;
$lh-title: 1;

// Colors.
$white: #fff;
$black: #000;
$retro-red: #C12F0F;
$retro-paper: #F8EBC1;
$cream: #FFFCF2;
$sand: #F1E5C1;
$dark-sand: #F2E9D5;
$primary-dark: #222222;
$blue: #4672FD;
$thunder: #433543;

// Others.
$tr: 0.35s;
//$radius: 5px;