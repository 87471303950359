.love-n-robots {
  @include make-section-typography();
  @include make-section;
  &__inner {
    @include make-row(0);
    align-items: center;
    justify-content: center;
    @include media-breakpoint-up(lg) {
      justify-content: space-between;
    }
  }
  &__content {
    @include media-breakpoint-up(lg) {
      @include make-col(6)
    }
  }
  &__image {
    margin-top: 60px;
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      @include make-col(8);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(5);
    }
    @include media-breakpoint-up(lg) {
      @include make-col-auto;
      max-width: 50%;
      padding: 20px;
      margin-top: 0;
      margin-left: auto;
    }
  }
  &__title {
    @include make-section-title();
    margin-bottom: 0.6em;
    font-weight: 400;
  }
}