.hero {
	padding: 70px 0 40px;
	color: $retro-red;
	position: relative;
	z-index: 5;
	min-height: 70vh;

	display: flex;
    flex-direction: column;
    justify-content: center;
	
	@include media-breakpoint-up(lg) {
		padding: 14vh 0 5vh;
	}

	&::before {
		top: 0;
	}
	
	.section__background {
		@include section-paper();
		@include section-noise();
		background-color: #EEE1B9;
	}

	&-title {
		@include make-responsive-title(288px, 86px, 1);
		
		@include media-breakpoint-up(lg) {
			margin: auto;
		}

		&__rocket {
			display: block;
			position: absolute;
			z-index: 1;
			top: 50%;
			right: -$container-padding;
			transform: translateY(-50%);

			@include media-breakpoint-up(lg) {
				left: 53%;
				transform: translate(-50%, -50%);
				width: 2.5em;
				height: 2.5em;
			}

			img {
				display: block;
				object-fit: contain;
				font-size: 1rem;

				max-width: calc(375px + (662 - 375) * ((100vw - 380px) / (1980 - 380)));
				max-height: calc(329px + (648 - 329) * ((100vw - 380px) / (1980 - 380)));

				@include media-breakpoint-up(lg) {
					width: 100%;
					height: 100%;
					position: absolute;
				}
			}
		}

		& > div,
		& > div > div,
		& > div > span > div {
			overflow: hidden;
		}

		& > div {

			// span {
			// 	display: block;

			// 	@include media-breakpoint-up(lg) {
			// 		display: initial;
			// 	}
			// }

			&:nth-of-type(2) span,
			&:nth-of-type(3) {
				position: relative;
				z-index: 1;
			}

			div {
				margin-bottom: 0;

				span {
					display: inline-block;
				}
			}
			
			&:nth-of-type(2) {
				@include media-breakpoint-between(sm, md) {
					padding-left: 0.6em;
				}
				
				@include media-breakpoint-up(lg) {
					text-align: right;
				}
				
				// div {
				// 	@include media-breakpoint-up(xl) {
				// 		display: inline-block;
				// 	}
				// }
			}
		}
	}
}