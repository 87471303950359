.today-dreams {
  @include make-section;
  @include make-section-typography();
  &__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  &__title {
    @include make-section-title(120px, 42px, 0.9);
    margin-bottom: 0;
    
    strong {
      display: block;
    }
    
    &--red {
      color: $retro-red;
      padding-bottom: 20px;
      overflow: hidden;
    }
  }
  &__head {
    @include make-col(12);
    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }
  }
  &__head-offset {
    padding-left: percentage(divide(1, 12));
  }
  &__content {
    @include make-col(12);
    @include media-breakpoint-down(sm) {
      padding-left: percentage(divide(1, 12));
    }
  }
  @include media-breakpoint-up(sm) {
    &__head {
      @include make-col(7);
    }
    &__content {
      @include make-col(5);
    }
  }
  &-images {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    margin-top: get-abstract-size(160px);
    @include media-breakpoint-down(sm) {
      margin-top: 40px;
    }
    &__item {
      flex-shrink: 0;
      // display: inline-block;
      text-align: center;
      max-width: get-abstract-size(212px, 377px);
      padding: 0 get-abstract-size(20px);
      // &:not(:last-child) {
      //   // padding-left: 40px;
      // }

      @include media-breakpoint-down(md) {
        width: get-abstract-size(210px, 375px);

        padding: 0 get-abstract-size(10px);
      }

      @include media-breakpoint-up(lg) {
        max-width: get-abstract-size(490px);
      }

      img {
        mask-image: url(../img/today-dream/todays-dream-mask.svg);
        mask-size: cover;
        mask-repeat: round;
        // max-height: 338px;
        // width: auto;
        width: 100%;
        display: block;
        // aspect-ratio: 1.45;//(490 / 338);
        height: get-vw(148px, 375px);

        object-fit: cover;

        @include media-breakpoint-up(lg) {
          height: get-vw(338px);
        }
      }
    }
  }
}