.oval-thumb-section {
    @extend .section;
    min-height: 100vh;

    .section {
        &__background {
            background-color: $cream;
        }
    }
    
    &__title {
        @extend .section__title--sm;
        // @include make-section-title(80px);
        margin-bottom: em(60, 80);

        span {
            color: $retro-red;
        }
    }

    p {
        &:not(:last-child) {
            margin-bottom: 1em;
        }
    }
    
    .oval-thumb {
        display: block;
        width: 100%;
        background: $sand;
        border-radius: 50%;
        overflow: hidden;
        margin: auto;
        position: relative;
        max-width: 360px;
        transform: translate3d(0,0,0); // Fix macos animation
        backface-visibility: hidden;

        @include media-breakpoint-up(md) {
            max-width: 480px;
        }

        @include media-breakpoint-up(lg) {
            max-width: 660px;
        }

        &::before {
            content: '';
            display: block;
            height: 0;
            padding-bottom: 114.616%;
        }

        img {
            position: absolute;
            left: 0;
            display: block;
            width: 100%;
            top: 0;
            height: 100%;
            -o-object-fit: contain;
            object-fit: cover;
            backface-visibility: hidden
        }
    }

    .row {
        @include make-row();
        @include media-breakpoint-up(lg) {
            flex-direction: row-reverse;
            @include make-row(60px);
        }
        @include media-breakpoint-up(xl) {
            @include make-row(120px);
        }
    }

    .col-content {
        @include make-col-ready();
        margin-bottom: 40px;

        @include media-breakpoint-up(lg) {
            @include make-col-ready(60px);
            @include make-col(7);
            margin-bottom: 0;
        }

        @include media-breakpoint-up(xl) {
            @include make-col-ready(120px);
        }
    }

    .col-thumb {
        @include make-col-ready();
        @include media-breakpoint-up(lg) {
            @include make-col(5);
            @include make-col-ready(60px);
        }
        @include media-breakpoint-up(xl) {
            @include make-col-ready(120px);
        }
    }
}