@mixin section-mask($mask: both) {
    --mask-edge-size: 28px;
    --mask-size: 30px;
    @include media-breakpoint-up(md) {
        --mask-edge-size: 43px;
        --mask-size: 45px;
    }
    @include media-breakpoint-up(lg) {
        --mask-edge-size: 58px;
        --mask-size: 60px;
    }

    $maskTop: url(../img/mask-top.svg);
    $maskBottom: url(../img/mask-bottom.svg);
    $maskRect: url(../img/mask-rect.svg);

    @if $mask == top {
        mask-image: $maskRect, $maskTop;
        mask-position: 0 var(--mask-edge-size), top;
        mask-size: 100% calc(100% - var(--mask-edge-size)), 100% var(--mask-size);
    }
    @else if $mask == bottom {
        mask-image: $maskRect, $maskBottom;
        mask-position: 0 0, bottom;
        mask-size: 100% calc(100% - var(--mask-edge-size)), 100% var(--mask-size);
    }
    @else if $mask == both {
        mask-image: $maskRect, $maskTop, $maskBottom;
        mask-position: 0 var(--mask-edge-size), top, bottom;
        mask-size: 100% calc(100% - (2 * var(--mask-edge-size))), 100% var(--mask-size), 100% var(--mask-size);
    }

    mask-repeat: no-repeat;
    backface-visibility: hidden;
}

@mixin section-margin($margin: false) {
    --mask-size: 30px;
    @include media-breakpoint-up(md) {
        --mask-size: 45px;
    }
    @include media-breakpoint-up(lg) {
        --mask-size: 60px;
    }

    @if $margin == top {
        margin-top: calc(-1 * var(--mask-size));
    }
    @else if $margin == bottom {
        margin-bottom: calc(-1 * var(--mask-size));
    }
    @else if $margin == both {
        margin-top: calc(-1 * var(--mask-size));
        margin-bottom: calc(-1 * var(--mask-size));
    }
}

@mixin section-fullheight-mask($margin: false) {
    --mask-size: 30px;
    @include media-breakpoint-up(md) {
        --mask-size: 45px;
    }
    @include media-breakpoint-up(lg) {
        --mask-size: 60px;
    }
    
    @if $margin == top or $margin == bottom {
        min-height: calc(100vh + var(--mask-size));
    }
    @else if $margin == both {
        min-height: calc(100vh + (2* var(--mask-size)));
    }
}

@mixin section-paper() {
    &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        mix-blend-mode: hard-light;
        background-image: url(../img/paper-texture.png);
        background-position: center;
        background-size: cover;
    }
}

@mixin section-noise($opacity: 0.1) {
    &::after {
        content: '';
        pointer-events: none;
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: $opacity;
        left: 0;
        top: 0;
        background-image: url(../img/noise-effect.png);
    }
}

@mixin section-shadow() {
    filter: drop-shadow(0px 0px 6px rgba($black, 0.35));
}

@mixin section-shadow-inset($shadow: both) {
    $maskBottom: url(../img/mask-top.svg);
    $maskTop: url(../img/mask-bottom.svg);
    $maskRect: url(../img/mask-rect.svg);

    $top: false;
    $bottom: false;
    @if $shadow == both {
        $top: true;
        $bottom: true;
    }
    @else if $shadow == top {
        $top: true;
    }
    @else if $shadow == bottom {
        $bottom: true;
    }

    --size: 30px;
    --edge-size: 28px;
    @include media-breakpoint-up(md) {
        --size: 45px;
        --edge-size: 43px;
    }
    @include media-breakpoint-up(lg) {
        --size: 60px;
        --edge-size: 58px;
    }

    &::before, &::after {
        overflow: hidden;
        --size: inherit;
        pointer-events: none;
        position: absolute;
        z-index: 2;
        height: calc(var(--size) + 12px);
        width: 100%;
        left: 0;
        background-repeat: no-repeat;
        background-size: 100% var(--size);
        filter: drop-shadow(0px 0 6px rgba(0,0,0,0.35));
        mask-repeat: no-repeat;
        mask-size: 100% 100%, 100% calc(var(--size));
    }

    @if $top {
        &::before {
            content: '';
            background-image: $maskTop;
            top: -2px;
            mask-position: 0 var(--size), 0 0;
            mask-image: $maskRect, $maskBottom;
            background-position: 0 -2px;
        }
    }
    @if $bottom {
        &::after {
            content: '';
            background-image: $maskBottom;
            bottom: -2px;
            background-position: 0 12px;
            mask-image: $maskRect, $maskTop;
            mask-position: 0 calc(-1 * var(--size)), 0 10px;
        }
    }
}

.section-shadow-inset {
    @include section-shadow-inset(top);
}

@mixin make-typography($font-size-responsive: 32px, $font-size-mobile: 18px,  $breakpoint: lg) {
    font-size: $font-size-mobile;
    @include media-breakpoint-up($breakpoint) {
        font-size: get-vw($font-size-responsive);
    }
}

@mixin make-section-typography($font-size-responsive: 32px, $font-size-mobile: 18px, $breakpoint: lg) {
    @include make-typography($font-size-responsive, $font-size-mobile, $breakpoint);
}

@mixin make-responsive-title($font-size-responsive: 100px, $font-size-mobile: 42px, $line-height: 1.1, $breakpoint: lg) {
    font-family: 'Brice';
    font-style: normal;
    font-weight: $fw-black;
    font-size: $font-size-mobile;
    line-height:  $line-height;
    text-transform: uppercase;
    
    @include media-breakpoint-up(lg) {
        width: em(1720, $font-size-responsive);
        font-size: #{"min(#{get-vw($font-size-responsive)}, #{get-vh($font-size-responsive)})"};
    }
}

@mixin make-section-title($font-size-responsive: 100px, $font-size-mobile: 42px, $line-height: 1.1, $breakpoint: lg) {
    font-family: 'Brice';
    font-style: normal;
    font-weight: $fw-black;
    font-size: $font-size-mobile;
    line-height:  $line-height;
    text-transform: uppercase;
    
    @include media-breakpoint-up(lg) {
        font-size: get-vw($font-size-responsive);
    }

    span {
        color: $retro-red;
    }
}

@mixin make-section($padding: both, $size: 160px) {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    
    @include make-section-padding($padding, $size);
}

@mixin make-section-padding($padding: both, $size: 160px, $mq: all) {
    $size-md: floor($size * 0.625);
    $size-xs: floor($size * 0.38);

    @if ($mq == all or $mq == xs) {
        @if $padding == top {
            padding-top: $size-xs;
        }
        @else if $padding == bottom {
            padding-bottom: $size-xs;
        }
        @else {
            padding-top: $size-xs;
            padding-bottom: $size-xs;
        }
    }

    @if ($mq == all or $mq == md) {
        @include media-breakpoint-up(md) {
            @if $padding == top {
                padding-top: $size-md;
            }
            @else if $padding == bottom {
                padding-bottom: $size-md;
            }
            @else {
                padding-top: $size-md;
                padding-bottom: $size-md;
            }
        }
    }

    @if ($mq == all or $mq == xl) {
        @include media-breakpoint-up(xl) {
            @if $padding == top {
                padding-top: $size;
            }
            @else if $padding == bottom {
                padding-bottom: $size;
            }
            @else {
                padding-top: $size;
                padding-bottom: $size;
            }
        }
    }
}

@mixin make-section-background() {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

@mixin make-section-foreground() {
    position: relative;
    z-index: 1;
}

@mixin mask-image($number : 1, $angle: 20deg) {
    $mask-start: 1;
    $mask-end: 4;
    $mask-ext: '.svg';
    $name: 1;
    
    @for $i from $mask-start through $mask-end {
        @if $number % $i == 0 {
            $name: $number;
        }
    }
    $url: 'mask-poster-' + $name + $mask-ext;
    width: 100%;
    height: auto;
    mask-image: url('../img/' + $url);
    mask-size: cover;
    mask-repeat: round;
    transform: rotate($angle);
    backface-visibility: hidden;
}