.side-by-side {
  $shadow: 19px 21px 90px rgba(0, 0, 0, 0.19);
  $drop-shadow: drop-shadow(19px 21px 90px rgba(0, 0, 0, 0.19));;

  // @include make-section-typography();
  .sbs-item {
    &__inner {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    &__title {
      @include make-section-title();
      
      strong {
        display: block;
  
        @include media-breakpoint-up(sm) {
          display: inline;
        }
      }
      
      .ease-left {
        color: inherit;
      }
    }
    &__title--strong {
      @include make-section-title(140px, 62px);
      color: $retro-red;
    }
    .sbs-item__content {
      margin-bottom: 30%;
      @include media-breakpoint-up(sm) {
        @include make-col(6);
        margin-bottom: 0;
      }
    }
  }

  .sbs-item--mars {
    padding: 160px 0 50px;
    @include media-breakpoint-down(sm) {
      padding-top: 60px;
      .sbs-item__title {
        margin-bottom: 0;
      }
    }
    &__head {
      @include media-breakpoint-up(sm) {
        @include make-col(4);
      }
    }
    .sbs-item__content {
      margin-bottom: 0;
      @include media-breakpoint-up(sm) {
        @include make-col(7);
        margin-left: auto;
      }
    }

    .sbs-item__image {
      width: 100%;
      margin-top: 20px;
      border-radius: 6px;
      @include media-breakpoint-up(md) {
        margin-top: 80px;
        border-radius: 26px;
      }
    }
    .ease-left {
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }

  .sbs-item--neptune {
    padding-top: 10px;
    @include media-breakpoint-up(lg) {
      padding-top: 300px;
    }
    .sbs-item__title span {
      color: $blue;
    }
    .sbs-item__content {
      margin-left: auto;
    }
    .sbs-item__image {
      position: relative;
      order: 1;
      @include media-breakpoint-up(sm) {
        @include make-col(5);
        order: 0;
      }
    }
    .sbs-item-frog {
      &__bg {
        width: 100%;
        border-radius: 100px 26px;
      }
      &__planet {
        position: absolute;
        left: 0;
        top: -20%;
        width: 70%;
        border-radius: 50%;
        // box-shadow: $shadow;
        filter: $drop-shadow;
      }
      &__frog {
        position: absolute;
        left: 10%;
        top: -31%;
        width: 50%;
      }
    }
  }

  .sbs-item--pluto {
    margin-top: 60px;
    @include media-breakpoint-up(lg) {
      margin-top: 210px;
    }
    .sbs-item__content {
      margin-right: auto;
    }
    .sbs-item-bat {
      position: relative;
      @include media-breakpoint-up(sm) {
        @include make-col(5);
      }
      &__bg {
        width: 100%;
        border-radius: 26px 100px;
      }
      &__planet {
        position: absolute;
        right: 0;
        top: -20%;
        width: 70%;
      }
      &__bat {
        position: absolute;
        width: 50%;
        transform: rotate(-19deg);
        &--top {
          top: -30%;
          right: 15%;
          transform: rotate(-26deg);
        }
        &--right {
          top: -15%;
          right: -10%;
          transform: rotate(-53deg);
        }
        &--left {
          top: 20%;
          right: 35%;
          transform: rotate(60deg);
        }
        &--bottom {
          top: 39%;
          right: -10%;
          width: 65%;
          transform: rotate(-60deg);
        }
      }
    }
  }

  .sbs-item--jupiter {
    margin-top: 60px;
    margin-bottom: 60px;
    @include media-breakpoint-up(lg) {
      margin-top: 160px;
      margin-bottom: 160px;
    }
    .sbs-item__title {
      @include make-section-title(80px)
    }
    .sbs-item__content {
      margin-left: auto;
    }
    .sbs-item__image {
      padding: 0 49px;
      order: 1;
      @include media-breakpoint-up(sm) {
        @include make-col(5);
        order: 0;
      }
    }
    .sbs-item-jupiter {
      position: relative;
      display: inline-block;
      img {
        border-radius: 26px;
        border: 10px solid $cream;
        background-color: $cream;
        // filter: $drop-shadow;
        box-shadow: $shadow;
      }
      &__foreground {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
      }
    }
  }
}