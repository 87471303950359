.horizontal-scroll {
  $noise-opacity: 0.6;

  min-height: 100vh;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  z-index: 1;
  
  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: nowrap;
    width: 400% !important;
  }

  .title {
    @include make-section-title(70px, 42px);
    color: inherit;
    margin-bottom: 0.6em;
  }

  &__item {
    @include section-mask(bottom);
    @include make-section-typography(26px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    padding: 60px 0;
    position: relative;
    &:not(:first-child) {
      margin-top: -30px;
      @include section-noise($noise-opacity);
    }
    
    &:first-child .sbs-block {
      margin-top: 0;
    }
    @include media-breakpoint-up(md) {
      //position: absolute;
      //top: 0;
      //left: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      //mask-image: none;
      &:not(:first-child) {
        margin-top: 0;
      }
      margin-top: 0;
    }
  }

  &__item--dark {
    background-color: transparent;
    color: $white;
    &:first-child {
      @include media-breakpoint-down(md) {
        @include section-mask(both);
      }
    }
    @include media-breakpoint-down(md) {
      background-color: $primary-dark;
      @include section-noise($noise-opacity);
    }
    &.is-active {
      background-color: $primary-dark;
      @include section-noise($noise-opacity);
    }

    .sbs-block__image::before {
      content: '';
      display: block;
      padding-bottom: 71%;
    }
    .sbs-block .title {
      width: 50%;
      flex-shrink: 0;
      font-size: 6vmin;
      margin-right: 80px;
    }
  }

  .sbs-block__image {
    width: 50%;
    flex-shrink: 0;
    margin-right: 42px;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-down(sm) {
      margin-right: 0;
      margin-top: 30px;
      text-align: center;
      width: 100%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .sbs-block__images {
    display: flex;
    flex-wrap: wrap;
    margin: -14px;
    margin-top: 60px;
    @include media-breakpoint-up(md) {
      margin-right: 50px;
      margin-top: 0;
    }

    > div  {
      width: calc(50% - 28px);
      margin: 14px;
      overflow: hidden;
    }
  }

  &__item--red {
    background-color: $retro-red;
    color: $white;
    .sbs-block__image::before {
      content: '';
      display: block;
      padding-bottom: 118%;
    }
    .title {
      @include make-section-title(80px)
    }
  }

  &__item--light {
    background-color: $cream;
    @include media-breakpoint-down(sm) {
      padding: 50% 0;
    }

    .images-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;

      .item-image {
        position: absolute;
        max-height: 40vmin;
        max-width: 30%;
        mask-image: url(../img/mask-poster-1.svg);
        mask-size: cover;
        mask-repeat: round;
        backface-visibility: hidden;
  
        @include media-breakpoint-up(xl) {
          max-height: 44vmin;
          max-width: 40%;
        }
  
        &.item-image--left.item-image--top {
          left: -10%;
          top: -150px;
          transform: rotate(-26deg);
  
          @include media-breakpoint-up(lg) {
            left: -6%;
            top: -6%;
          }
  
          @include media-breakpoint-up(xl) {
            left: -16%;
            top: -204px;
            max-width: 40.46875vw  /* 777/19.2 */;
            max-height: 31.25vw  /* 600/19.2 */;
          }
          
          @include media-breakpoint-down(md) {
            top: -25px;
            max-width: 35%;
          }
          @include media-breakpoint-down(sm) {
            top: 0;
            max-width: 50%;
          }
        }
        &.item-image--left.item-image--center {
          left: -18%;
          transform: rotate(37deg) translateY(-50%);
  
          @include media-breakpoint-up(md) {
            left: -25%;
          }
          
          @include media-breakpoint-up(xl) {
            left: -21%;
            top: 55%;
            max-width: 27.5vw  /* 528/19.2 */;
            max-height: 21.25vw  /* 408/19.2 */;
          }
          
        }
        &.item-image--left.item-image--bottom {
          left: 7%;
          transform: rotate(-64deg);
          bottom: -30%;
  
          @include media-breakpoint-up(lg) {
            bottom: -15%;
          }
  
          @include media-breakpoint-up(xl) {
            bottom: -36%;
            max-width: 35.104vw  /* 674/19.2 */;
            max-height: 27.135vw  /* 521/19.2 */;
          }
  
          @include media-breakpoint-down(xl) {
            bottom: -30%;
          }
          
          @include media-breakpoint-down(md) {
            bottom: -60px;
            left: -5%;
            max-width: 35%;
          }
          @include media-breakpoint-down(sm) {
            bottom: 0;
            max-width: 50%;
            transform: rotate(6deg);
          }
        }
        &.item-image--right.item-image--top {
          right: -9%;
          top: -18%;
          transform: rotate(40deg);
  
          @include media-breakpoint-up(lg) {
            top: -5%;
          }
          
          @include media-breakpoint-up(xl) {
            top: -16%;
            max-width: 33.177vw  /* 637/19.2 */;
            max-height: 25.625vw  /* 492/19.2 */;
          }
  
          @include media-breakpoint-down(md) {
            top: -25px;
            max-width: 35%;
          }
          @include media-breakpoint-down(sm) {
            top: 0;
            max-width: 50%;
          }
        }
        &.item-image--right.item-image--center {
          right: -11%;
          transform: rotate(-40deg) translateY(-50%);
          max-width: 24%;
          top: 43%;
  
          @include media-breakpoint-up(sm) {
            max-width: 15%;
            right: -6vmin;
          }
  
          @include media-breakpoint-up(lg) {
            right: -5vmin;
          }
  
          @include media-breakpoint-up(xl) {
            right: -9%;
            top: 46%;
            max-width: 19.792vw  /* 380/19.2 */;
            max-height: 15.3125vw  /* 294/19.2 */;
          }
        }
        &.item-image--right.item-image--bottom {
          right: -10%;
          transform: rotate(-54deg);
          bottom: -26%;
  
          @include media-breakpoint-up(lg) {
            bottom: -5%;
            right: -5%;
          }
  
          @include media-breakpoint-up(xl) {
            right: -10%;
            bottom: -25%;
            max-width: 35.104vw  /* 674/19.2 */;
            max-height: 28.333vw  /* 544/19.2 */;
          }
          
          @include media-breakpoint-down(md) {
            bottom: -20px;
            right: -5%;
            max-width: 35%;
          }
          @include media-breakpoint-down(sm) {
            bottom: 0;
            max-width: 50%;
          }
        }

        &--top {
          top: 0;
        }

        &--center {
          top: 50%;
          transform: translateY(-50%);
        }

        &--bottom {
          bottom: 0;
        }

        &--left {
          left: 0;
        }

        &--right {
          right: 0;
        }
      }
      @include media-breakpoint-down(md) {
        .item-image--bottom.item-image--left {
          left: -14%;
        }
      }
    }

    .content-wrapper {
      max-width: 71%;
      margin: 0 auto;
      text-align: center;
  
      @include media-breakpoint-up(xl) {
        max-width: 57%;
      }
    }
  }
  &__item--dark:last-child {
    padding: 80px 0 125px;
    background-color: $primary-dark;
    .sbs-block {
      .title {
        @include make-section-title(76px, 42px)
      }
      
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        .title {
          width: 100%;
          margin-bottom: 20px;
        }
      }
      
      .sbs-block__content p {
        @include make-section-typography(25px);
      }
    }
  }
  .horizontal-images {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    &__item {
      max-width: 33%;
      flex-shrink: 0;
      margin: 3vh 80px 0;
      text-align: center;
      @include media-breakpoint-down(md) {
        margin: 30px;
      }
      img {
        max-height: 40vh;
        width: auto;
      }
      &:not(.no-mask) img {
        mask-image: url(../img/mask-poster-1.svg);
        mask-size: cover;
        mask-repeat: round;
        backface-visibility: hidden;
      }
      &:nth-child(even) img {
        transform: rotate(-20deg);
      }
    }
  }
}

.js-bg-change {
  @include media-breakpoint-down(md) {
    display: contents;
  }
}