h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	font-family: $ff-title;
	font-weight: $fw-bold;
	line-height: $lh-title;
	margin: 0 0 1rem;
	text-transform: uppercase;
}

h1, .h1 {
	font-size: 32px;

	@include media-breakpoint-up(sm) {
		font-size: 60px
	}
}

h2, .h2 {
	font-size: 24px;

	@include media-breakpoint-up(sm) {
		font-size: 32px
	}
}
.futurism-title {
	@extend .section__title;
	 & > div, & > span > div {
		overflow: hidden;
		line-height: 1;
	}
	&  span {
		color: $retro-red;
	}
	@include media-breakpoint-down(lg) {
		font-size: calc(80px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
		div {
			line-height: 1;
		}
	}
}
.ios .futurism-title {
	& > div, & > span > div {
		line-height: 1.02;
	}
}
h3, .h3 {
	font-size: 30px
}

h4, .h4 {
	font-size: 24px;

	//@include media-breakpoint-up(sm) {
	//	font-size: $fs-xl
	//}
}

h5, .h5 {
	//font-size: $fs-xl
}

h6, .h6 {
	//font-size: $fs-lg
}

a {
	display: inline-block;
	text-decoration: underline;
	transition: color ease-out $tr;

	&:hover {
		text-decoration: none
	}
}

p {
	margin-top: 0;
	margin-bottom: em(54, 32);

	&:last-of-type {
		margin-bottom: 0
	}
}

b, strong {
	font-weight: $fw-bold
}