.the-last-frontier {
	@extend .section;
	z-index: 1;
	padding-top: get-vw(220px, 375px);
	
	@include media-breakpoint-up(md) {
		padding-top: get-vw(140px);
	}
	
	&-images {
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		
		&__item {
			position: absolute;
			mask-repeat: no-repeat;
			mask-position: center;
			background-color: $cream;
			padding: 6px;
			
			@include media-breakpoint-up(md) {
				padding: get-vw(20px);
			}
			
			img {
				object-fit: cover;
				object-position: center;
				mask-repeat: no-repeat;
				mask-position: center;
				width: 100%;
				height: 100%;
				mask: inherit;
				display: block;
			}
			
			&--first {
				top: get-vw(50px, 375px);
				left: 3%;
				width: get-vw(112px + 12px, 375px);
				height: get-vw(108px + 12px, 375px);
				mask-image: get-mask-ellipse(-54deg, 112, 108, 102, 115);
				
				@include media-breakpoint-up(md) {
					top: get-vw(200px - 20px);
					left: initial;
					right: get-vw(216px - 20px);
					

					width: get-vw(420px);
					height: get-vw(404px);
				}
			}
			
			&--second {
				width: get-vw(154px, 375px);
				height: get-vw(144px, 375px);
				mask-image: get-mask-ellipse(62deg, 154, 144, 140, 156);

				top: get-vw(220px - 144px, 375px);
				right: get-vw(-94px, 375px);

				@at-root .section__foreground & {
					display: none;
					@include media-breakpoint-down(xs) {
						display: block;

						z-index: -1;
						transform: translateY(-100%);
						top: auto;
					}
				}

				@include media-breakpoint-down(xs) {
					display: none;
				}
								
				@include media-breakpoint-up(md) {
					top: get-vw(560px);
					right: get-vw(-160px - 20px);
					width: get-vw(465px + 20px);
					height: get-vw(442px + 20px);
				}
			}
		}
	}
	
	&__title {
		@include make-section-title(80px);
		
		@include media-breakpoint-up(md) {
			max-width: get-vw(880px);
		}

		&:not(&--large) {
			@include media-breakpoint-down(sm) {
				padding-right: get-abstract-size(55 - 15px, 375);
			}
		}
		
		&--large {
			@include make-section-title(120px, 58px);
			margin-bottom: em(10px, 58px);
		}
		
		&:first-child > div, &:first-child > span {
			overflow: hidden;
			display: inline-block;
		}
		
		
		&:nth-of-type(2) {
			@include media-breakpoint-up(md) {
				margin-bottom: 60px;
			}
		}
	}
	
	&__content {	
		@include media-breakpoint-up(md) {
			max-width: get-vw(1110px);
		}
	}
}