.red-revolution-section {
    @include make-section();
    @include make-section-typography();
    color: $white;
    min-height: 100vh;
    @extend .section;
    @include media-breakpoint-down(md) {
        padding-top: 20vw;
    }

    &::before, &::after {
        content: '';
        display: block;
        width: 100%;
        height: 80px;
        position: absolute;
        top: -20px;
        left: 0;
    }

    &::before {
        background-color: #222;
        z-index: -2;
    }

    &::after {
        background-image: url(../img/noise-effect.png);
        opacity: 0.6;
        z-index: -1;
    }

    .section {
        &__background {
            background-color: $retro-red;
            @include section-noise(0.6);
            @include section-mask(both);
        }
    }
    
    &__title {
        @include make-section-title();
        margin-bottom: em(60, 100);
        color: $cream;
        span {
            color: $primary-dark;
        }
    }
    &__content {
        @include media-breakpoint-up(lg) {
            max-width: 85%;
        }
    }

    p {
        &:not(:last-child) {
            margin-bottom: 1em;
        }
    }

    .row {
        @include make-row();
        @include media-breakpoint-up(lg) {
            @include make-row(40px);
        }
    }

    .col-content {
        @include make-col-ready();
        margin-bottom: 40px;

        @include media-breakpoint-up(lg) {
            @include make-col-ready(40px);
            @include make-col(12 - 2.8);
            margin-bottom: 0;
        }
    }

    .col-img {
        @include make-col-ready();
        @include media-breakpoint-down(md) {
            max-width: 400px;
            margin: auto;
        }
        @include media-breakpoint-up(lg) {
            @include make-col(2.8);
            @include make-col-ready(40px);
        }

        img {
            display: block;
            aspect-ratio: var(--aspect-ratio);

            &:first-child {
                width: get-abstract-width(314px, 404px);
            }

            &:not(:last-child) {
                margin-bottom: 40px;
            }

            margin-left: auto;
            margin-right: auto;
            @include media-breakpoint-up(lg) {
                margin-right: 0;
            }
        }
    }
}
