.space-adventures-wrap {
    
    @include media-breakpoint-up(md) {
        padding-top: em(50);
    }
    
    @include media-breakpoint-up(lg) {
    }
}

.space-adventures-section {
    @extend .section;
    position: relative;
    min-height: 100vh;
    .section {
        &__foreground {
            position: static;
        }

        &__content {
            @include media-breakpoint-up(md) {
                width: 55vw;
            }
            @include media-breakpoint-up(xl) {
                width: get-vw(990px);
            }
        }
    }

    .gallery {
        overflow: hidden;
        width: 100%;
        height: 100vh;
        position: relative;
        padding: 0 16px;
        backface-visibility: hidden;
        min-width: 420px;
        width: 80%;
        margin: 40px auto 0;
        
        @include media-breakpoint-up(md) {
            padding: 0;
            position: absolute;
            right: get-vw(-100px);
            top: 0;
            height: 100%;
            margin: auto;
            bottom: 0;
            width: 43vw;
            min-width: initial;
        }

        @include media-breakpoint-up(xl) {
            width: get-vw(780px);
        }

        &::before,
        &::after {
            $size: 160px;
            $size-xs: floor($size * 0.38);
            $size-md: floor($size * 0.625);

            content: '';
            display: block;
            width: 100%;
            left: 0;
            position: absolute;
            z-index: 1;
            
            height: $size-xs;
            @include media-breakpoint-up(md) {    
                height: $size-md;
            }
            
            @include media-breakpoint-up(xl) {
                height: $size;
            }
        }
        
        &::before {
            top: -2px;
            background: linear-gradient(0deg, rgba(255, 252, 242, 0) 0, #FFFCF2 75%);
        }

        &::after {
            bottom: -2px;
            background: linear-gradient(180deg, rgba(255, 252, 242, 0) 0, #FFFCF2 75%);
        }

        &__inner {
            height: inherit;
            display: flex;
            flex-direction: row;
            margin: 0 -10px;
        }

        &__track {
            width: 50%;
            left: 0;
            top: 0;
            max-height: 100%;
            padding: 0 10px;
            align-self: center;

            // display: flex;
            // flex-direction: column;
            // justify-content: center;

            &:nth-child(1) {
                .gallery__item:last-child {
                    margin-bottom: 25%;
                }
            }

            &:nth-child(2) {
                // left: 50%;
                .gallery__item:first-child {
                    margin-top: 25%;
                }
            }

            img {
                border-radius: 22px;
                display: block;
                aspect-ratio: var(--aspect-ratio);
                width: 100%;
            }

        }

        &__item {
            padding: 10px 0;

            &:last-child {
                padding-bottom: 0;
            }

            &:first-child {
                padding-top: 0;
            }
        }
    }
}