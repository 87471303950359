.oval-image {
	position: relative;
	z-index: 1;
	color: $white;
	@include section-margin(top);
	@include make-section();
	@include make-section-typography();
	min-height: 95vh;
	@include media-breakpoint-down(md) {
		padding-top: 275px;
		padding-bottom: 345px;
	}

	.section {

		&__background {
			background-color: $primary-dark;
			@include section-noise(0.6);
			@include section-mask(top);
		}
	}

	&__title {
		margin-bottom: 20px;
		@include make-responsive-title(100px, 42px);

		@include media-breakpoint-up(lg) {
			margin-bottom: 60px;
		}

		p {
			margin-bottom: 0;

			span {
				display: inline-block;
			}

			&:first-child span:nth-of-type(2) {

				@include media-breakpoint-up(lg) {
					padding-left: 14%;
				}
			}

			&:nth-child(2) span:first-child {

				@include media-breakpoint-up(lg) {
					padding-left: 7%;
				}
			}
		}

		span {
			color: $white;
		}
	}

	&__img {
		position: absolute;
		z-index: 1;

		&--first {
			top: 4%;
			left: -29%;
			width: 65%;

			@include media-breakpoint-up(sm) {
				width: 55%;
			}

			@include media-breakpoint-up(md) {
				width: 35%;
				left: -20%;
			}

			@include media-breakpoint-up(lg) {
				top: -3%;
				left: -8%;
				width: 23vw;
			}
		}

		&--second {
			left: -7%;
			width: 65%;
			bottom: -12vmin;

			@include media-breakpoint-up(sm) {
				width: 55%;
			}

			@include media-breakpoint-up(md) {
				width: 45%;
			}

			@include media-breakpoint-up(lg) {
				left: 50%;
				width: 28vmax;
			}
		}

		&--third {
			bottom: 14%;
			right: -15%;
			width: 55%;

			@include media-breakpoint-up(sm) {
				width: 45%;
			}

			@include media-breakpoint-up(lg) {
				bottom: initial;
				right: -5%;
				top: 20%;
				width: 27vw;
			}
		}
	}
}