.distant-stars {
  @include make-section;
  @include make-section-typography();
  &__inner {
    @include make-row(0);
  }
  &__title {
    @include make-section-title(80px);
    margin-bottom: 0.75em;
    word-break: break-word;
  
    span {
      color: $blue;
    }
  }
  &__img {
    order: 1;
    @include make-col-ready(0);
    @include media-breakpoint-up(md) {
      @include make-col(6);
      padding-right: 5%;
      order: 0;
      min-height: 100%;
    }
    @include media-breakpoint-up(lg) {
      @include make-col(5);
      padding-right: get-abstract-width(120px, 1720px);
    }
    &-wrap {
      overflow: hidden;
      border-radius: 8%;
      @include media-breakpoint-down(sm) {
        border-radius: 3.2vw;
        max-width: 480px;
        margin: auto;
      }
      @include media-breakpoint-up(md) {
        position: relative;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          position: absolute;
          object-fit: cover;
          font-family: 'object-fit: cover;';
        }
      }
    }
  }
  &__content {
    margin-bottom: 40px;
    @include make-col-ready(0);
    @include media-breakpoint-up(md) {
      @include make-col(6);
      margin-bottom: 0;
    }
    p:not(:last-child) {
      margin-bottom: 1em;
    }
  }
}