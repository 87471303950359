@font-face {
	font-family: 'Brice';
	src: url('../../fonts/brice/Brice-BlackCondensed.ttf') format('opentype');
	font-weight: 900;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Brice';
	src: url('../../fonts/brice/Brice-BoldCondensed.ttf') format('opentype');
	font-weight: 700;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Cabinet Grotesk';
	src: url('../../fonts/cabinetgrotesk/CabinetGrotesk-Black.otf') format('opentype');
	font-weight: 900;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Cabinet Grotesk';
	src: url('../../fonts/cabinetgrotesk/CabinetGrotesk-Bold.otf') format('opentype');
	font-weight: 700;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Cabinet Grotesk';
	src: url('../../fonts/cabinetgrotesk/CabinetGrotesk-Extrabold.otf') format('opentype');
	font-weight: 800;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Cabinet Grotesk';
	src: url('../../fonts/cabinetgrotesk/CabinetGrotesk-Extralight.otf') format('opentype');
	font-weight: 200;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Cabinet Grotesk';
	src: url('../../fonts/cabinetgrotesk/CabinetGrotesk-Light.otf') format('opentype');
	font-weight: 300;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Cabinet Grotesk';
	src: url('../../fonts/cabinetgrotesk/CabinetGrotesk-Medium.otf') format('opentype');
	font-weight: 500;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Cabinet Grotesk';
	src: url('../../fonts/cabinetgrotesk/CabinetGrotesk-Regular.otf') format('opentype');
	font-weight: 400;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Cabinet Grotesk';
	src: url('../../fonts/cabinetgrotesk/CabinetGrotesk-Thin.otf') format('opentype');
	font-weight: 100;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Cera Pro';
	src: url('../../fonts/cerapro/CeraPro-Black.ttf') format('truetype');
	font-weight: 900;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Cera Pro';
	src: url('../../fonts/cerapro/CeraPro-Bold.ttf') format('truetype');
	font-weight: 700;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Cera Pro';
	src: url('../../fonts/cerapro/CeraPro-Light.ttf') format('truetype');
	font-weight: 300;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Cera Pro';
	src: url('../../fonts/cerapro/CeraPro-Medium.ttf') format('truetype');
	font-weight: 500;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Cera Pro';
	src: url('../../fonts/cerapro/CeraPro-Regular.ttf') format('truetype');
	font-weight: 400;
	font-display: swap;
	font-style: normal;
}