*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.section-shadow-inset {
  --size: 30px;
  --edge-size: 28px; }
  @media (min-width: 768px) {
    .section-shadow-inset {
      --size: 45px;
      --edge-size: 43px; } }
  @media (min-width: 992px) {
    .section-shadow-inset {
      --size: 60px;
      --edge-size: 58px; } }
  .section-shadow-inset::before, .section-shadow-inset::after {
    overflow: hidden;
    --size: inherit;
    pointer-events: none;
    position: absolute;
    z-index: 2;
    height: calc(var(--size) + 12px);
    width: 100%;
    left: 0;
    background-repeat: no-repeat;
    background-size: 100% var(--size);
    filter: drop-shadow(0px 0 6px rgba(0, 0, 0, 0.35));
    mask-repeat: no-repeat;
    mask-size: 100% 100%, 100% calc(var(--size)); }
  .section-shadow-inset::before {
    content: '';
    background-image: url(../img/mask-bottom.svg);
    top: -2px;
    mask-position: 0 var(--size), 0 0;
    mask-image: url(../img/mask-rect.svg), url(../img/mask-top.svg);
    background-position: 0 -2px; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Brice";
  font-weight: 700;
  line-height: 1;
  margin: 0 0 1rem;
  text-transform: uppercase; }

h1, .h1 {
  font-size: 32px; }
  @media (min-width: 576px) {
    h1, .h1 {
      font-size: 60px; } }

h2, .h2 {
  font-size: 24px; }
  @media (min-width: 576px) {
    h2, .h2 {
      font-size: 32px; } }

.futurism-title > div, .futurism-title > span > div {
  overflow: hidden;
  line-height: 1; }

.futurism-title span {
  color: #C12F0F; }

@media (max-width: 1199.98px) {
  .futurism-title {
    font-size: calc(80px + (26 - 14) * ((100vw - 300px) / (1600 - 300))); }
    .futurism-title div {
      line-height: 1; } }

.ios .futurism-title > div, .ios .futurism-title > span > div {
  line-height: 1.02; }

h3, .h3 {
  font-size: 30px; }

h4, .h4 {
  font-size: 24px; }

a {
  display: inline-block;
  text-decoration: underline;
  transition: color ease-out 0.35s; }
  a:hover {
    text-decoration: none; }

p {
  margin-top: 0;
  margin-bottom: 1.6875em; }
  p:last-of-type {
    margin-bottom: 0; }

b,
strong {
  font-weight: 700; }

section.light {
  background-color: #FFFCF2;
  min-height: 100vh;
  padding-top: 140px;
  padding-bottom: 160px; }

h2.title {
  font-size: 100px;
  color: #222222; }
  h2.title.large {
    font-size: 15vmin;
    max-width: 1720px; }

.subtitle {
  font-size: 10vmin;
  color: #C12F0F; }

.sbs-block {
  display: flex;
  align-items: flex-start; }
  .sbs-block:not(:first-child) {
    margin-top: 80px; }
    @media (max-width: 767.98px) {
      .sbs-block:not(:first-child) {
        margin-top: 30px; } }
  @media (max-width: 767.98px) {
    .sbs-block {
      flex-direction: column-reverse; } }

html, body {
  height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  font-family: "Cabinet Grotesk";
  line-height: 1.4;
  color: #222222;
  background-color: #FFFCF2;
  font-size: 18px; }
  @media (min-width: 992px) {
    body {
      font-size: 1.66667vw; } }

.js #smooth-wrapper {
  animation: fadeIn 0.2s; }

#smooth-content {
  backface-visibility: hidden; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

img {
  max-width: 100%;
  height: auto; }

.wrapper {
  position: relative;
  overflow: hidden; }

.container,
.container-fluid {
  padding: 0 15px; }
  @media (min-width: 992px) {
    .container,
    .container-fluid {
      padding: 0 5%; } }

.section, .plane, .karl-marx, .fr-article, .fr-medicine--inner, .oval-thumb-section, .flowers-section, .tech-dev, .red-revolution-section, .image-slider, .hammer-and-sickle-section, .boldest-dreams-section, .centre-of-the-earth, .the-last-frontier, .gigantomania-section, .end-great-future-section, .space-adventures-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px; }
  @media (min-width: 768px) {
    .section, .plane, .karl-marx, .fr-article, .fr-medicine--inner, .oval-thumb-section, .flowers-section, .tech-dev, .red-revolution-section, .image-slider, .hammer-and-sickle-section, .boldest-dreams-section, .centre-of-the-earth, .the-last-frontier, .gigantomania-section, .end-great-future-section, .space-adventures-section {
      padding-top: 100px;
      padding-bottom: 100px; } }
  @media (min-width: 1200px) {
    .section, .plane, .karl-marx, .fr-article, .fr-medicine--inner, .oval-thumb-section, .flowers-section, .tech-dev, .red-revolution-section, .image-slider, .hammer-and-sickle-section, .boldest-dreams-section, .centre-of-the-earth, .the-last-frontier, .gigantomania-section, .end-great-future-section, .space-adventures-section {
      padding-top: 160px;
      padding-bottom: 160px; } }
  .section__title, .futurism-title, .fr-article__title, .flowers-section__title, .centre-of-the-earth__title {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 1.1;
    text-transform: uppercase;
    margin-bottom: 0.5em; }
    @media (min-width: 992px) {
      .section__title, .futurism-title, .fr-article__title, .flowers-section__title, .centre-of-the-earth__title {
        font-size: 5.20833vw; } }
    .section__title span, .futurism-title span, .fr-article__title span, .flowers-section__title span, .centre-of-the-earth__title span {
      color: #C12F0F; }
  .section__title--sm, .oval-thumb-section__title {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 1.1;
    text-transform: uppercase;
    margin-bottom: 0.5em; }
    @media (min-width: 992px) {
      .section__title--sm, .oval-thumb-section__title {
        font-size: 4.16667vw; } }
    .section__title--sm span, .oval-thumb-section__title span {
      color: #C12F0F; }
  .section__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; }
  .section__foreground {
    position: relative;
    z-index: 1; }
  .section__sand {
    color: #222222; }
    .section__sand .section__background {
      background-color: #F1E5C1; }
  .section__red {
    color: #fff; }
    .section__red .section__background {
      background-color: #C12F0F; }
  .section__dark {
    color: #fff; }
    .section__dark .section__background {
      background-color: #222222; }

.hero {
  padding: 70px 0 40px;
  color: #C12F0F;
  position: relative;
  z-index: 5;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media (min-width: 992px) {
    .hero {
      padding: 14vh 0 5vh; } }
  .hero::before {
    top: 0; }
  .hero .section__background {
    background-color: #EEE1B9; }
    .hero .section__background::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      mix-blend-mode: hard-light;
      background-image: url(../img/paper-texture.png);
      background-position: center;
      background-size: cover; }
    .hero .section__background::after {
      content: '';
      pointer-events: none;
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 0.1;
      left: 0;
      top: 0;
      background-image: url(../img/noise-effect.png); }
  .hero-title {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 86px;
    line-height: 1;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .hero-title {
        width: 5.97222em;
        font-size: min(15vw, 26.66667vh); } }
    @media (min-width: 992px) {
      .hero-title {
        margin: auto; } }
    .hero-title__rocket {
      display: block;
      position: absolute;
      z-index: 1;
      top: 50%;
      right: -15px;
      transform: translateY(-50%); }
      @media (min-width: 992px) {
        .hero-title__rocket {
          left: 53%;
          transform: translate(-50%, -50%);
          width: 2.5em;
          height: 2.5em; } }
      .hero-title__rocket img {
        display: block;
        object-fit: contain;
        font-size: 1rem;
        max-width: calc(375px + (662 - 375) * ((100vw - 380px) / (1980 - 380)));
        max-height: calc(329px + (648 - 329) * ((100vw - 380px) / (1980 - 380))); }
        @media (min-width: 992px) {
          .hero-title__rocket img {
            width: 100%;
            height: 100%;
            position: absolute; } }
    .hero-title > div,
    .hero-title > div > div,
    .hero-title > div > span > div {
      overflow: hidden; }
    .hero-title > div:nth-of-type(2) span, .hero-title > div:nth-of-type(3) {
      position: relative;
      z-index: 1; }
    .hero-title > div div {
      margin-bottom: 0; }
      .hero-title > div div span {
        display: inline-block; }
    @media (min-width: 576px) and (max-width: 991.98px) {
      .hero-title > div:nth-of-type(2) {
        padding-left: 0.6em; } }
    @media (min-width: 992px) {
      .hero-title > div:nth-of-type(2) {
        text-align: right; } }

.plane {
  color: #fff;
  position: relative;
  z-index: 5;
  min-height: 70vh;
  --mask-size: 30px;
  margin-top: calc(-1 * var(--mask-size));
  margin-bottom: calc(-1 * var(--mask-size)); }
  @media (min-width: 768px) {
    .plane {
      --mask-size: 45px; } }
  @media (min-width: 992px) {
    .plane {
      --mask-size: 60px; } }
  @media (min-width: 992px) {
    .plane {
      --mask-size: 30px;
      min-height: calc(100vh + (2* var(--mask-size)));
      min-height: 100vh; } }
  @media (min-width: 992px) and (min-width: 768px) {
    .plane {
      --mask-size: 45px; } }
  @media (min-width: 992px) and (min-width: 992px) {
    .plane {
      --mask-size: 60px; } }
  .plane .section__background {
    --mask-edge-size: 28px;
    --mask-size: 30px;
    mask-image: url(../img/mask-rect.svg), url(../img/mask-top.svg), url(../img/mask-bottom.svg);
    mask-position: 0 var(--mask-edge-size), top, bottom;
    mask-size: 100% calc(100% - (2 * var(--mask-edge-size))), 100% var(--mask-size), 100% var(--mask-size);
    mask-repeat: no-repeat;
    backface-visibility: hidden;
    background-color: #222222; }
    @media (min-width: 768px) {
      .plane .section__background {
        --mask-edge-size: 43px;
        --mask-size: 45px; } }
    @media (min-width: 992px) {
      .plane .section__background {
        --mask-edge-size: 58px;
        --mask-size: 60px; } }
    .plane .section__background::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      mix-blend-mode: hard-light;
      background-image: url(../img/paper-texture.png);
      background-position: center;
      background-size: cover; }
    .plane .section__background::after {
      content: '';
      pointer-events: none;
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 0.1;
      left: 0;
      top: 0;
      background-image: url(../img/noise-effect.png); }
  .plane__image {
    position: absolute;
    width: 50%;
    height: 50%; }
    @media (min-width: 992px) {
      .plane__image {
        height: calc(50% + min(11.45833vw, 20.37037vh));
        max-height: calc(50vh + min(11.45833vw, 20.37037vh)); } }
    .plane__image--plane {
      right: 0;
      top: 0;
      width: 60%;
      text-align: right; }
    .plane__image--camera {
      left: 0;
      bottom: 0;
      text-align: left; }
    .plane__image img {
      height: 100%;
      object-fit: contain; }
  .plane__inner {
    position: relative;
    z-index: 1; }
  .plane__title {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 1;
    text-transform: uppercase;
    margin: auto; }
    @media (min-width: 992px) {
      .plane__title {
        width: 7.81818em;
        font-size: min(11.45833vw, 20.37037vh); } }
    @media (min-width: 576px) {
      .plane__title {
        width: 7.81818em; } }
    .plane__title .align-left {
      display: block;
      text-align: left; }
    .plane__title .align-right {
      display: block;
      text-align: right; }

.js-bg-change:has(.earth)::after {
  content: '';
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.6;
  left: 0;
  top: 0;
  background-image: url(../img/noise-effect.png); }

.js-bg-change:has(.earth)::after {
  opacity: calc( 0.6  * var(--opacity, 0)); }

.earth {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;
  z-index: 3; }
  @media (min-width: 768px) {
    .earth {
      padding-top: 100px;
      padding-bottom: 100px; } }
  @media (min-width: 1200px) {
    .earth {
      padding-top: 160px;
      padding-bottom: 160px; } }
  .earth__wrapper {
    position: relative; }
  .earth-img {
    position: absolute;
    top: 43%;
    max-width: 573px;
    width: 50%;
    right: 0; }
    @media (min-width: 768px) {
      .earth-img {
        top: 50%; } }
    @media (max-width: 991.98px) {
      .earth-img {
        max-width: 250px; } }
    @media (max-width: 767.98px) {
      .earth-img {
        max-width: 140px; } }
    @media (max-width: 767.98px) {
      .earth-img {
        transform: rotate(-20deg);
        right: -17px; } }
    .earth-img::before {
      content: '';
      display: block;
      padding-bottom: 85%; }
    .earth-img img {
      position: absolute;
      width: 50%;
      transition: transform 0.8s; }
    .earth-img__bottom {
      bottom: 0;
      left: 0; }
    .earth-img__top {
      top: 0;
      right: 0;
      transform: translate(-73%, 36%); }
      .is-active .earth-img__top {
        transform: translate(0, 0); }
    @media (max-width: 767.98px) {
      .earth-img__top {
        transform: none; }
      .earth-img__bottom {
        transform: translate(73%, -36%); }
        .is-active .earth-img__bottom {
          transform: translate(0, 0); } }
  .earth__title {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 1.02;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .earth__title {
        width: 8.6em;
        font-size: min(10.41667vw, 18.51852vh); } }
    @media (max-width: 767.98px) {
      .earth__title {
        max-width: 73%; } }
    .earth__title span {
      display: block; }
      @media (min-width: 992px) {
        .earth__title span {
          display: inline; } }
  .earth__subtitle {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 34px;
    line-height: 1;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .earth__subtitle {
        width: 12.28571em;
        font-size: min(7.29167vw, 12.96296vh); } }
  .earth__subtitle {
    color: #C12F0F; }
  .earth .earth-posters {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-top: 230px; }
    @media (max-width: 767.98px) {
      .earth .earth-posters {
        margin-top: 60px; } }
    .earth .earth-posters__item {
      flex-shrink: 0;
      flex-basis: 50%;
      padding: 0 7%; }
      @media (max-width: 767.98px) {
        .earth .earth-posters__item {
          flex-basis: 100%;
          padding: 0 50px;
          max-width: 475px; } }
      .earth .earth-posters__item img {
        width: 100%;
        height: auto;
        mask-image: url(../img/mask-poster-1.svg);
        mask-size: cover;
        mask-repeat: round;
        transform: rotate(-20deg);
        backface-visibility: hidden; }

.horizontal-scroll {
  min-height: 100vh;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  z-index: 1; }
  @media (min-width: 768px) {
    .horizontal-scroll {
      display: flex;
      flex-wrap: nowrap;
      width: 400% !important; } }
  .horizontal-scroll .title {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 1.1;
    text-transform: uppercase;
    color: inherit;
    margin-bottom: 0.6em; }
    @media (min-width: 992px) {
      .horizontal-scroll .title {
        font-size: 3.64583vw; } }
    .horizontal-scroll .title span {
      color: #C12F0F; }
  .horizontal-scroll__item {
    --mask-edge-size: 28px;
    --mask-size: 30px;
    mask-image: url(../img/mask-rect.svg), url(../img/mask-bottom.svg);
    mask-position: 0 0, bottom;
    mask-size: 100% calc(100% - var(--mask-edge-size)), 100% var(--mask-size);
    mask-repeat: no-repeat;
    backface-visibility: hidden;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    padding: 60px 0;
    position: relative; }
    @media (min-width: 768px) {
      .horizontal-scroll__item {
        --mask-edge-size: 43px;
        --mask-size: 45px; } }
    @media (min-width: 992px) {
      .horizontal-scroll__item {
        --mask-edge-size: 58px;
        --mask-size: 60px; } }
    @media (min-width: 992px) {
      .horizontal-scroll__item {
        font-size: 1.35417vw; } }
    .horizontal-scroll__item:not(:first-child) {
      margin-top: -30px; }
      .horizontal-scroll__item:not(:first-child)::after {
        content: '';
        pointer-events: none;
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0.6;
        left: 0;
        top: 0;
        background-image: url(../img/noise-effect.png); }
    .horizontal-scroll__item:first-child .sbs-block {
      margin-top: 0; }
    @media (min-width: 768px) {
      .horizontal-scroll__item {
        width: 100%;
        height: 100%;
        padding: 0;
        margin-top: 0; }
        .horizontal-scroll__item:not(:first-child) {
          margin-top: 0; } }
  .horizontal-scroll__item--dark {
    background-color: transparent;
    color: #fff; }
    @media (max-width: 991.98px) {
      .horizontal-scroll__item--dark:first-child {
        --mask-edge-size: 28px;
        --mask-size: 30px;
        mask-image: url(../img/mask-rect.svg), url(../img/mask-top.svg), url(../img/mask-bottom.svg);
        mask-position: 0 var(--mask-edge-size), top, bottom;
        mask-size: 100% calc(100% - (2 * var(--mask-edge-size))), 100% var(--mask-size), 100% var(--mask-size);
        mask-repeat: no-repeat;
        backface-visibility: hidden; } }
  @media (max-width: 991.98px) and (min-width: 768px) {
    .horizontal-scroll__item--dark:first-child {
      --mask-edge-size: 43px;
      --mask-size: 45px; } }
  @media (max-width: 991.98px) and (min-width: 992px) {
    .horizontal-scroll__item--dark:first-child {
      --mask-edge-size: 58px;
      --mask-size: 60px; } }
    @media (max-width: 991.98px) {
      .horizontal-scroll__item--dark {
        background-color: #222222; }
        .horizontal-scroll__item--dark::after {
          content: '';
          pointer-events: none;
          position: absolute;
          height: 100%;
          width: 100%;
          opacity: 0.6;
          left: 0;
          top: 0;
          background-image: url(../img/noise-effect.png); } }
    .horizontal-scroll__item--dark.is-active {
      background-color: #222222; }
      .horizontal-scroll__item--dark.is-active::after {
        content: '';
        pointer-events: none;
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0.6;
        left: 0;
        top: 0;
        background-image: url(../img/noise-effect.png); }
    .horizontal-scroll__item--dark .sbs-block__image::before {
      content: '';
      display: block;
      padding-bottom: 71%; }
    .horizontal-scroll__item--dark .sbs-block .title {
      width: 50%;
      flex-shrink: 0;
      font-size: 6vmin;
      margin-right: 80px; }
  .horizontal-scroll .sbs-block__image {
    width: 50%;
    flex-shrink: 0;
    margin-right: 42px;
    position: relative;
    overflow: hidden; }
    @media (max-width: 767.98px) {
      .horizontal-scroll .sbs-block__image {
        margin-right: 0;
        margin-top: 30px;
        text-align: center;
        width: 100%; } }
    .horizontal-scroll .sbs-block__image img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .horizontal-scroll .sbs-block__images {
    display: flex;
    flex-wrap: wrap;
    margin: -14px;
    margin-top: 60px; }
    @media (min-width: 768px) {
      .horizontal-scroll .sbs-block__images {
        margin-right: 50px;
        margin-top: 0; } }
    .horizontal-scroll .sbs-block__images > div {
      width: calc(50% - 28px);
      margin: 14px;
      overflow: hidden; }
  .horizontal-scroll__item--red {
    background-color: #C12F0F;
    color: #fff; }
    .horizontal-scroll__item--red .sbs-block__image::before {
      content: '';
      display: block;
      padding-bottom: 118%; }
    .horizontal-scroll__item--red .title {
      font-family: 'Brice';
      font-style: normal;
      font-weight: 900;
      font-size: 42px;
      line-height: 1.1;
      text-transform: uppercase; }
      @media (min-width: 992px) {
        .horizontal-scroll__item--red .title {
          font-size: 4.16667vw; } }
      .horizontal-scroll__item--red .title span {
        color: #C12F0F; }
  .horizontal-scroll__item--light {
    background-color: #FFFCF2; }
    @media (max-width: 767.98px) {
      .horizontal-scroll__item--light {
        padding: 50% 0; } }
    .horizontal-scroll__item--light .images-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none; }
      .horizontal-scroll__item--light .images-wrapper .item-image {
        position: absolute;
        max-height: 40vmin;
        max-width: 30%;
        mask-image: url(../img/mask-poster-1.svg);
        mask-size: cover;
        mask-repeat: round;
        backface-visibility: hidden; }
        @media (min-width: 1200px) {
          .horizontal-scroll__item--light .images-wrapper .item-image {
            max-height: 44vmin;
            max-width: 40%; } }
        .horizontal-scroll__item--light .images-wrapper .item-image.item-image--left.item-image--top {
          left: -10%;
          top: -150px;
          transform: rotate(-26deg); }
          @media (min-width: 992px) {
            .horizontal-scroll__item--light .images-wrapper .item-image.item-image--left.item-image--top {
              left: -6%;
              top: -6%; } }
          @media (min-width: 1200px) {
            .horizontal-scroll__item--light .images-wrapper .item-image.item-image--left.item-image--top {
              left: -16%;
              top: -204px;
              max-width: 40.46875vw;
              max-height: 31.25vw; } }
          @media (max-width: 991.98px) {
            .horizontal-scroll__item--light .images-wrapper .item-image.item-image--left.item-image--top {
              top: -25px;
              max-width: 35%; } }
          @media (max-width: 767.98px) {
            .horizontal-scroll__item--light .images-wrapper .item-image.item-image--left.item-image--top {
              top: 0;
              max-width: 50%; } }
        .horizontal-scroll__item--light .images-wrapper .item-image.item-image--left.item-image--center {
          left: -18%;
          transform: rotate(37deg) translateY(-50%); }
          @media (min-width: 768px) {
            .horizontal-scroll__item--light .images-wrapper .item-image.item-image--left.item-image--center {
              left: -25%; } }
          @media (min-width: 1200px) {
            .horizontal-scroll__item--light .images-wrapper .item-image.item-image--left.item-image--center {
              left: -21%;
              top: 55%;
              max-width: 27.5vw;
              max-height: 21.25vw; } }
        .horizontal-scroll__item--light .images-wrapper .item-image.item-image--left.item-image--bottom {
          left: 7%;
          transform: rotate(-64deg);
          bottom: -30%;
          bottom: -30%; }
          @media (min-width: 992px) {
            .horizontal-scroll__item--light .images-wrapper .item-image.item-image--left.item-image--bottom {
              bottom: -15%; } }
          @media (min-width: 1200px) {
            .horizontal-scroll__item--light .images-wrapper .item-image.item-image--left.item-image--bottom {
              bottom: -36%;
              max-width: 35.104vw;
              max-height: 27.135vw; } }
          @media (max-width: 991.98px) {
            .horizontal-scroll__item--light .images-wrapper .item-image.item-image--left.item-image--bottom {
              bottom: -60px;
              left: -5%;
              max-width: 35%; } }
          @media (max-width: 767.98px) {
            .horizontal-scroll__item--light .images-wrapper .item-image.item-image--left.item-image--bottom {
              bottom: 0;
              max-width: 50%;
              transform: rotate(6deg); } }
        .horizontal-scroll__item--light .images-wrapper .item-image.item-image--right.item-image--top {
          right: -9%;
          top: -18%;
          transform: rotate(40deg); }
          @media (min-width: 992px) {
            .horizontal-scroll__item--light .images-wrapper .item-image.item-image--right.item-image--top {
              top: -5%; } }
          @media (min-width: 1200px) {
            .horizontal-scroll__item--light .images-wrapper .item-image.item-image--right.item-image--top {
              top: -16%;
              max-width: 33.177vw;
              max-height: 25.625vw; } }
          @media (max-width: 991.98px) {
            .horizontal-scroll__item--light .images-wrapper .item-image.item-image--right.item-image--top {
              top: -25px;
              max-width: 35%; } }
          @media (max-width: 767.98px) {
            .horizontal-scroll__item--light .images-wrapper .item-image.item-image--right.item-image--top {
              top: 0;
              max-width: 50%; } }
        .horizontal-scroll__item--light .images-wrapper .item-image.item-image--right.item-image--center {
          right: -11%;
          transform: rotate(-40deg) translateY(-50%);
          max-width: 24%;
          top: 43%; }
          @media (min-width: 576px) {
            .horizontal-scroll__item--light .images-wrapper .item-image.item-image--right.item-image--center {
              max-width: 15%;
              right: -6vmin; } }
          @media (min-width: 992px) {
            .horizontal-scroll__item--light .images-wrapper .item-image.item-image--right.item-image--center {
              right: -5vmin; } }
          @media (min-width: 1200px) {
            .horizontal-scroll__item--light .images-wrapper .item-image.item-image--right.item-image--center {
              right: -9%;
              top: 46%;
              max-width: 19.792vw;
              max-height: 15.3125vw; } }
        .horizontal-scroll__item--light .images-wrapper .item-image.item-image--right.item-image--bottom {
          right: -10%;
          transform: rotate(-54deg);
          bottom: -26%; }
          @media (min-width: 992px) {
            .horizontal-scroll__item--light .images-wrapper .item-image.item-image--right.item-image--bottom {
              bottom: -5%;
              right: -5%; } }
          @media (min-width: 1200px) {
            .horizontal-scroll__item--light .images-wrapper .item-image.item-image--right.item-image--bottom {
              right: -10%;
              bottom: -25%;
              max-width: 35.104vw;
              max-height: 28.333vw; } }
          @media (max-width: 991.98px) {
            .horizontal-scroll__item--light .images-wrapper .item-image.item-image--right.item-image--bottom {
              bottom: -20px;
              right: -5%;
              max-width: 35%; } }
          @media (max-width: 767.98px) {
            .horizontal-scroll__item--light .images-wrapper .item-image.item-image--right.item-image--bottom {
              bottom: 0;
              max-width: 50%; } }
        .horizontal-scroll__item--light .images-wrapper .item-image--top {
          top: 0; }
        .horizontal-scroll__item--light .images-wrapper .item-image--center {
          top: 50%;
          transform: translateY(-50%); }
        .horizontal-scroll__item--light .images-wrapper .item-image--bottom {
          bottom: 0; }
        .horizontal-scroll__item--light .images-wrapper .item-image--left {
          left: 0; }
        .horizontal-scroll__item--light .images-wrapper .item-image--right {
          right: 0; }
      @media (max-width: 991.98px) {
        .horizontal-scroll__item--light .images-wrapper .item-image--bottom.item-image--left {
          left: -14%; } }
    .horizontal-scroll__item--light .content-wrapper {
      max-width: 71%;
      margin: 0 auto;
      text-align: center; }
      @media (min-width: 1200px) {
        .horizontal-scroll__item--light .content-wrapper {
          max-width: 57%; } }
  .horizontal-scroll__item--dark:last-child {
    padding: 80px 0 125px;
    background-color: #222222; }
    .horizontal-scroll__item--dark:last-child .sbs-block .title {
      font-family: 'Brice';
      font-style: normal;
      font-weight: 900;
      font-size: 42px;
      line-height: 1.1;
      text-transform: uppercase; }
      @media (min-width: 992px) {
        .horizontal-scroll__item--dark:last-child .sbs-block .title {
          font-size: 3.95833vw; } }
      .horizontal-scroll__item--dark:last-child .sbs-block .title span {
        color: #C12F0F; }
    @media (max-width: 767.98px) {
      .horizontal-scroll__item--dark:last-child .sbs-block {
        flex-direction: column; }
        .horizontal-scroll__item--dark:last-child .sbs-block .title {
          width: 100%;
          margin-bottom: 20px; } }
    .horizontal-scroll__item--dark:last-child .sbs-block .sbs-block__content p {
      font-size: 18px; }
      @media (min-width: 992px) {
        .horizontal-scroll__item--dark:last-child .sbs-block .sbs-block__content p {
          font-size: 1.30208vw; } }
  .horizontal-scroll .horizontal-images {
    display: flex;
    flex-wrap: nowrap;
    align-items: center; }
    .horizontal-scroll .horizontal-images__item {
      max-width: 33%;
      flex-shrink: 0;
      margin: 3vh 80px 0;
      text-align: center; }
      @media (max-width: 991.98px) {
        .horizontal-scroll .horizontal-images__item {
          margin: 30px; } }
      .horizontal-scroll .horizontal-images__item img {
        max-height: 40vh;
        width: auto; }
      .horizontal-scroll .horizontal-images__item:not(.no-mask) img {
        mask-image: url(../img/mask-poster-1.svg);
        mask-size: cover;
        mask-repeat: round;
        backface-visibility: hidden; }
      .horizontal-scroll .horizontal-images__item:nth-child(even) img {
        transform: rotate(-20deg); }

@media (max-width: 991.98px) {
  .js-bg-change {
    display: contents; } }

.karl-marx {
  --mask-size: 30px;
  margin-top: calc(-1 * var(--mask-size));
  min-height: 100vh;
  overflow: hidden; }
  @media (min-width: 768px) {
    .karl-marx {
      --mask-size: 45px; } }
  @media (min-width: 992px) {
    .karl-marx {
      --mask-size: 60px; } }
  .karl-marx .section__background {
    background-color: #FFFCF2; }

.karl-marx__grid {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 991.98px) {
    .karl-marx__grid {
      flex-direction: column; } }

.karl-marx__title {
  font-family: 'Brice';
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  line-height: 1.1;
  text-transform: uppercase;
  width: 61.34259%; }
  @media (min-width: 992px) {
    .karl-marx__title {
      font-size: 5.20833vw; } }
  .karl-marx__title span {
    color: #C12F0F; }
  .karl-marx__title div {
    line-height: inherit; }
  .karl-marx__title > div {
    overflow: hidden; }
  @media (max-width: 991.98px) {
    .karl-marx__title {
      width: 100%; } }

.karl-marx__wrapper {
  position: relative;
  width: 39.23611%;
  right: -5.6%; }
  @media (max-width: 991.98px) {
    .karl-marx__wrapper {
      right: 0;
      width: 100%;
      margin-top: 40px;
      padding: 0 6%; } }

.karl-marx__image {
  width: 100%;
  height: auto;
  mask-image: url("../img/mask-poster-2.svg");
  mask-size: cover;
  mask-repeat: round;
  transform: rotate(20deg);
  backface-visibility: hidden;
  width: 103.53982%;
  max-width: 103.53982%;
  transform: rotate(14deg);
  position: relative;
  right: -14.8%;
  margin: 7% 0;
  display: block; }
  @media (max-width: 991.98px) {
    .karl-marx__image {
      width: 100%;
      max-width: 480px;
      right: 0;
      margin-left: auto;
      margin-right: auto; } }

.futurism-article {
  --mask-edge-size: 28px;
  --mask-size: 30px;
  mask-image: url(../img/mask-rect.svg), url(../img/mask-top.svg), url(../img/mask-bottom.svg);
  mask-position: 0 var(--mask-edge-size), top, bottom;
  mask-size: 100% calc(100% - (2 * var(--mask-edge-size))), 100% var(--mask-size), 100% var(--mask-size);
  mask-repeat: no-repeat;
  backface-visibility: hidden;
  font-size: 18px;
  padding: 278px 0 258px;
  background-color: #F1E5C1;
  position: relative;
  padding: 208px 0 208px; }
  @media (min-width: 768px) {
    .futurism-article {
      --mask-edge-size: 43px;
      --mask-size: 45px; } }
  @media (min-width: 992px) {
    .futurism-article {
      --mask-edge-size: 58px;
      --mask-size: 60px; } }
  @media (min-width: 992px) {
    .futurism-article {
      font-size: 1.66667vw; } }
  @media (max-width: 767.98px) {
    .futurism-article {
      padding: 200px 0 280px; } }
  @media screen and (max-width: 360px) {
    .futurism-article {
      padding: 180px 0 280px; } }

.futurism-article__grid {
  padding: 0 7%; }
  @media (max-width: 991.98px) {
    .futurism-article__grid {
      padding: 0 12%; } }

.futurism-article__title {
  z-index: 3;
  position: relative;
  text-align: center;
  font-size: 5.2vmax; }
  .futurism-article__title--second {
    margin-top: 100px;
    margin-bottom: 0; }
  @media (max-width: 767.98px) {
    .futurism-article__title {
      font-size: 4.2vmax; }
      .futurism-article__title--second {
        margin-top: 50px; } }

.futurism-article .images-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden; }
  .futurism-article .images-wrapper .item-image {
    position: absolute;
    width: 100%;
    height: auto;
    mask-image: url("../img/mask-poster-4.svg");
    mask-size: cover;
    mask-repeat: round;
    transform: rotate(0);
    backface-visibility: hidden; }
    .futurism-article .images-wrapper .item-image--top {
      top: -23%;
      width: 100%;
      max-width: 560px; }
      .futurism-article .images-wrapper .item-image--top.item-image--left {
        width: 100%;
        height: auto;
        mask-image: url("../img/mask-poster-1.svg");
        mask-size: cover;
        mask-repeat: round;
        transform: rotate(0);
        backface-visibility: hidden;
        left: -7%;
        transform: translate(-80%, -80%) rotate(-25deg); }
      .futurism-article .images-wrapper .item-image--top.item-image--right {
        right: -8%;
        top: -18%;
        transform: translate(80%, -80%) rotate(41deg);
        object-fit: cover;
        object-position: 50% -30%; }
      @media screen and (max-width: 1450px) {
        .futurism-article .images-wrapper .item-image--top {
          max-width: 400px; }
          .futurism-article .images-wrapper .item-image--top.item-image--left {
            top: -13%;
            left: -5%; }
          .futurism-article .images-wrapper .item-image--top.item-image--right {
            top: -10%;
            right: -5%;
            max-width: 400px; } }
      @media (max-width: 1199.98px) {
        .futurism-article .images-wrapper .item-image--top {
          max-width: 350px; }
          .futurism-article .images-wrapper .item-image--top.item-image--left {
            top: -10%;
            left: -10%; }
          .futurism-article .images-wrapper .item-image--top.item-image--right {
            right: -10%;
            max-width: 350px; } }
      @media (max-width: 767.98px) {
        .futurism-article .images-wrapper .item-image--top {
          max-width: 300px; }
          .futurism-article .images-wrapper .item-image--top.item-image--left {
            left: -13%;
            top: -7%; }
          .futurism-article .images-wrapper .item-image--top.item-image--right {
            display: none; } }
      @media (max-width: 575.98px) {
        .futurism-article .images-wrapper .item-image--top {
          max-width: 250px; }
          .futurism-article .images-wrapper .item-image--top.item-image--left {
            max-width: 225px;
            left: -18%;
            top: -2%; } }
    .futurism-article .images-wrapper .item-image--center {
      top: 40%;
      width: 100%;
      max-width: 500px;
      width: 100%;
      height: auto;
      mask-image: url("../img/mask-poster-1.svg");
      mask-size: cover;
      mask-repeat: round;
      transform: rotate(0);
      backface-visibility: hidden; }
      .futurism-article .images-wrapper .item-image--center.item-image--left {
        left: -16%;
        transform: translate(-80%, 0) rotate(-60deg) scaleX(-1); }
      .futurism-article .images-wrapper .item-image--center.item-image--right {
        right: -14%;
        transform: translate(80%, 0) rotate(41deg); }
      @media screen and (max-width: 2000px) {
        .futurism-article .images-wrapper .item-image--center {
          max-width: 400px; }
          .futurism-article .images-wrapper .item-image--center.item-image--left {
            left: -14%; }
          .futurism-article .images-wrapper .item-image--center.item-image--right {
            right: -15%; } }
      @media screen and (max-width: 1450px) {
        .futurism-article .images-wrapper .item-image--center {
          top: 45%; }
          .futurism-article .images-wrapper .item-image--center.item-image--left {
            left: -22%; }
          .futurism-article .images-wrapper .item-image--center.item-image--right {
            right: -22%;
            top: 42%; } }
      @media (max-width: 1199.98px) {
        .futurism-article .images-wrapper .item-image--center {
          max-width: 300px; }
          .futurism-article .images-wrapper .item-image--center.item-image--left {
            left: -20%; }
          .futurism-article .images-wrapper .item-image--center.item-image--right {
            right: -20%; } }
      @media screen and (max-width: 900px) {
        .futurism-article .images-wrapper .item-image--center {
          max-width: 350px;
          top: 40%; }
          .futurism-article .images-wrapper .item-image--center.item-image--left {
            left: -28%; }
          .futurism-article .images-wrapper .item-image--center.item-image--right {
            right: -25%; } }
      @media (max-width: 767.98px) {
        .futurism-article .images-wrapper .item-image--center {
          top: auto; }
          .futurism-article .images-wrapper .item-image--center.item-image--left {
            left: -30%;
            bottom: 8%;
            transform: translate(-80%, 0) rotate(-60deg) scaleX(-1);
            max-width: 350px; }
          .futurism-article .images-wrapper .item-image--center.item-image--right {
            top: 7%;
            right: -10%;
            transform: translate(80%, -80%) rotate(41deg);
            max-width: 175px; } }
      @media (max-width: 575.98px) {
        .futurism-article .images-wrapper .item-image--center.item-image--left {
          max-width: 250px;
          bottom: 5%;
          left: -30%; }
        .futurism-article .images-wrapper .item-image--center.item-image--right {
          max-width: 155px;
          top: 5%;
          right: -18%;
          transform: translate(80%, -80%) rotate(41deg); } }
    .futurism-article .images-wrapper .item-image--bottom {
      bottom: -15%;
      width: 100%;
      max-width: 500px;
      width: 100%;
      height: auto;
      mask-image: url("../img/mask-poster-1.svg");
      mask-size: cover;
      mask-repeat: round;
      transform: rotate(0);
      backface-visibility: hidden; }
      .futurism-article .images-wrapper .item-image--bottom.item-image--left {
        left: -12%;
        transform: translate(-80%, 80%) rotate(-60deg); }
      .futurism-article .images-wrapper .item-image--bottom.item-image--right {
        right: -6%;
        transform: translate(80%, 80%) rotate(-60deg);
        bottom: -100px; }
      @media screen and (max-width: 1450px) {
        .futurism-article .images-wrapper .item-image--bottom {
          max-width: 450px;
          bottom: -20%; } }
      @media (max-width: 1199.98px) {
        .futurism-article .images-wrapper .item-image--bottom {
          bottom: -30%; } }
      @media (max-width: 991.98px) {
        .futurism-article .images-wrapper .item-image--bottom.item-image--right {
          bottom: -20%; } }
      @media (max-width: 767.98px) {
        .futurism-article .images-wrapper .item-image--bottom {
          max-width: 350px; }
          .futurism-article .images-wrapper .item-image--bottom.item-image--left {
            left: 22%;
            bottom: -10%;
            transform: translate(0, 95%) rotate(-60deg); }
          .futurism-article .images-wrapper .item-image--bottom.item-image--right {
            max-width: 250px;
            bottom: 15%;
            right: -20%;
            transform: translate(70%, 0) rotate(-45deg); } }
      @media (max-width: 575.98px) {
        .futurism-article .images-wrapper .item-image--bottom.item-image--left {
          left: 25%;
          bottom: -8%;
          max-width: 250px; }
        .futurism-article .images-wrapper .item-image--bottom.item-image--right {
          bottom: 12%;
          right: -34%;
          max-width: 220px; } }
      @media screen and (max-width: 400px) {
        .futurism-article .images-wrapper .item-image--bottom.item-image--left {
          left: 21%;
          bottom: -10%;
          max-width: 220px; } }

.fr-article {
  --mask-size: 30px;
  margin-top: calc(-1 * var(--mask-size));
  min-height: 95vh;
  color: #fff; }
  @media (min-width: 768px) {
    .fr-article {
      --mask-size: 45px; } }
  @media (min-width: 992px) {
    .fr-article {
      --mask-size: 60px; } }
  .fr-article .section__background {
    background-color: #222222;
    --mask-edge-size: 28px;
    --mask-size: 30px;
    mask-image: url(../img/mask-rect.svg), url(../img/mask-top.svg);
    mask-position: 0 var(--mask-edge-size), top;
    mask-size: 100% calc(100% - var(--mask-edge-size)), 100% var(--mask-size);
    mask-repeat: no-repeat;
    backface-visibility: hidden; }
    .fr-article .section__background::after {
      content: '';
      pointer-events: none;
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 0.6;
      left: 0;
      top: 0;
      background-image: url(../img/noise-effect.png); }
    @media (min-width: 768px) {
      .fr-article .section__background {
        --mask-edge-size: 43px;
        --mask-size: 45px; } }
    @media (min-width: 992px) {
      .fr-article .section__background {
        --mask-edge-size: 58px;
        --mask-size: 60px; } }
  .fr-article__title {
    color: #fff;
    margin-bottom: 0.6em; }

.fr-article__grid {
  display: flex;
  align-items: center; }
  @media (max-width: 991.98px) {
    .fr-article__grid {
      flex-direction: column;
      justify-content: center; } }

.fr-article__content {
  width: calc(70% - 80px);
  width: calc(60% - 60px); }
  .fr-article__content p {
    margin-top: 50px; }
  @media (max-width: 991.98px) {
    .fr-article__content {
      width: 100%; }
      .fr-article__content p {
        margin-top: 20px; } }

.fr-article__image {
  margin-left: auto;
  overflow: hidden; }
  @media (max-width: 991.98px) {
    .fr-article__image {
      margin-right: auto;
      margin-top: 40px;
      width: 100%;
      text-align: center; }
      .fr-article__image img {
        width: 100%;
        max-width: 480px; } }

@media (min-width: 992px) {
  .fr-article__text {
    max-width: 80%; } }

.fr-medicine-wrap {
  position: relative;
  z-index: 1; }

.fr-medicine {
  --mask-size: 30px;
  margin-top: calc(-1 * var(--mask-size));
  margin-bottom: calc(-1 * var(--mask-size));
  overflow: hidden;
  position: relative; }
  @media (min-width: 768px) {
    .fr-medicine {
      --mask-size: 45px; } }
  @media (min-width: 992px) {
    .fr-medicine {
      --mask-size: 60px; } }
  @media (max-width: 767.98px) {
    .fr-medicine {
      max-height: none; } }
  .fr-medicine img {
    aspect-ratio: var(--aspect-ratio); }
  .fr-medicine--inner {
    min-height: 100vh;
    background-color: #FFFCF2;
    --mask-edge-size: 28px;
    --mask-size: 30px;
    mask-image: url(../img/mask-rect.svg), url(../img/mask-top.svg), url(../img/mask-bottom.svg);
    mask-position: 0 var(--mask-edge-size), top, bottom;
    mask-size: 100% calc(100% - (2 * var(--mask-edge-size))), 100% var(--mask-size), 100% var(--mask-size);
    mask-repeat: no-repeat;
    backface-visibility: hidden; }
    @media (min-width: 768px) {
      .fr-medicine--inner {
        --mask-edge-size: 43px;
        --mask-size: 45px; } }
    @media (min-width: 992px) {
      .fr-medicine--inner {
        --mask-edge-size: 58px;
        --mask-size: 60px; } }
    @media (max-width: 767.98px) {
      .fr-medicine--inner {
        min-height: initial; } }
  .fr-medicine .section__background {
    background-color: #FFFCF2;
    --mask-edge-size: 28px;
    --mask-size: 30px;
    mask-image: url(../img/mask-rect.svg), url(../img/mask-top.svg), url(../img/mask-bottom.svg);
    mask-position: 0 var(--mask-edge-size), top, bottom;
    mask-size: 100% calc(100% - (2 * var(--mask-edge-size))), 100% var(--mask-size), 100% var(--mask-size);
    mask-repeat: no-repeat;
    backface-visibility: hidden;
    z-index: -1; }
    @media (min-width: 768px) {
      .fr-medicine .section__background {
        --mask-edge-size: 43px;
        --mask-size: 45px; } }
    @media (min-width: 992px) {
      .fr-medicine .section__background {
        --mask-edge-size: 58px;
        --mask-size: 60px; } }
  .fr-medicine .section__background-helper {
    position: absolute;
    left: 0;
    height: 160px;
    width: 100%;
    z-index: -1; }
    .fr-medicine .section__background-helper::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: var(--after-background);
      opacity: var(--after-opacity); }
    .fr-medicine .section__background-helper--top {
      top: -1px; }
    .fr-medicine .section__background-helper--bottom {
      bottom: -1px; }

.fr-medicine__grid {
  display: flex;
  align-items: center; }
  @media (max-width: 767.98px) {
    .fr-medicine__grid {
      flex-direction: column; } }

.fr-medicine__title {
  font-family: 'Brice';
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  line-height: 1.1;
  text-transform: uppercase; }
  @media (min-width: 992px) {
    .fr-medicine__title {
      font-size: 5.20833vw; } }
  .fr-medicine__title span {
    color: #C12F0F; }

.fr-medicine__content {
  margin-right: 50px;
  width: calc(65% - 50px); }
  @media (max-width: 767.98px) {
    .fr-medicine__content {
      width: 100%;
      margin-right: auto; } }

.fr-medicine__images {
  position: absolute;
  right: 0;
  top: 0;
  width: 35%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden; }
  @media (max-width: 767.98px) {
    .fr-medicine__images {
      position: relative;
      width: 100%; } }

.vertical-scroll {
  backface-visibility: hidden; }
  .vertical-scroll--first {
    width: 60%; }
    @media (max-width: 767.98px) {
      .vertical-scroll--first {
        display: flex;
        width: 100%; } }
  .vertical-scroll--second {
    width: calc(40% - 20px);
    margin-left: 20px; }
    @media (max-width: 767.98px) {
      .vertical-scroll--second {
        display: none; } }
  .vertical-scroll__item {
    margin-bottom: 40px;
    margin-bottom: 20px;
    width: 22vw; }
    .vertical-scroll__item:last-child {
      margin-bottom: 0; }
    .vertical-scroll__item img {
      width: 100%;
      height: auto;
      mask-image: url("../img/mask-poster-3.svg");
      mask-size: cover;
      mask-repeat: round;
      transform: rotate(0);
      backface-visibility: hidden;
      width: 20vw;
      max-width: 500px;
      object-fit: contain; }
    @media (max-width: 767.98px) {
      .vertical-scroll__item {
        margin-right: 20px;
        width: 190px;
        margin-top: 40px;
        margin-bottom: 0; }
        .vertical-scroll__item img {
          width: 190px;
          height: 100%; }
        .vertical-scroll__item:last-child {
          margin-right: 0; } }

.oval-thumb-section {
  min-height: 100vh; }
  .oval-thumb-section .section__background {
    background-color: #FFFCF2; }
  .oval-thumb-section__title {
    margin-bottom: 0.75em; }
    .oval-thumb-section__title span {
      color: #C12F0F; }
  .oval-thumb-section p:not(:last-child) {
    margin-bottom: 1em; }
  .oval-thumb-section .oval-thumb {
    display: block;
    width: 100%;
    background: #F1E5C1;
    border-radius: 50%;
    overflow: hidden;
    margin: auto;
    position: relative;
    max-width: 360px;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden; }
    @media (min-width: 768px) {
      .oval-thumb-section .oval-thumb {
        max-width: 480px; } }
    @media (min-width: 992px) {
      .oval-thumb-section .oval-thumb {
        max-width: 660px; } }
    .oval-thumb-section .oval-thumb::before {
      content: '';
      display: block;
      height: 0;
      padding-bottom: 114.616%; }
    .oval-thumb-section .oval-thumb img {
      position: absolute;
      left: 0;
      display: block;
      width: 100%;
      top: 0;
      height: 100%;
      -o-object-fit: contain;
      object-fit: cover;
      backface-visibility: hidden; }
  .oval-thumb-section .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
    @media (min-width: 992px) {
      .oval-thumb-section .row {
        flex-direction: row-reverse;
        display: flex;
        flex-wrap: wrap;
        margin-right: -30px;
        margin-left: -30px; } }
    @media (min-width: 1200px) {
      .oval-thumb-section .row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -60px;
        margin-left: -60px; } }
  .oval-thumb-section .col-content {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 40px; }
    @media (min-width: 992px) {
      .oval-thumb-section .col-content {
        position: relative;
        width: 100%;
        padding-right: 30px;
        padding-left: 30px;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
        margin-bottom: 0; } }
    @media (min-width: 1200px) {
      .oval-thumb-section .col-content {
        position: relative;
        width: 100%;
        padding-right: 60px;
        padding-left: 60px; } }
  .oval-thumb-section .col-thumb {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; }
    @media (min-width: 992px) {
      .oval-thumb-section .col-thumb {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
        position: relative;
        width: 100%;
        padding-right: 30px;
        padding-left: 30px; } }
    @media (min-width: 1200px) {
      .oval-thumb-section .col-thumb {
        position: relative;
        width: 100%;
        padding-right: 60px;
        padding-left: 60px; } }

.flowers-section {
  --mask-size: 30px;
  margin-top: calc(-1 * var(--mask-size));
  min-height: 100vh; }
  @media (min-width: 768px) {
    .flowers-section {
      --mask-size: 45px; } }
  @media (min-width: 992px) {
    .flowers-section {
      --mask-size: 60px; } }
  .flowers-section .section__background {
    --mask-edge-size: 28px;
    --mask-size: 30px;
    mask-image: url(../img/mask-rect.svg), url(../img/mask-top.svg);
    mask-position: 0 var(--mask-edge-size), top;
    mask-size: 100% calc(100% - var(--mask-edge-size)), 100% var(--mask-size);
    mask-repeat: no-repeat;
    backface-visibility: hidden;
    background-color: #FFFCF2; }
    @media (min-width: 768px) {
      .flowers-section .section__background {
        --mask-edge-size: 43px;
        --mask-size: 45px; } }
    @media (min-width: 992px) {
      .flowers-section .section__background {
        --mask-edge-size: 58px;
        --mask-size: 60px; } }
  .flowers-section .container {
    position: relative; }
  .flowers-section__title {
    line-height: 1.1;
    margin-bottom: 0.47619em; }
    .flowers-section__title::before {
      display: block;
      width: 4.32em;
      margin-top: 1.1em;
      height: 2.2em;
      float: left;
      shape-outside: polygon(0% 34%, 33% 34%, 33% 68%, 100% 68%, 100% 100%, 0% 100%); }
      @media (min-width: 768px) {
        .flowers-section__title::before {
          content: ''; } }
    @media (min-width: 992px) {
      .flowers-section__title {
        margin-bottom: 0.6em; } }
    .flowers-section__title span {
      color: #C12F0F; }
    .flowers-section__title--desktop {
      display: none; }
      @media (min-width: 768px) {
        .flowers-section__title--desktop {
          display: block; } }
    .flowers-section__title--mobile {
      max-width: 500px;
      display: block; }
      @media (min-width: 768px) {
        .flowers-section__title--mobile {
          display: none; } }
  @media (max-width: 767.98px) {
    .flowers-section__content::before {
      float: right;
      content: '';
      height: 3em;
      width: 32%; } }
  @media (min-width: 768px) {
    .flowers-section__content {
      width: 56%;
      margin-left: 26%; } }
  @media (min-width: 992px) {
    .flowers-section__content {
      width: 56%;
      margin-left: 38%; } }
  .flowers-section p:not(:last-child) {
    margin-bottom: 1em; }
  .flowers-section .flower__img {
    filter: grayscale(100%);
    aspect-ratio: var(--aspect-ratio); }
    .flowers-section .flower__img-1 {
      width: 38.93333%;
      margin-left: 5.33333%;
      max-width: 250px; }
      @media (min-width: 768px) {
        .flowers-section .flower__img-1 {
          width: 21.92708%;
          margin-top: -30%;
          margin-left: 15px;
          max-width: 100%; } }
      @media (min-width: 992px) {
        .flowers-section .flower__img-1 {
          margin-left: 5%; } }
    .flowers-section .flower__img-2 {
      width: 38.13333%;
      margin-left: auto;
      margin-right: 0; }
      @media (min-width: 768px) {
        .flowers-section .flower__img-2 {
          margin-left: 8.33333%;
          margin-top: 5%;
          width: 26.5625%; } }
      @media (min-width: 992px) {
        .flowers-section .flower__img-2 {
          margin-top: 100px; } }
    .flowers-section .flower__img-3 {
      width: 32%; }
      @media (max-width: 767.98px) {
        .flowers-section .flower__img-3 {
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: -36%; } }
      @media (min-width: 768px) {
        .flowers-section .flower__img-3 {
          margin-left: auto;
          width: 30.88542%;
          margin-right: -5.6%; } }
    .flowers-section .flower__img-4 {
      width: 116px;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1; }
      @media (min-width: 768px) {
        .flowers-section .flower__img-4 {
          top: -5%;
          width: 12.91667%; } }
  .flowers-section .flower__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 30px; }
    @media (min-width: 992px) {
      .flowers-section .flower__wrapper {
        margin-top: 70px; } }

.tech-dev {
  --mask-edge-size: 28px;
  --mask-size: 30px;
  mask-image: url(../img/mask-rect.svg), url(../img/mask-top.svg), url(../img/mask-bottom.svg);
  mask-position: 0 var(--mask-edge-size), top, bottom;
  mask-size: 100% calc(100% - (2 * var(--mask-edge-size))), 100% var(--mask-size), 100% var(--mask-size);
  mask-repeat: no-repeat;
  backface-visibility: hidden;
  --size: 30px;
  --edge-size: 28px;
  position: relative;
  text-align: center; }
  @media (min-width: 768px) {
    .tech-dev {
      --mask-edge-size: 43px;
      --mask-size: 45px; } }
  @media (min-width: 992px) {
    .tech-dev {
      --mask-edge-size: 58px;
      --mask-size: 60px; } }
  @media (min-width: 768px) {
    .tech-dev {
      --size: 45px;
      --edge-size: 43px; } }
  @media (min-width: 992px) {
    .tech-dev {
      --size: 60px;
      --edge-size: 58px; } }
  .tech-dev::before, .tech-dev::after {
    overflow: hidden;
    --size: inherit;
    pointer-events: none;
    position: absolute;
    z-index: 2;
    height: calc(var(--size) + 12px);
    width: 100%;
    left: 0;
    background-repeat: no-repeat;
    background-size: 100% var(--size);
    filter: drop-shadow(0px 0 6px rgba(0, 0, 0, 0.35));
    mask-repeat: no-repeat;
    mask-size: 100% 100%, 100% calc(var(--size)); }
  .tech-dev::before {
    content: '';
    background-image: url(../img/mask-bottom.svg);
    top: -2px;
    mask-position: 0 var(--size), 0 0;
    mask-image: url(../img/mask-rect.svg), url(../img/mask-top.svg);
    background-position: 0 -2px; }
  .tech-dev::after {
    content: '';
    background-image: url(../img/mask-top.svg);
    bottom: -2px;
    background-position: 0 12px;
    mask-image: url(../img/mask-rect.svg), url(../img/mask-bottom.svg);
    mask-position: 0 calc(-1 * var(--size)), 0 10px; }
  @media (max-width: 767.98px) {
    .tech-dev {
      padding-bottom: 200px; } }
  .tech-dev__description {
    max-width: 1214px;
    margin: 60px auto 0; }
  .tech-dev__title {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 1.1;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .tech-dev__title {
        font-size: 5.20833vw; } }
    .tech-dev__title span {
      color: #C12F0F; }
  .tech-dev #motionPath {
    width: 100%; }
  .tech-dev .section__background {
    background-color: #F1E5C1; }
    .tech-dev .section__background::after {
      content: '';
      pointer-events: none;
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 0.1;
      left: 0;
      top: 0;
      background-image: url(../img/noise-effect.png); }
  .tech-dev .tech-dev-images {
    display: flex;
    flex-wrap: nowrap;
    align-items: center; }
    @media (min-width: 576px) {
      .tech-dev .tech-dev-images {
        position: absolute;
        width: 100%;
        bottom: 0; } }
    .tech-dev .tech-dev-images img {
      flex-shrink: 0;
      mix-blend-mode: darken;
      max-width: 20vw; }
      @media (max-width: 767.98px) {
        .tech-dev .tech-dev-images img {
          position: absolute;
          max-width: 30vw; }
          .tech-dev .tech-dev-images img:nth-child(1) {
            top: 20px;
            left: -50px; }
          .tech-dev .tech-dev-images img:nth-child(2) {
            bottom: 170px;
            left: -20px; }
          .tech-dev .tech-dev-images img:nth-child(3) {
            bottom: 50px;
            left: 50%;
            transform: translateX(-50%); }
          .tech-dev .tech-dev-images img:nth-child(4) {
            bottom: 170px;
            right: -20px; }
          .tech-dev .tech-dev-images img:nth-child(5) {
            top: 80px;
            right: -70px; } }
  @media (min-width: 576px) {
    .tech-dev__scene {
      position: relative; }
      .tech-dev__scene::after {
        content: '';
        display: block;
        padding-bottom: 10%; } }

.oval-image {
  position: relative;
  z-index: 1;
  color: #fff;
  --mask-size: 30px;
  margin-top: calc(-1 * var(--mask-size));
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px;
  font-size: 18px;
  min-height: 95vh; }
  @media (min-width: 768px) {
    .oval-image {
      --mask-size: 45px; } }
  @media (min-width: 992px) {
    .oval-image {
      --mask-size: 60px; } }
  @media (min-width: 768px) {
    .oval-image {
      padding-top: 100px;
      padding-bottom: 100px; } }
  @media (min-width: 1200px) {
    .oval-image {
      padding-top: 160px;
      padding-bottom: 160px; } }
  @media (min-width: 992px) {
    .oval-image {
      font-size: 1.66667vw; } }
  @media (max-width: 991.98px) {
    .oval-image {
      padding-top: 275px;
      padding-bottom: 345px; } }
  .oval-image .section__background {
    background-color: #222222;
    --mask-edge-size: 28px;
    --mask-size: 30px;
    mask-image: url(../img/mask-rect.svg), url(../img/mask-top.svg);
    mask-position: 0 var(--mask-edge-size), top;
    mask-size: 100% calc(100% - var(--mask-edge-size)), 100% var(--mask-size);
    mask-repeat: no-repeat;
    backface-visibility: hidden; }
    .oval-image .section__background::after {
      content: '';
      pointer-events: none;
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 0.6;
      left: 0;
      top: 0;
      background-image: url(../img/noise-effect.png); }
    @media (min-width: 768px) {
      .oval-image .section__background {
        --mask-edge-size: 43px;
        --mask-size: 45px; } }
    @media (min-width: 992px) {
      .oval-image .section__background {
        --mask-edge-size: 58px;
        --mask-size: 60px; } }
  .oval-image__title {
    margin-bottom: 20px;
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 1.1;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .oval-image__title {
        width: 17.2em;
        font-size: min(5.20833vw, 9.25926vh); } }
    @media (min-width: 992px) {
      .oval-image__title {
        margin-bottom: 60px; } }
    .oval-image__title p {
      margin-bottom: 0; }
      .oval-image__title p span {
        display: inline-block; }
      @media (min-width: 992px) {
        .oval-image__title p:first-child span:nth-of-type(2) {
          padding-left: 14%; } }
      @media (min-width: 992px) {
        .oval-image__title p:nth-child(2) span:first-child {
          padding-left: 7%; } }
    .oval-image__title span {
      color: #fff; }
  .oval-image__img {
    position: absolute;
    z-index: 1; }
    .oval-image__img--first {
      top: 4%;
      left: -29%;
      width: 65%; }
      @media (min-width: 576px) {
        .oval-image__img--first {
          width: 55%; } }
      @media (min-width: 768px) {
        .oval-image__img--first {
          width: 35%;
          left: -20%; } }
      @media (min-width: 992px) {
        .oval-image__img--first {
          top: -3%;
          left: -8%;
          width: 23vw; } }
    .oval-image__img--second {
      left: -7%;
      width: 65%;
      bottom: -12vmin; }
      @media (min-width: 576px) {
        .oval-image__img--second {
          width: 55%; } }
      @media (min-width: 768px) {
        .oval-image__img--second {
          width: 45%; } }
      @media (min-width: 992px) {
        .oval-image__img--second {
          left: 50%;
          width: 28vmax; } }
    .oval-image__img--third {
      bottom: 14%;
      right: -15%;
      width: 55%; }
      @media (min-width: 576px) {
        .oval-image__img--third {
          width: 45%; } }
      @media (min-width: 992px) {
        .oval-image__img--third {
          bottom: initial;
          right: -5%;
          top: 20%;
          width: 27vw; } }

.love-n-robots {
  font-size: 18px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px; }
  @media (min-width: 992px) {
    .love-n-robots {
      font-size: 1.66667vw; } }
  @media (min-width: 768px) {
    .love-n-robots {
      padding-top: 100px;
      padding-bottom: 100px; } }
  @media (min-width: 1200px) {
    .love-n-robots {
      padding-top: 160px;
      padding-bottom: 160px; } }
  .love-n-robots__inner {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
    align-items: center;
    justify-content: center; }
    @media (min-width: 992px) {
      .love-n-robots__inner {
        justify-content: space-between; } }
  @media (min-width: 992px) {
    .love-n-robots__content {
      flex: 0 0 50%;
      max-width: 50%; } }
  .love-n-robots__image {
    margin-top: 60px;
    overflow: hidden; }
    @media (min-width: 576px) {
      .love-n-robots__image {
        flex: 0 0 66.66667%;
        max-width: 66.66667%; } }
    @media (min-width: 992px) {
      .love-n-robots__image {
        flex: 0 0 41.66667%;
        max-width: 41.66667%; } }
    @media (min-width: 992px) {
      .love-n-robots__image {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
        max-width: 50%;
        padding: 20px;
        margin-top: 0;
        margin-left: auto; } }
  .love-n-robots__title {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 1.1;
    text-transform: uppercase;
    margin-bottom: 0.6em;
    font-weight: 400; }
    @media (min-width: 992px) {
      .love-n-robots__title {
        font-size: 5.20833vw; } }
    .love-n-robots__title span {
      color: #C12F0F; }

.dark-light-sections {
  display: grid;
  grid-template-columns: auto auto 40.02193%;
  grid-template-rows: auto auto 1fr 2fr auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px; }
  @media (min-width: 768px) {
    .dark-light-sections {
      grid-template-rows: repeat(3, auto); } }
  @media (min-width: 992px) {
    .dark-light-sections {
      padding-right: 5%; } }
  .dark-light-sections .dark-section__background {
    position: relative;
    grid-area: 1 / 1 / 4 / 4;
    z-index: -1;
    background-color: #433543;
    margin-right: 13.33333%; }
    .dark-light-sections .dark-section__background::after {
      content: '';
      pointer-events: none;
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 0.6;
      left: 0;
      top: 0;
      background-image: url(../img/noise-effect.png); }
    @media (min-width: 576px) {
      .dark-light-sections .dark-section__background {
        margin-right: 7.8125%; } }
    @media (min-width: 768px) {
      .dark-light-sections .dark-section__background {
        grid-area: 1 / 1 / 3 / 4; } }
  .dark-light-sections .dark-section__title {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 1.1;
    text-transform: uppercase;
    margin-bottom: 0.6em;
    color: rgba(255, 255, 255, 0.54);
    grid-area: 1 / 1 / 2 / 4;
    padding-right: 17.33333%;
    padding-left: 15px;
    padding-top: 60px; }
    @media (min-width: 992px) {
      .dark-light-sections .dark-section__title {
        font-size: 5.20833vw; } }
    .dark-light-sections .dark-section__title span {
      color: #C12F0F; }
    .dark-light-sections .dark-section__title span {
      color: #FFFCF2;
      display: block; }
    @media (min-width: 768px) {
      .dark-light-sections .dark-section__title {
        padding-top: 100px; } }
    @media (min-width: 1200px) {
      .dark-light-sections .dark-section__title {
        padding-top: 160px; } }
    @media (min-width: 576px) {
      .dark-light-sections .dark-section__title {
        padding-right: calc(15px + 7.8125%); } }
    @media (min-width: 768px) {
      .dark-light-sections .dark-section__title {
        grid-area: 1 / 1 / 2 / 4;
        padding-right: 20.83333%; } }
    @media (min-width: 992px) {
      .dark-light-sections .dark-section__title {
        padding-left: 5%; } }
  .dark-light-sections .dark-section__desc {
    grid-area: 2 / 1 / 3 / 4;
    color: #fff;
    padding-bottom: 10.66667%;
    padding-left: 15px;
    padding-right: 17.33333%; }
    @media (min-width: 768px) {
      .dark-light-sections .dark-section__desc {
        padding-bottom: 100px; } }
    @media (min-width: 1200px) {
      .dark-light-sections .dark-section__desc {
        padding-bottom: 160px; } }
    @media (min-width: 768px) {
      .dark-light-sections .dark-section__desc {
        grid-area: 2 / 1 / 3 / 3;
        padding-right: 15px; } }
    @media (min-width: 992px) {
      .dark-light-sections .dark-section__desc {
        padding-right: 8.77514%;
        padding-left: 8.77514%; } }
  .dark-light-sections .dark-section__image {
    grid-area: 3 / 1 / 5 / 4;
    position: relative;
    width: 67.73333%;
    max-width: 360px;
    margin-left: auto; }
    @media (min-width: 768px) {
      .dark-light-sections .dark-section__image {
        width: 100%;
        max-width: 100%;
        margin-bottom: 100px;
        grid-area: 2 / 3 / 4 / 4; } }
    @media (min-width: 992px) {
      .dark-light-sections .dark-section__image {
        margin-bottom: 160px; } }
    .dark-light-sections .dark-section__image img {
      display: block;
      height: auto; }
  .dark-light-sections .light-section {
    grid-area: 6 / 1 / 5 / 4;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10.66667%;
    padding-bottom: 60px; }
    @media (min-width: 768px) {
      .dark-light-sections .light-section {
        padding-top: 100px;
        padding-bottom: 100px; } }
    @media (min-width: 1200px) {
      .dark-light-sections .light-section {
        padding-top: 160px;
        padding-bottom: 160px; } }
    @media (min-width: 768px) {
      .dark-light-sections .light-section {
        grid-area: 3 / 1 / 4 / 3; } }
    @media (min-width: 992px) {
      .dark-light-sections .light-section {
        padding-left: 8.77514%;
        padding-right: 8.77514%; } }
    @media (min-width: 1200px) {
      .dark-light-sections .light-section {
        padding-right: 13.71115%; } }
    .dark-light-sections .light-section__title {
      font-family: 'Brice';
      font-style: normal;
      font-weight: 900;
      font-size: 42px;
      line-height: 1.1;
      text-transform: uppercase; }
      @media (min-width: 992px) {
        .dark-light-sections .light-section__title {
          font-size: 4.16667vw; } }
      .dark-light-sections .light-section__title span {
        color: #C12F0F; }
      .dark-light-sections .light-section__title:last-child {
        margin-bottom: 0; }

.red-revolution-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px;
  font-size: 18px;
  color: #fff;
  min-height: 100vh; }
  @media (min-width: 768px) {
    .red-revolution-section {
      padding-top: 100px;
      padding-bottom: 100px; } }
  @media (min-width: 1200px) {
    .red-revolution-section {
      padding-top: 160px;
      padding-bottom: 160px; } }
  @media (min-width: 992px) {
    .red-revolution-section {
      font-size: 1.66667vw; } }
  @media (max-width: 991.98px) {
    .red-revolution-section {
      padding-top: 20vw; } }
  .red-revolution-section::before, .red-revolution-section::after {
    content: '';
    display: block;
    width: 100%;
    height: 80px;
    position: absolute;
    top: -20px;
    left: 0; }
  .red-revolution-section::before {
    background-color: #222;
    z-index: -2; }
  .red-revolution-section::after {
    background-image: url(../img/noise-effect.png);
    opacity: 0.6;
    z-index: -1; }
  .red-revolution-section .section__background {
    background-color: #C12F0F;
    --mask-edge-size: 28px;
    --mask-size: 30px;
    mask-image: url(../img/mask-rect.svg), url(../img/mask-top.svg), url(../img/mask-bottom.svg);
    mask-position: 0 var(--mask-edge-size), top, bottom;
    mask-size: 100% calc(100% - (2 * var(--mask-edge-size))), 100% var(--mask-size), 100% var(--mask-size);
    mask-repeat: no-repeat;
    backface-visibility: hidden; }
    .red-revolution-section .section__background::after {
      content: '';
      pointer-events: none;
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 0.6;
      left: 0;
      top: 0;
      background-image: url(../img/noise-effect.png); }
    @media (min-width: 768px) {
      .red-revolution-section .section__background {
        --mask-edge-size: 43px;
        --mask-size: 45px; } }
    @media (min-width: 992px) {
      .red-revolution-section .section__background {
        --mask-edge-size: 58px;
        --mask-size: 60px; } }
  .red-revolution-section__title {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 1.1;
    text-transform: uppercase;
    margin-bottom: 0.6em;
    color: #FFFCF2; }
    @media (min-width: 992px) {
      .red-revolution-section__title {
        font-size: 5.20833vw; } }
    .red-revolution-section__title span {
      color: #C12F0F; }
    .red-revolution-section__title span {
      color: #222222; }
  @media (min-width: 992px) {
    .red-revolution-section__content {
      max-width: 85%; } }
  .red-revolution-section p:not(:last-child) {
    margin-bottom: 1em; }
  .red-revolution-section .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
    @media (min-width: 992px) {
      .red-revolution-section .row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -20px;
        margin-left: -20px; } }
  .red-revolution-section .col-content {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 40px; }
    @media (min-width: 992px) {
      .red-revolution-section .col-content {
        position: relative;
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
        flex: 0 0 76.66667%;
        max-width: 76.66667%;
        margin-bottom: 0; } }
  .red-revolution-section .col-img {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; }
    @media (max-width: 991.98px) {
      .red-revolution-section .col-img {
        max-width: 400px;
        margin: auto; } }
    @media (min-width: 992px) {
      .red-revolution-section .col-img {
        flex: 0 0 23.33333%;
        max-width: 23.33333%;
        position: relative;
        width: 100%;
        padding-right: 20px;
        padding-left: 20px; } }
    .red-revolution-section .col-img img {
      display: block;
      aspect-ratio: var(--aspect-ratio);
      margin-left: auto;
      margin-right: auto; }
      .red-revolution-section .col-img img:first-child {
        width: 77.72277%; }
      .red-revolution-section .col-img img:not(:last-child) {
        margin-bottom: 40px; }
      @media (min-width: 992px) {
        .red-revolution-section .col-img img {
          margin-right: 0; } }

.image-slider {
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 0;
  margin-bottom: 13vw; }
  @media (max-width: 767.98px) {
    .image-slider {
      padding-top: 0; } }
  @media (min-width: 992px) {
    .image-slider {
      display: block; } }
  .image-slider__title, .image-slider__subtitle {
    text-align: center; }
    @media (min-width: 992px) {
      .image-slider__title, .image-slider__subtitle {
        text-align: left; } }
  @media (min-width: 576px) {
    .image-slider .section__background {
      background-color: #F2E9D5; } }
  @media (max-width: 767.98px) {
    .image-slider .section__foreground {
      display: flex;
      flex-direction: column-reverse; } }
  .image-slider__title {
    margin-bottom: 20px;
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 1.1;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .image-slider__title {
        font-size: 5.05208vw; } }
    .image-slider__title span {
      color: #C12F0F; }
    @media (min-width: 992px) {
      .image-slider__title {
        margin-bottom: 0;
        max-width: 91%; }
        .image-slider__title:after {
          content: '';
          display: inline-block;
          width: 60%;
          height: 0.1em; } }
    .image-slider__title strong {
      font-weight: 900; }
      @media (min-width: 992px) {
        .image-slider__title strong {
          display: block; } }
  @media (min-width: 992px) {
    .image-slider__subtitle {
      width: 40%;
      margin: -5.83333em 7.65625vw 0 auto !important; } }
  .image-slider__images-wrap {
    margin-bottom: 86px; }
    @media (min-width: 768px) {
      .image-slider__images-wrap {
        margin-bottom: 0;
        transform: translateY(50%); } }
  .image-slider-images {
    display: flex;
    align-items: center; }
    .image-slider-images__item {
      height: auto;
      flex-shrink: 0;
      flex-basis: 60%;
      margin: 0 5%; }
      @media (min-width: 992px) {
        .image-slider-images__item {
          flex-basis: 26.61458%;
          margin: 0 1.5625%; } }
      .image-slider-images__item img {
        object-fit: cover;
        width: 100%;
        height: 100%; }

.uncle-sam {
  color: #fff; }
  @media (max-width: 767.98px) {
    .uncle-sam {
      font-size: 16px; } }
  .uncle-sam__inner {
    padding: 60px 5% 0;
    background-color: #3C3B6E;
    position: relative; }
    .uncle-sam__inner::before {
      content: '';
      display: block;
      width: 100%;
      height: 183px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: repeating-linear-gradient(180deg, #B22234 0%, #B22234 14.28%, #fff 14.29%, #fff 28.56%);
      z-index: 1; }
      @media (min-width: 992px) {
        .uncle-sam__inner::before {
          height: 252px; } }
    @media (min-width: 992px) {
      .uncle-sam__inner {
        padding: 32px 0 0 5%;
        max-width: calc(100% - 5.20833%); } }
  @media (min-width: 992px) {
    .uncle-sam__row {
      display: flex;
      position: relative; } }
  .uncle-sam .futurism-title {
    position: relative;
    z-index: 1; }
    @media (min-width: 992px) {
      .uncle-sam .futurism-title {
        width: 66%;
        padding-top: 88px;
        position: absolute;
        top: 0;
        left: 0; } }
    .uncle-sam .futurism-title span {
      color: #fff; }
      @media (min-width: 992px) {
        .uncle-sam .futurism-title span {
          display: block; } }
  .uncle-sam-col {
    position: relative; }
    .uncle-sam-col__portrait {
      text-align: center;
      margin-top: -60px; }
      @media (min-width: 992px) {
        .uncle-sam-col__portrait {
          margin: 0 0 0 auto;
          width: 45%; } }
      .uncle-sam-col__portrait img {
        max-width: 232px; }
        @media (min-width: 992px) {
          .uncle-sam-col__portrait img {
            max-width: initial; } }
    .uncle-sam-col__inner {
      position: relative;
      z-index: 1;
      padding: 30px;
      background-color: #FFFCF2;
      color: #222222;
      margin-top: -60px;
      bottom: -5px; }
      @media (min-width: 992px) {
        .uncle-sam-col__inner {
          padding: 80px 0 100px 4.16667vw;
          margin-top: -163px;
          margin-left: auto;
          width: 45%; } }
  .uncle-sam .uncle-sam__stars {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 158px);
    background-image: url(../img/uncle-sam/stars-bg.png);
    background-size: cover; }
    @media (min-width: 992px) {
      .uncle-sam .uncle-sam__stars {
        height: calc(100% - 252px);
        width: 55%; } }

@media (max-width: 991.98px) {
  .hammer-and-sickle-section {
    padding-top: 100px; } }

@media (max-width: 575.98px) {
  .hammer-and-sickle-section {
    padding-top: 20px; } }

.hammer-and-sickle-section .section__background {
  background-color: #FFFCF2;
  opacity: 0;
  transition: opacity 0.25s;
  background-image: url("../img/hammer-and-sickle/bg-hammer-and-sickle.png");
  background-size: 33%;
  mask-image: linear-gradient(to bottom, white 0%, white calc(100% - 220px), rgba(255, 255, 255, 0) calc(100% - 200px));
  mask-size: 100% 100%; }
  @media (min-width: 768px) {
    .hammer-and-sickle-section .section__background {
      opacity: 1; } }
  @media (min-width: 1200px) {
    .hammer-and-sickle-section .section__background {
      mask-image: linear-gradient(to bottom, white 0%, white calc(100% - 528px), rgba(255, 255, 255, 0) calc(100% - 480px)); } }

.hammer-and-sickle-section .section__title, .hammer-and-sickle-section .futurism-title, .hammer-and-sickle-section .fr-article__title, .hammer-and-sickle-section .flowers-section__title, .hammer-and-sickle-section .centre-of-the-earth__title {
  text-align: center; }
  @media (min-width: 992px) {
    .hammer-and-sickle-section .section__title, .hammer-and-sickle-section .futurism-title, .hammer-and-sickle-section .fr-article__title, .hammer-and-sickle-section .flowers-section__title, .hammer-and-sickle-section .centre-of-the-earth__title {
      margin-left: auto;
      margin-right: auto;
      width: 65.11628%; } }
  .hammer-and-sickle-section .section__title span, .hammer-and-sickle-section .futurism-title span, .hammer-and-sickle-section .fr-article__title span, .hammer-and-sickle-section .flowers-section__title span, .hammer-and-sickle-section .centre-of-the-earth__title span {
    color: #C12F0F; }

@media (max-width: 991.98px) {
  .hammer-and-sickle-section .section__foreground {
    padding-top: 300px; } }

@media (max-width: 575.98px) {
  .hammer-and-sickle-section .section__foreground {
    padding-top: 80%; } }

@media (min-width: 992px) {
  .hammer-and-sickle-section p {
    margin-left: auto;
    margin-right: auto;
    width: 48.83721%; } }

.hammer-and-sickle-section__img {
  display: flex; }
  .hammer-and-sickle-section__img img {
    display: block;
    aspect-ratio: var(--aspect-ratio);
    backface-visibility: hidden; }
  .hammer-and-sickle-section__img--center {
    margin: 16% auto 0;
    width: 66.66667%; }
    @media (max-width: 991.98px) {
      .hammer-and-sickle-section__img--center {
        max-width: 420px; } }
    @media (min-width: 992px) {
      .hammer-and-sickle-section__img--center {
        margin-top: 5.20833%;
        width: 32.60417%; } }
  .hammer-and-sickle-section__img--left, .hammer-and-sickle-section__img--right {
    width: 32%;
    display: flex;
    flex-direction: column; }
    @media (max-width: 991.98px) {
      .hammer-and-sickle-section__img--left, .hammer-and-sickle-section__img--right {
        margin-top: 8%; } }
    @media (min-width: 992px) {
      .hammer-and-sickle-section__img--left, .hammer-and-sickle-section__img--right {
        width: 26.04167%;
        position: absolute;
        top: 0;
        padding: inherit;
        bottom: 0; } }
    .hammer-and-sickle-section__img--left img, .hammer-and-sickle-section__img--right img {
      mask-size: 100% 100%; }
  .hammer-and-sickle-section__img--left {
    align-items: flex-start; }
    @media (min-width: 992px) {
      .hammer-and-sickle-section__img--left {
        left: 0; } }
    .hammer-and-sickle-section__img--left img:nth-child(1) {
      width: 76.6%;
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384.42 417.59'%3E%3Cellipse transform='rotate(-29.38 192.21 208.795) scale(0.97405) translate(5.41785 5.41785)' cx='192.21' cy='208.795' rx='187.2225' ry='208.795'/%3E%3C/svg%3E");
      margin-left: -27%;
      margin-bottom: 16%; }
      @media (max-width: 991.98px) {
        .hammer-and-sickle-section__img--left img:nth-child(1) {
          position: absolute;
          top: 0;
          left: 0;
          width: 180px;
          margin-top: 80px;
          margin-left: -72px; } }
      @media (max-width: 575.98px) {
        .hammer-and-sickle-section__img--left img:nth-child(1) {
          max-width: 280px;
          margin-top: 21.33333%;
          width: 48%;
          margin-left: -19.2%; } }
    @media (min-width: 992px) {
      .hammer-and-sickle-section__img--left img {
        max-height: 21.8%; } }
    .hammer-and-sickle-section__img--left img:nth-child(2) {
      width: 66%;
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 330 373'%3E%3Cellipse transform='rotate(-16 165 186.5) scale(0.98685) translate(2.45182 2.45182)' cx='165' cy='186.5' rx='162.83083' ry='186.5'/%3E%3C/svg%3E");
      margin-bottom: 18%;
      margin-left: 10.4%; }
      @media (min-width: 992px) {
        .hammer-and-sickle-section__img--left img:nth-child(2) {
          max-height: 20.1%; } }
    .hammer-and-sickle-section__img--left img:nth-child(3) {
      margin-left: 10px;
      width: 93.4%;
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 467 522'%3E%3Cellipse transform='rotate(-23.06 233.5 261) scale(0.97712) translate(5.9708 5.9708)' cx='233.5' cy='261' rx='228.15831' ry='261'/%3E%3C/svg%3E");
      margin-bottom: 14%;
      margin-left: -22%; }
      @media (min-width: 992px) {
        .hammer-and-sickle-section__img--left img:nth-child(3) {
          max-height: 27.2%; } }
    .hammer-and-sickle-section__img--left img:nth-child(4) {
      width: 72%;
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 360 393'%3E%3Cellipse transform='rotate(27.4 180 196.5) scale(0.97566) translate(4.78188 4.78188)' cx='180' cy='196.5' rx='175.61965' ry='196.5'/%3E%3C/svg%3E");
      margin-left: 29.2%; }
      @media (min-width: 992px) {
        .hammer-and-sickle-section__img--left img:nth-child(4) {
          max-height: 20.5%; } }
  .hammer-and-sickle-section__img--right {
    align-items: flex-end; }
    @media (min-width: 992px) {
      .hammer-and-sickle-section__img--right {
        right: 0; } }
    .hammer-and-sickle-section__img--right img:nth-child(1) {
      width: 76.6%;
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384.42 417.59'%3E%3Cellipse transform='rotate(29.38 192.21 208.795) scale(0.97405) translate(5.41785 5.41785)' cx='192.21' cy='208.795' rx='187.2225' ry='208.795'/%3E%3C/svg%3E");
      margin-right: -27%;
      margin-bottom: 18%; }
      @media (max-width: 991.98px) {
        .hammer-and-sickle-section__img--right img:nth-child(1) {
          position: absolute;
          top: 0;
          right: 0;
          margin-right: -50px;
          width: 185px; } }
      @media (max-width: 575.98px) {
        .hammer-and-sickle-section__img--right img:nth-child(1) {
          max-width: 280px;
          margin-right: -13.33333%;
          width: 49.33333%; } }
      @media (min-width: 992px) {
        .hammer-and-sickle-section__img--right img:nth-child(1) {
          max-height: 21.7%; } }
    .hammer-and-sickle-section__img--right img:nth-child(2) {
      width: 66%;
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 330 373'%3E%3Cellipse transform='rotate(16 165 186.5) scale(0.98685) translate(2.45182 2.45182)' cx='165' cy='186.5' rx='162.83083' ry='186.5'/%3E%3C/svg%3E");
      margin-bottom: 28.4%;
      margin-left: 10.4%; }
      @media (min-width: 992px) {
        .hammer-and-sickle-section__img--right img:nth-child(2) {
          max-height: 17.6%; } }
    .hammer-and-sickle-section__img--right img:nth-child(3) {
      margin-left: 10px;
      width: 93.4%;
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 467 522'%3E%3Cellipse transform='rotate(23.06 233.5 261) scale(0.97712) translate(5.9708 5.9708)' cx='233.5' cy='261' rx='228.15831' ry='261'/%3E%3C/svg%3E");
      margin-bottom: 19.6%;
      margin-right: -22%; }
      @media (min-width: 992px) {
        .hammer-and-sickle-section__img--right img:nth-child(3) {
          max-height: 24.3%; } }
    .hammer-and-sickle-section__img--right img:nth-child(4) {
      width: 72%;
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 360 393'%3E%3Cellipse transform='rotate(-27.4 180 196.5) scale(0.97566) translate(4.78188 4.78188)' cx='180' cy='196.5' rx='175.61965' ry='196.5'/%3E%3C/svg%3E");
      margin-left: 29.2%; }
      @media (min-width: 992px) {
        .hammer-and-sickle-section__img--right img:nth-child(4) {
          max-height: 20.4%; } }

.boldest-dreams-section {
  color: #fff; }
  @media (max-width: 991.98px) {
    .boldest-dreams-section {
      padding-top: max(130px, 21.71875%); } }
  .boldest-dreams-section .section__title, .boldest-dreams-section .futurism-title, .boldest-dreams-section .fr-article__title, .boldest-dreams-section .flowers-section__title, .boldest-dreams-section .centre-of-the-earth__title {
    margin-bottom: 20px; }
    @media (min-width: 992px) {
      .boldest-dreams-section .section__title, .boldest-dreams-section .futurism-title, .boldest-dreams-section .fr-article__title, .boldest-dreams-section .flowers-section__title, .boldest-dreams-section .centre-of-the-earth__title {
        margin-bottom: 60px; } }
    .boldest-dreams-section .section__title strong, .boldest-dreams-section .futurism-title strong, .boldest-dreams-section .fr-article__title strong, .boldest-dreams-section .flowers-section__title strong, .boldest-dreams-section .centre-of-the-earth__title strong {
      display: block; }
      @media (min-width: 992px) {
        .boldest-dreams-section .section__title strong, .boldest-dreams-section .futurism-title strong, .boldest-dreams-section .fr-article__title strong, .boldest-dreams-section .flowers-section__title strong, .boldest-dreams-section .centre-of-the-earth__title strong {
          display: inline; } }
  .boldest-dreams-section .planet {
    z-index: -1;
    pointer-events: none; }
    @media (min-width: 992px) {
      .boldest-dreams-section .planet {
        position: absolute; } }
    .boldest-dreams-section .planet::after {
      padding-bottom: 100%; }
    .boldest-dreams-section .planet img {
      display: block;
      aspect-ratio: 1;
      width: 100%;
      box-shadow: 19px 21px 90px rgba(0, 0, 0, 0.19);
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.19); }
    @media (max-width: 991.98px) {
      .boldest-dreams-section .planet__holder {
        margin-top: 24px;
        display: flex;
        justify-content: space-between; } }
    .boldest-dreams-section .planet--mars {
      width: 3.3em;
      height: 3.3em;
      left: 50%;
      transform: translateX(-100%);
      top: -0.5em; }
    .boldest-dreams-section .planet--venus {
      max-width: 334px;
      width: 55.07246%; }
      @media (max-width: 991.98px) {
        .boldest-dreams-section .planet--venus {
          margin-top: 13.04348%;
          margin-left: -10.14493%; } }
      @media (min-width: 992px) {
        .boldest-dreams-section .planet--venus {
          left: 14.46759%;
          top: 50%;
          transform: translateY(-50%);
          width: 19.3287%; } }
    .boldest-dreams-section .planet--moon {
      max-width: 250px;
      width: 31.88406%; }
      @media (min-width: 992px) {
        .boldest-dreams-section .planet--moon {
          right: 0%;
          bottom: 100%;
          width: 14.46759%; } }
  .boldest-dreams-section .section__background {
    background-image: url(../img/boldest-dreams/boldest-dreams-bg.jpg);
    background-size: cover;
    background-color: #222222; }
  .boldest-dreams-section .section__title, .boldest-dreams-section .futurism-title, .boldest-dreams-section .fr-article__title, .boldest-dreams-section .flowers-section__title, .boldest-dreams-section .centre-of-the-earth__title {
    position: relative; }
    .boldest-dreams-section .section__title .planet, .boldest-dreams-section .futurism-title .planet, .boldest-dreams-section .fr-article__title .planet, .boldest-dreams-section .flowers-section__title .planet, .boldest-dreams-section .centre-of-the-earth__title .planet {
      position: absolute; }
    @media (min-width: 992px) {
      .boldest-dreams-section .section__title, .boldest-dreams-section .futurism-title, .boldest-dreams-section .fr-article__title, .boldest-dreams-section .flowers-section__title, .boldest-dreams-section .centre-of-the-earth__title {
        padding: 0 14.46759%;
        text-align: center; } }
    .boldest-dreams-section .section__title .line-indent, .boldest-dreams-section .futurism-title .line-indent, .boldest-dreams-section .fr-article__title .line-indent, .boldest-dreams-section .flowers-section__title .line-indent, .boldest-dreams-section .centre-of-the-earth__title .line-indent {
      padding-left: 1.4em; }
      @media (min-width: 992px) {
        .boldest-dreams-section .section__title .line-indent, .boldest-dreams-section .futurism-title .line-indent, .boldest-dreams-section .fr-article__title .line-indent, .boldest-dreams-section .flowers-section__title .line-indent, .boldest-dreams-section .centre-of-the-earth__title .line-indent {
          padding-left: 4em; } }
  .boldest-dreams-section .section__content {
    position: relative;
    margin-left: auto;
    margin-right: auto; }
    @media (min-width: 992px) {
      .boldest-dreams-section .section__content {
        padding-right: 14.46759%;
        padding-left: 39.75694%; } }
  .boldest-dreams-section .diamond {
    z-index: 1;
    position: absolute;
    transform: rotate(-47.2deg);
    box-shadow: 19px 21px 90px rgba(0, 0, 0, 0.19);
    background-color: #FFFCF2;
    border: 10px solid #FFFCF2;
    border-radius: 36px;
    width: max(120px, 20.15625%);
    overflow: hidden;
    left: -5.6%;
    top: -5%; }
    @media (max-width: 991.98px) {
      .boldest-dreams-section .diamond {
        border-width: 5px;
        border-radius: 24px; } }
    @media (max-width: 575.98px) {
      .boldest-dreams-section .diamond {
        border-width: 2px;
        border-radius: 12px; } }
    .boldest-dreams-section .diamond::before {
      padding-bottom: 100%;
      content: '';
      display: block; }
    .boldest-dreams-section .diamond__img {
      position: absolute;
      left: 0;
      top: 0;
      transform: rotate(47.2deg) scale(1.41421);
      width: 100%;
      aspect-ratio: 1; }

.big-world {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 60px;
  text-align: center; }
  @media (min-width: 768px) {
    .big-world {
      padding-top: 100px; } }
  @media (min-width: 1200px) {
    .big-world {
      padding-top: 160px; } }
  @media (max-width: 767.98px) {
    .big-world {
      margin-top: 50px; } }
  .big-world__container {
    max-width: 68%;
    margin: 0 auto; }
    @media (max-width: 767.98px) {
      .big-world__container {
        max-width: 90%; } }
  .big-world__title {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 1.1;
    text-transform: uppercase;
    margin-bottom: 0.6em;
    padding: 0 9%; }
    @media (min-width: 992px) {
      .big-world__title {
        font-size: 5.20833vw; } }
    .big-world__title span {
      color: #C12F0F; }
    @media (min-width: 576px) {
      .big-world__title {
        padding: 0 15%; } }
    @media (min-width: 992px) {
      .big-world__title {
        padding: 0; }
        .big-world__title b {
          display: block; } }
  .big-world__image {
    margin-top: 120px;
    position: relative; }
    @media (max-width: 767.98px) {
      .big-world__image {
        max-width: 470px;
        margin-left: auto;
        margin-right: auto; } }
    .big-world__image::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(255, 252, 242, 0) 60.02%, #FFFCF2 98.01%); }
  .big-world__decor {
    position: absolute;
    border-radius: 10%;
    overflow: hidden; }
    .big-world__decor::before {
      content: '';
      display: block;
      padding-bottom: 100%; }
    .big-world__decor img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;'; }
    .big-world__decor--left-top {
      top: 13%;
      left: 5%;
      transform: rotate(27deg);
      width: 10%; }
      @media (max-width: 767.98px) {
        .big-world__decor--left-top {
          top: 0;
          width: 25%;
          left: -10%; } }
    .big-world__decor--left-bottom {
      bottom: 30%;
      left: -2%;
      transform: rotate(-33deg);
      width: 15%; }
      @media (max-width: 767.98px) {
        .big-world__decor--left-bottom {
          width: 20%;
          bottom: 25%;
          left: -5%; } }
    .big-world__decor--right-top {
      top: 13%;
      right: 5%;
      width: 10%;
      transform: rotate(-40deg); }
      @media (max-width: 767.98px) {
        .big-world__decor--right-top {
          top: 0;
          width: 25%;
          right: -10%; } }
    .big-world__decor--right-center {
      width: 15%;
      top: 45%;
      right: -2%;
      transform: rotate(44deg); }
      @media (max-width: 767.98px) {
        .big-world__decor--right-center {
          display: none; } }
    .big-world__decor--right-bottom {
      width: 11%;
      bottom: 10%;
      right: 5%;
      transform: rotate(12deg); }
      @media (max-width: 767.98px) {
        .big-world__decor--right-bottom {
          width: 20%;
          bottom: 25%;
          right: -5%; } }
  .big-world p:not(:last-of-type) {
    margin-bottom: 1.625em; }

.side-by-side .sbs-item__inner {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap; }

.side-by-side .sbs-item__title {
  font-family: 'Brice';
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  line-height: 1.1;
  text-transform: uppercase; }
  @media (min-width: 992px) {
    .side-by-side .sbs-item__title {
      font-size: 5.20833vw; } }
  .side-by-side .sbs-item__title span {
    color: #C12F0F; }
  .side-by-side .sbs-item__title strong {
    display: block; }
    @media (min-width: 576px) {
      .side-by-side .sbs-item__title strong {
        display: inline; } }
  .side-by-side .sbs-item__title .ease-left {
    color: inherit; }

.side-by-side .sbs-item__title--strong {
  font-family: 'Brice';
  font-style: normal;
  font-weight: 900;
  font-size: 62px;
  line-height: 1.1;
  text-transform: uppercase;
  color: #C12F0F; }
  @media (min-width: 992px) {
    .side-by-side .sbs-item__title--strong {
      font-size: 7.29167vw; } }
  .side-by-side .sbs-item__title--strong span {
    color: #C12F0F; }

.side-by-side .sbs-item .sbs-item__content {
  margin-bottom: 30%; }
  @media (min-width: 576px) {
    .side-by-side .sbs-item .sbs-item__content {
      flex: 0 0 50%;
      max-width: 50%;
      margin-bottom: 0; } }

.side-by-side .sbs-item--mars {
  padding: 160px 0 50px; }
  @media (max-width: 767.98px) {
    .side-by-side .sbs-item--mars {
      padding-top: 60px; }
      .side-by-side .sbs-item--mars .sbs-item__title {
        margin-bottom: 0; } }
  @media (min-width: 576px) {
    .side-by-side .sbs-item--mars__head {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }
  .side-by-side .sbs-item--mars .sbs-item__content {
    margin-bottom: 0; }
    @media (min-width: 576px) {
      .side-by-side .sbs-item--mars .sbs-item__content {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
        margin-left: auto; } }
  .side-by-side .sbs-item--mars .sbs-item__image {
    width: 100%;
    margin-top: 20px;
    border-radius: 6px; }
    @media (min-width: 768px) {
      .side-by-side .sbs-item--mars .sbs-item__image {
        margin-top: 80px;
        border-radius: 26px; } }
  @media (min-width: 992px) {
    .side-by-side .sbs-item--mars .ease-left {
      display: block; } }

.side-by-side .sbs-item--neptune {
  padding-top: 10px; }
  @media (min-width: 992px) {
    .side-by-side .sbs-item--neptune {
      padding-top: 300px; } }
  .side-by-side .sbs-item--neptune .sbs-item__title span {
    color: #4672FD; }
  .side-by-side .sbs-item--neptune .sbs-item__content {
    margin-left: auto; }
  .side-by-side .sbs-item--neptune .sbs-item__image {
    position: relative;
    order: 1; }
    @media (min-width: 576px) {
      .side-by-side .sbs-item--neptune .sbs-item__image {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
        order: 0; } }
  .side-by-side .sbs-item--neptune .sbs-item-frog__bg {
    width: 100%;
    border-radius: 100px 26px; }
  .side-by-side .sbs-item--neptune .sbs-item-frog__planet {
    position: absolute;
    left: 0;
    top: -20%;
    width: 70%;
    border-radius: 50%;
    filter: drop-shadow(19px 21px 90px rgba(0, 0, 0, 0.19)); }
  .side-by-side .sbs-item--neptune .sbs-item-frog__frog {
    position: absolute;
    left: 10%;
    top: -31%;
    width: 50%; }

.side-by-side .sbs-item--pluto {
  margin-top: 60px; }
  @media (min-width: 992px) {
    .side-by-side .sbs-item--pluto {
      margin-top: 210px; } }
  .side-by-side .sbs-item--pluto .sbs-item__content {
    margin-right: auto; }
  .side-by-side .sbs-item--pluto .sbs-item-bat {
    position: relative; }
    @media (min-width: 576px) {
      .side-by-side .sbs-item--pluto .sbs-item-bat {
        flex: 0 0 41.66667%;
        max-width: 41.66667%; } }
    .side-by-side .sbs-item--pluto .sbs-item-bat__bg {
      width: 100%;
      border-radius: 26px 100px; }
    .side-by-side .sbs-item--pluto .sbs-item-bat__planet {
      position: absolute;
      right: 0;
      top: -20%;
      width: 70%; }
    .side-by-side .sbs-item--pluto .sbs-item-bat__bat {
      position: absolute;
      width: 50%;
      transform: rotate(-19deg); }
      .side-by-side .sbs-item--pluto .sbs-item-bat__bat--top {
        top: -30%;
        right: 15%;
        transform: rotate(-26deg); }
      .side-by-side .sbs-item--pluto .sbs-item-bat__bat--right {
        top: -15%;
        right: -10%;
        transform: rotate(-53deg); }
      .side-by-side .sbs-item--pluto .sbs-item-bat__bat--left {
        top: 20%;
        right: 35%;
        transform: rotate(60deg); }
      .side-by-side .sbs-item--pluto .sbs-item-bat__bat--bottom {
        top: 39%;
        right: -10%;
        width: 65%;
        transform: rotate(-60deg); }

.side-by-side .sbs-item--jupiter {
  margin-top: 60px;
  margin-bottom: 60px; }
  @media (min-width: 992px) {
    .side-by-side .sbs-item--jupiter {
      margin-top: 160px;
      margin-bottom: 160px; } }
  .side-by-side .sbs-item--jupiter .sbs-item__title {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 1.1;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .side-by-side .sbs-item--jupiter .sbs-item__title {
        font-size: 4.16667vw; } }
    .side-by-side .sbs-item--jupiter .sbs-item__title span {
      color: #C12F0F; }
  .side-by-side .sbs-item--jupiter .sbs-item__content {
    margin-left: auto; }
  .side-by-side .sbs-item--jupiter .sbs-item__image {
    padding: 0 49px;
    order: 1; }
    @media (min-width: 576px) {
      .side-by-side .sbs-item--jupiter .sbs-item__image {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
        order: 0; } }
  .side-by-side .sbs-item--jupiter .sbs-item-jupiter {
    position: relative;
    display: inline-block; }
    .side-by-side .sbs-item--jupiter .sbs-item-jupiter img {
      border-radius: 26px;
      border: 10px solid #FFFCF2;
      background-color: #FFFCF2;
      box-shadow: 19px 21px 90px rgba(0, 0, 0, 0.19); }
    .side-by-side .sbs-item--jupiter .sbs-item-jupiter__foreground {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;'; }

.distant-stars {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px;
  font-size: 18px; }
  @media (min-width: 768px) {
    .distant-stars {
      padding-top: 100px;
      padding-bottom: 100px; } }
  @media (min-width: 1200px) {
    .distant-stars {
      padding-top: 160px;
      padding-bottom: 160px; } }
  @media (min-width: 992px) {
    .distant-stars {
      font-size: 1.66667vw; } }
  .distant-stars__inner {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0; }
  .distant-stars__title {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 1.1;
    text-transform: uppercase;
    margin-bottom: 0.75em;
    word-break: break-word; }
    @media (min-width: 992px) {
      .distant-stars__title {
        font-size: 4.16667vw; } }
    .distant-stars__title span {
      color: #C12F0F; }
    .distant-stars__title span {
      color: #4672FD; }
  .distant-stars__img {
    order: 1;
    position: relative;
    width: 100%;
    padding-right: 0;
    padding-left: 0; }
    @media (min-width: 768px) {
      .distant-stars__img {
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 5%;
        order: 0;
        min-height: 100%; } }
    @media (min-width: 992px) {
      .distant-stars__img {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
        padding-right: 6.97674%; } }
    .distant-stars__img-wrap {
      overflow: hidden;
      border-radius: 8%; }
      @media (max-width: 767.98px) {
        .distant-stars__img-wrap {
          border-radius: 3.2vw;
          max-width: 480px;
          margin: auto; } }
      @media (min-width: 768px) {
        .distant-stars__img-wrap {
          position: relative;
          width: 100%;
          height: 100%; }
          .distant-stars__img-wrap img {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            object-fit: cover;
            font-family: 'object-fit: cover;'; } }
  .distant-stars__content {
    margin-bottom: 40px;
    position: relative;
    width: 100%;
    padding-right: 0;
    padding-left: 0; }
    @media (min-width: 768px) {
      .distant-stars__content {
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 0; } }
    .distant-stars__content p:not(:last-child) {
      margin-bottom: 1em; }

.usa-flag {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px;
  font-size: 18px;
  overflow: hidden;
  background-color: #F1E5C1; }
  @media (min-width: 768px) {
    .usa-flag {
      padding-top: 100px;
      padding-bottom: 100px; } }
  @media (min-width: 1200px) {
    .usa-flag {
      padding-top: 160px;
      padding-bottom: 160px; } }
  @media (min-width: 992px) {
    .usa-flag {
      font-size: 1.66667vw; } }
  .usa-flag__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
  .usa-flag__container {
    position: relative;
    z-index: 1; }
  .usa-flag__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    top: 70%;
    height: 30%; }
    @media (min-width: 576px) {
      .usa-flag__background {
        left: 75%;
        width: 50%;
        height: 100%;
        top: 0; } }
    .usa-flag__background img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;'; }
  @media (min-width: 576px) {
    .usa-flag__content {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; } }
  .usa-flag__ussr {
    position: relative;
    margin-top: 60px; }
    @media (min-width: 768px) {
      .usa-flag__ussr {
        padding-left: 20%; }
        .usa-flag__ussr::before {
          content: '';
          background: url(../img/usa-flag/Coat_of_arms_of_the_USSR.png) no-repeat;
          background-size: contain;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 14%;
          display: block; } }
    @media (min-width: 992px) {
      .usa-flag__ussr {
        padding-left: 10%; }
        .usa-flag__ussr::before {
          width: 7%; } }
  .usa-flag__title {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 1.1;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .usa-flag__title {
        font-size: 4.16667vw; } }
    .usa-flag__title span {
      color: #C12F0F; }
    .usa-flag__title--large {
      font-family: 'Brice';
      font-style: normal;
      font-weight: 900;
      font-size: 56px;
      line-height: 1.1;
      text-transform: uppercase; }
      @media (min-width: 992px) {
        .usa-flag__title--large {
          font-size: 6.25vw; } }
      .usa-flag__title--large span {
        color: #C12F0F; }
      @media (max-width: 767.98px) {
        .usa-flag__title--large {
          position: relative; }
          .usa-flag__title--large::after {
            content: '';
            background: url(../img/usa-flag/Coat_of_arms_of_the_USSR.png) no-repeat;
            background-size: contain;
            height: 1.1em;
            width: 1.1em;
            vertical-align: bottom;
            display: inline-block; } }
  .usa-flag__img {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%; }
    @media (min-width: 576px) {
      .usa-flag__img {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
        padding-left: 5%;
        margin-top: 0;
        max-width: none; } }
  .usa-flag__img-wrap {
    display: inline-block;
    position: relative; }
    .usa-flag__img-wrap::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 15px;
      width: 100%;
      height: 65px;
      background: #100300;
      filter: blur(120px);
      transform: skewX(30deg); }
    .usa-flag__img-wrap img {
      position: relative;
      z-index: 1;
      border: 10px solid #FFFCF2; }

.centre-of-the-earth {
  background-color: #F2E9D5; }
  @media (max-width: 767.98px) {
    .centre-of-the-earth {
      padding-bottom: 16vw; } }
  @media (min-width: 768px) {
    .centre-of-the-earth__inner {
      max-width: 70%; } }
  @media (min-width: 768px) {
    .centre-of-the-earth__inner {
      max-width: 68.05556%; } }
  .centre-of-the-earth__title > div, .centre-of-the-earth__content > div {
    width: fit-content; }
  @media (min-width: 768px) {
    .centre-of-the-earth__title {
      margin-bottom: 60px; } }
  @media (max-width: 767.98px) {
    .centre-of-the-earth__content:not([style])::after {
      content: '';
      display: inline-block;
      width: 17.33333vw; }
    .centre-of-the-earth__content[style] > div[style]:last-child::after {
      content: '';
      display: inline-block;
      width: 17.33333vw; } }
  .centre-of-the-earth__image {
    position: absolute;
    bottom: -42.66667vw;
    text-align: right;
    width: 58.13333vw;
    height: 64.53333vw;
    right: -18.66667vw;
    background-color: #FFFCF2;
    padding: 1.6vw;
    transform: rotate(32deg);
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 636 709'%3E%3Cellipse transform='rotate(0 318 354.5) scale(0.99681) translate(1.12974 1.12974)' cx='318' cy='354.5' rx='316.98658' ry='354.5'/%3E%3C/svg%3E");
    mask-repeat: no-repeat;
    mask-position: center; }
    .centre-of-the-earth__image img {
      mask-image: inherit;
      mask-repeat: inherit;
      mask-position: inherit;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    @media (min-width: 576px) {
      .centre-of-the-earth__image {
        bottom: -29.33333vw;
        width: 35.20833vw;
        height: 37.60417vw;
        right: 6.25%; } }
    @media (min-width: 768px) {
      .centre-of-the-earth__image {
        right: -6.25%;
        padding: 1.04167vw;
        bottom: -6.25vw; } }

.the-last-frontier {
  z-index: 1;
  padding-top: 58.66667vw; }
  @media (min-width: 768px) {
    .the-last-frontier {
      padding-top: 7.29167vw; } }
  .the-last-frontier-images {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
    .the-last-frontier-images__item {
      position: absolute;
      mask-repeat: no-repeat;
      mask-position: center;
      background-color: #FFFCF2;
      padding: 6px; }
      @media (min-width: 768px) {
        .the-last-frontier-images__item {
          padding: 1.04167vw; } }
      .the-last-frontier-images__item img {
        object-fit: cover;
        object-position: center;
        mask-repeat: no-repeat;
        mask-position: center;
        width: 100%;
        height: 100%;
        mask: inherit;
        display: block; }
      .the-last-frontier-images__item--first {
        top: 13.33333vw;
        left: 3%;
        width: 33.06667vw;
        height: 32vw;
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 112 108'%3E%3Cellipse transform='rotate(-54 56 54)' cx='56' cy='54' rx='51' ry='57.5'/%3E%3C/svg%3E"); }
        @media (min-width: 768px) {
          .the-last-frontier-images__item--first {
            top: 9.375vw;
            left: initial;
            right: 10.20833vw;
            width: 21.875vw;
            height: 21.04167vw; } }
      .the-last-frontier-images__item--second {
        width: 41.06667vw;
        height: 38.4vw;
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 154 144'%3E%3Cellipse transform='rotate(62 77 72)' cx='77' cy='72' rx='70' ry='78'/%3E%3C/svg%3E");
        top: 20.26667vw;
        right: -25.06667vw; }
        .section__foreground .the-last-frontier-images__item--second {
          display: none; }
          @media (max-width: 575.98px) {
            .section__foreground .the-last-frontier-images__item--second {
              display: block;
              z-index: -1;
              transform: translateY(-100%);
              top: auto; } }
        @media (max-width: 575.98px) {
          .the-last-frontier-images__item--second {
            display: none; } }
        @media (min-width: 768px) {
          .the-last-frontier-images__item--second {
            top: 29.16667vw;
            right: -9.375vw;
            width: 25.26042vw;
            height: 24.0625vw; } }
  .the-last-frontier__title {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 1.1;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .the-last-frontier__title {
        font-size: 4.16667vw; } }
    .the-last-frontier__title span {
      color: #C12F0F; }
    @media (min-width: 768px) {
      .the-last-frontier__title {
        max-width: 45.83333vw; } }
    @media (max-width: 767.98px) {
      .the-last-frontier__title:not(.the-last-frontier__title--large) {
        padding-right: 10.66667%; } }
    .the-last-frontier__title--large {
      font-family: 'Brice';
      font-style: normal;
      font-weight: 900;
      font-size: 58px;
      line-height: 1.1;
      text-transform: uppercase;
      margin-bottom: 0.17241em; }
      @media (min-width: 992px) {
        .the-last-frontier__title--large {
          font-size: 6.25vw; } }
      .the-last-frontier__title--large span {
        color: #C12F0F; }
    .the-last-frontier__title:first-child > div, .the-last-frontier__title:first-child > span {
      overflow: hidden;
      display: inline-block; }
    @media (min-width: 768px) {
      .the-last-frontier__title:nth-of-type(2) {
        margin-bottom: 60px; } }
  @media (min-width: 768px) {
    .the-last-frontier__content {
      max-width: 57.8125vw; } }

.iron-sky {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #F2E9D6;
  align-items: center; }
  @media (min-width: 768px) {
    .iron-sky {
      padding-top: 100px;
      padding-bottom: 100px; } }
  @media (min-width: 1200px) {
    .iron-sky {
      padding-top: 160px;
      padding-bottom: 160px; } }
  @media (min-width: 768px) {
    .iron-sky {
      flex-direction: row; } }
  .iron-sky__title {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 1.1;
    text-transform: uppercase;
    margin-bottom: 0.75em;
    word-break: break-all; }
    @media (min-width: 992px) {
      .iron-sky__title {
        font-size: 4.16667vw; } }
    .iron-sky__title span {
      color: #C12F0F; }
    @media (min-width: 992px) {
      .iron-sky__title {
        max-width: 90%; } }
  .iron-sky > .container {
    margin-bottom: 40px; }
    @media (min-width: 768px) {
      .iron-sky > .container {
        margin-bottom: 0;
        width: 50.5%;
        padding: 0 4%; } }
  .iron-sky__image {
    margin-right: -10px;
    max-width: 290px;
    width: 100%; }
    @media (min-width: 768px) {
      .iron-sky__image {
        width: 49.5%;
        max-width: none;
        position: relative;
        right: -20px; } }
    @media (min-width: 768px) {
      .iron-sky__image::before {
        content: '';
        display: block;
        padding-bottom: 79%; } }
    .iron-sky__image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
      border: 7px solid #FFFCF3; }
      @media (min-width: 768px) {
        .iron-sky__image img {
          border: 20px solid #FFFCF3;
          position: absolute;
          top: 0;
          left: 0; } }

.gigantomania-section {
  color: #FFFCF2; }
  @media (max-width: 991.98px) {
    .gigantomania-section {
      margin-bottom: 8%; } }
  .gigantomania-section .poster__mask {
    mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 82.90855 120.61483' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg' %3E%3Crect width='82.90855' height='120.61483' rx='16.58171'/%3E%3C/svg%3E");
    mask-repeat: no-repeat;
    mask-size: 100% 100%; }
  .gigantomania-section .section__background {
    background-color: #222222; }
    @media (min-width: 992px) {
      .gigantomania-section .section__background {
        border-radius: 50%/15%;
        border-top-left-radius: 0;
        border-top-right-radius: 0; } }
  .gigantomania-section .section__title, .gigantomania-section .futurism-title, .gigantomania-section .fr-article__title, .gigantomania-section .flowers-section__title, .gigantomania-section .centre-of-the-earth__title {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 1.1;
    text-transform: uppercase;
    padding-left: 16%; }
    @media (min-width: 992px) {
      .gigantomania-section .section__title, .gigantomania-section .futurism-title, .gigantomania-section .fr-article__title, .gigantomania-section .flowers-section__title, .gigantomania-section .centre-of-the-earth__title {
        font-size: 7.29167vw; } }
    .gigantomania-section .section__title span, .gigantomania-section .futurism-title span, .gigantomania-section .fr-article__title span, .gigantomania-section .flowers-section__title span, .gigantomania-section .centre-of-the-earth__title span {
      color: #C12F0F; }
    @media (min-width: 992px) {
      .gigantomania-section .section__title, .gigantomania-section .futurism-title, .gigantomania-section .fr-article__title, .gigantomania-section .flowers-section__title, .gigantomania-section .centre-of-the-earth__title {
        padding-left: 0;
        width: 54.06977%;
        margin-left: 45.69767%; } }
    .gigantomania-section .section__title span, .gigantomania-section .futurism-title span, .gigantomania-section .fr-article__title span, .gigantomania-section .flowers-section__title span, .gigantomania-section .centre-of-the-earth__title span {
      color: #C12F0F; }
    .gigantomania-section .section__title > div:first-child, .gigantomania-section .futurism-title > div:first-child, .gigantomania-section .fr-article__title > div:first-child, .gigantomania-section .flowers-section__title > div:first-child, .gigantomania-section .centre-of-the-earth__title > div:first-child {
      margin-left: -16%; }
      @media (min-width: 992px) {
        .gigantomania-section .section__title > div:first-child, .gigantomania-section .futurism-title > div:first-child, .gigantomania-section .fr-article__title > div:first-child, .gigantomania-section .flowers-section__title > div:first-child, .gigantomania-section .centre-of-the-earth__title > div:first-child {
          margin-left: -21.50538%; } }
  .gigantomania-section .section__subtitle {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 1.1;
    text-transform: uppercase;
    margin-bottom: 0.75em; }
    @media (min-width: 992px) {
      .gigantomania-section .section__subtitle {
        font-size: 4.16667vw; } }
    .gigantomania-section .section__subtitle span {
      color: #C12F0F; }
    @media (max-width: 575.98px) {
      .gigantomania-section .section__subtitle {
        max-width: 90%;
        min-width: 280px; } }
    .gigantomania-section .section__subtitle span {
      color: #C12F0F; }
  .gigantomania-section .section__content-item:not(:last-child) {
    margin-bottom: 200px; }
    @media (min-width: 992px) {
      .gigantomania-section .section__content-item:not(:last-child) {
        margin-bottom: 23.83721%; } }
  @media (min-width: 992px) {
    .gigantomania-section .section__content-item {
      margin-left: 34.06977%;
      padding-left: 11.62791%;
      padding-right: 10.46512%;
      width: 65.69767%; } }
  .gigantomania-section .zeppelin {
    position: absolute; }
    .gigantomania-section .zeppelin__img {
      display: block;
      width: 100%; }
    .gigantomania-section .zeppelin--small {
      top: -12px;
      width: min( 21.86667%, 150px);
      right: 15px; }
      @media (min-width: 992px) {
        .gigantomania-section .zeppelin--small {
          width: 13.64583%;
          top: -4.16667%;
          right: 5%; } }
    .gigantomania-section .zeppelin--big {
      opacity: 0.22;
      top: 20.83333%;
      width: min( 85.33333%, 500px);
      right: -9.33333%; }
      @media (min-width: 992px) {
        .gigantomania-section .zeppelin--big {
          top: 19.79167%;
          width: 52.08333%;
          right: -18.4375%; } }
      @media (max-width: 575.98px) {
        .gigantomania-section .zeppelin--big {
          top: 32%; } }
  @media (max-width: 991.98px) {
    .gigantomania-section .poster__holder {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 16%;
      width: 100%;
      align-items: flex-start; } }
  @media (min-width: 992px) {
    .gigantomania-section .poster__holder {
      left: 0;
      top: 0;
      bottom: 0;
      width: 34.375%;
      position: absolute; } }
  .gigantomania-section .poster__mask {
    overflow: hidden;
    background-color: #FFFCF2; }
  .gigantomania-section .poster {
    box-shadow: 19px 21px 90px rgba(0, 0, 0, 0.19);
    border-radius: 20%;
    backface-visibility: hidden; }
    @media (min-width: 992px) {
      .gigantomania-section .poster {
        position: absolute; } }
  @media (max-width: 991.98px) {
    .gigantomania-section .poster--1 {
      display: none; } }
  @media (min-width: 992px) {
    .gigantomania-section .poster--1 {
      transform: rotate(28.47deg);
      left: -9%;
      top: 9.375%;
      z-index: 4;
      width: 100%; } }
  .gigantomania-section .poster--2 {
    width: 38.66667%;
    z-index: 2; }
    @media (max-width: 991.98px) {
      .gigantomania-section .poster--2 {
        margin-right: 10.13333%; } }
    @media (min-width: 992px) {
      .gigantomania-section .poster--2 {
        transform: rotate(-1.21deg);
        width: 85.45455%;
        z-index: 3;
        top: 42.72727%;
        left: -47.87879%; } }
  .gigantomania-section .poster--3 {
    width: 32%;
    transform: rotate(29.251deg);
    z-index: 1; }
    @media (max-width: 991.98px) {
      .gigantomania-section .poster--3 {
        margin-top: 16.53333%; } }
    @media (min-width: 992px) {
      .gigantomania-section .poster--3 {
        left: 15.15152%;
        top: 66.16162%;
        width: 58.63636%;
        transform: rotate(29.47deg);
        z-index: 2; } }
  .gigantomania-section .poster--4 {
    width: 26.66667%;
    transform: rotate(-6.45deg); }
    @media (max-width: 991.98px) {
      .gigantomania-section .poster--4 {
        margin-left: -3.2%;
        margin-top: 25.33333%; } }
    @media (min-width: 992px) {
      .gigantomania-section .poster--4 {
        width: 45.45455%;
        transform: rotate(-6.93deg);
        left: 71.21212%;
        top: 73.23232%;
        z-index: 1; } }
  .gigantomania-section .poster__img {
    display: block;
    width: 93.93939%;
    margin: 3.0303%;
    mask: inherit;
    aspect-ratio: var(--aspect-ratio); }
  .gigantomania-section .planet {
    width: min( 77.33333%, 576px);
    left: -23.46667%;
    position: absolute;
    bottom: 0;
    will-change: transform;
    backface-visibility: hidden; }
    @media (max-width: 991.98px) {
      .gigantomania-section .planet {
        margin-bottom: -8%; } }
    @media (min-width: 992px) {
      .gigantomania-section .planet {
        width: 54.42708%;
        left: -17.70833%; } }
    .gigantomania-section .planet__img {
      width: 100%;
      display: block;
      aspect-ratio: 1;
      box-shadow: 19px 21px 90px rgba(0, 0, 0, 0.19);
      background-color: rgba(0, 0, 0, 0.19);
      border-radius: 50%; }

.today-dreams {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px;
  font-size: 18px; }
  @media (min-width: 768px) {
    .today-dreams {
      padding-top: 100px;
      padding-bottom: 100px; } }
  @media (min-width: 1200px) {
    .today-dreams {
      padding-top: 160px;
      padding-bottom: 160px; } }
  @media (min-width: 992px) {
    .today-dreams {
      font-size: 1.66667vw; } }
  .today-dreams__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
  .today-dreams__title {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 0.9;
    text-transform: uppercase;
    margin-bottom: 0; }
    @media (min-width: 992px) {
      .today-dreams__title {
        font-size: 6.25vw; } }
    .today-dreams__title span {
      color: #C12F0F; }
    .today-dreams__title strong {
      display: block; }
    .today-dreams__title--red {
      color: #C12F0F;
      padding-bottom: 20px;
      overflow: hidden; }
  .today-dreams__head {
    flex: 0 0 100%;
    max-width: 100%; }
    @media (max-width: 767.98px) {
      .today-dreams__head {
        margin-bottom: 20px; } }
  .today-dreams__head-offset {
    padding-left: 8.33333%; }
  .today-dreams__content {
    flex: 0 0 100%;
    max-width: 100%; }
    @media (max-width: 767.98px) {
      .today-dreams__content {
        padding-left: 8.33333%; } }
  @media (min-width: 576px) {
    .today-dreams__head {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .today-dreams__content {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; } }
  .today-dreams-images {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    margin-top: 8.33333%; }
    @media (max-width: 767.98px) {
      .today-dreams-images {
        margin-top: 40px; } }
    .today-dreams-images__item {
      flex-shrink: 0;
      text-align: center;
      max-width: 56.23342%;
      padding: 0 1.04167%; }
      @media (max-width: 991.98px) {
        .today-dreams-images__item {
          width: 56%;
          padding: 0 0.52083%; } }
      @media (min-width: 992px) {
        .today-dreams-images__item {
          max-width: 25.52083%; } }
      .today-dreams-images__item img {
        mask-image: url(../img/today-dream/todays-dream-mask.svg);
        mask-size: cover;
        mask-repeat: round;
        width: 100%;
        display: block;
        height: 39.46667vw;
        object-fit: cover; }
        @media (min-width: 992px) {
          .today-dreams-images__item img {
            height: 17.60417vw; } }

.end-great-future-section {
  color: #FFFCF2;
  z-index: 2; }
  .end-great-future-section .section__background {
    background-color: #C12F0F; }
    .end-great-future-section .section__background::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      mix-blend-mode: hard-light;
      background-image: url(../img/paper-texture.png);
      background-position: center;
      background-size: cover; }
  .end-great-future-section .section__title, .end-great-future-section .futurism-title, .end-great-future-section .fr-article__title, .end-great-future-section .flowers-section__title, .end-great-future-section .centre-of-the-earth__title {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 1.1;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .end-great-future-section .section__title, .end-great-future-section .futurism-title, .end-great-future-section .fr-article__title, .end-great-future-section .flowers-section__title, .end-great-future-section .centre-of-the-earth__title {
        font-size: 4.6875vw; } }
    .end-great-future-section .section__title span, .end-great-future-section .futurism-title span, .end-great-future-section .fr-article__title span, .end-great-future-section .flowers-section__title span, .end-great-future-section .centre-of-the-earth__title span {
      color: #C12F0F; }
    .end-great-future-section .section__title span, .end-great-future-section .futurism-title span, .end-great-future-section .fr-article__title span, .end-great-future-section .flowers-section__title span, .end-great-future-section .centre-of-the-earth__title span {
      color: #222222 !important; }
    .end-great-future-section .section__title::before, .end-great-future-section .futurism-title::before, .end-great-future-section .fr-article__title::before, .end-great-future-section .flowers-section__title::before, .end-great-future-section .centre-of-the-earth__title::before {
      content: '';
      float: right;
      display: block;
      width: min(76px, 12.8%);
      height: 1.1em;
      margin-right: -15px; }
      @media (min-width: 992px) {
        .end-great-future-section .section__title::before, .end-great-future-section .futurism-title::before, .end-great-future-section .fr-article__title::before, .end-great-future-section .flowers-section__title::before, .end-great-future-section .centre-of-the-earth__title::before {
          margin-right: -5.6%; } }
  @media (min-width: 992px) {
    .end-great-future-section .section__content {
      width: 57.55814%; } }
  .end-great-future-section .airplane {
    width: min(104px, 19.2%);
    height: 106.2px;
    position: absolute;
    right: 0;
    top: 0; }
    @media (min-width: 768px) {
      .end-great-future-section .airplane {
        height: 142px; } }
    @media (min-width: 992px) {
      .end-great-future-section .airplane {
        width: 8.85417%;
        height: auto; } }
    .end-great-future-section .airplane img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: top right; }
  .end-great-future-section .gallery {
    margin-top: 12.393%;
    margin-bottom: -10.66667%;
    transform: rotate(-12.51deg);
    z-index: -1; }
    @media (min-width: 992px) {
      .end-great-future-section .gallery {
        margin-top: 4.16667%;
        margin-bottom: -8.33333%; } }
    .end-great-future-section .gallery-inner {
      display: flex;
      align-items: stretch;
      width: 110%; }
    .end-great-future-section .gallery-item {
      padding-left: 5px;
      padding-right: 5px; }
      @media (min-width: 576px) {
        .end-great-future-section .gallery-item {
          padding-left: 10px;
          padding-right: 10px; } }
      @media (min-width: 992px) {
        .end-great-future-section .gallery-item {
          padding-left: 20px;
          padding-right: 20px; } }
      .end-great-future-section .gallery-item:first-child {
        padding-left: 0; }
      .end-great-future-section .gallery-item:last-child {
        padding-right: 0; }
    .end-great-future-section .gallery-img {
      display: block;
      border: 4px solid #FFFCF2;
      background-color: #FFFCF2;
      border-radius: 12px;
      height: 22vw;
      box-shadow: 19px 21px 90px rgba(0, 0, 0, 0.19);
      aspect-ratio: var(--aspect-ratio); }
      @media (min-width: 576px) {
        .end-great-future-section .gallery-img {
          border-width: 6px;
          border-radius: 30px; } }
      @media (min-width: 992px) {
        .end-great-future-section .gallery-img {
          border-width: 8px;
          border-radius: 40px; } }
      @media (min-width: 1200px) {
        .end-great-future-section .gallery-img {
          border-width: 10px;
          border-radius: 60px; } }

.dream-technology {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-top: min( 77.33333vw , 420px);
  padding-bottom: 81.6vw;
  background-color: #F2E9D6; }
  @media (min-width: 768px) {
    .dream-technology {
      padding-top: 100px;
      padding-bottom: 100px; } }
  @media (min-width: 1200px) {
    .dream-technology {
      padding-top: 160px;
      padding-bottom: 160px; } }
  @media (min-width: 768px) {
    .dream-technology {
      padding-top: 45.83333vw; } }
  @media (min-width: 768px) {
    .the-last-frontier + .dream-technology {
      margin-top: -100px; } }
  @media (min-width: 1200px) {
    .the-last-frontier + .dream-technology {
      margin-top: -160px; } }
  .dream-technology__foreground {
    z-index: 1; }
  .dream-technology__background {
    position: absolute;
    width: 100%;
    top: -2px;
    left: 0;
    background-color: #FFFCF2;
    height: calc(100% -  24vw  - 50px); }
    @media (min-width: 768px) {
      .dream-technology__background {
        height: 22.78646vw; } }
    .dream-technology__background::before {
      content: '';
      width: 120%;
      height: 48vw;
      position: absolute;
      bottom: 0;
      left: -10%;
      transform: translateY(50%);
      background-color: #FFFCF2;
      border-radius: 50%; }
      @media (min-width: 768px) {
        .dream-technology__background::before {
          height: 20.05208vw; } }
  .dream-technology__image {
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 100% 100%;
    padding: 1.6vw;
    position: absolute;
    background-color: #FFFCF2; }
    @media (min-width: 768px) {
      .dream-technology__image {
        padding: 1.04167vw;
        background-color: #FFFCF2; } }
    .dream-technology__image img {
      display: block;
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 100% 100%;
      mask-image: inherit;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .dream-technology__image--first {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 580 630'%3E%3Cellipse transform='rotate(-23 290 315)' cx='290' cy='315' rx='282.5' ry='318'/%3E%3C/svg%3E");
      width: min( 61.86667vw , 360px);
      height: min( 69.86667vw , 410px);
      left: 0;
      right: 0;
      top: 0;
      margin: auto; }
      @media (min-width: 768px) {
        .dream-technology__image--first {
          top: 0;
          right: 4.16667vw;
          left: auto;
          width: 32.29167vw;
          height: 34.89583vw; } }
    .dream-technology__image--second {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 545 565'%3E%3Cellipse transform='rotate(37 272.5 282.5)' cx='272.5' cy='282.5' rx='261' ry='294'/%3E%3C/svg%3E");
      width: 43.2vw;
      height: 48.53333vw;
      left: -10.66667vw;
      bottom: 0; }
      @media (min-width: 768px) {
        .dream-technology__image--second {
          bottom: auto;
          top: 10.41667vw;
          left: 20vw;
          width: 30.46875vw;
          height: 31.51042vw; } }
    .dream-technology__image--third {
      position: absolute;
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 836 865'%3E%3Cellipse transform='rotate(36.78 418 432.5)' cx='418' cy='432.5' rx='400' ry='450'/%3E%3C/svg%3E");
      mask-repeat: no-repeat;
      mask-position: center;
      width: 68.8vw;
      height: 77.33333vw;
      right: -25.33333vw;
      bottom: 5.33333vw; }
      @media (min-width: 768px) {
        .dream-technology__image--third {
          width: 45.625vw;
          height: 47.13542vw;
          top: 40.10417vw;
          left: -15.10417vw; } }
  @media (min-width: 768px) {
    .dream-technology__inner {
      margin-left: auto;
      width: 51.30208vw; } }
  .dream-technology__title {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 1.1;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .dream-technology__title {
        font-size: 4.16667vw; } }
    .dream-technology__title span {
      color: #C12F0F; }
    @media (min-width: 768px) {
      .dream-technology__title {
        margin-bottom: 3.125vw; } }

@media (min-width: 768px) {
  .space-adventures-wrap {
    padding-top: 2.77778em; } }

.space-adventures-section {
  position: relative;
  min-height: 100vh; }
  .space-adventures-section .section__foreground {
    position: static; }
  @media (min-width: 768px) {
    .space-adventures-section .section__content {
      width: 55vw; } }
  @media (min-width: 1200px) {
    .space-adventures-section .section__content {
      width: 51.5625vw; } }
  .space-adventures-section .gallery {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    position: relative;
    padding: 0 16px;
    backface-visibility: hidden;
    min-width: 420px;
    width: 80%;
    margin: 40px auto 0; }
    @media (min-width: 768px) {
      .space-adventures-section .gallery {
        padding: 0;
        position: absolute;
        right: -5.20833vw;
        top: 0;
        height: 100%;
        margin: auto;
        bottom: 0;
        width: 43vw;
        min-width: initial; } }
    @media (min-width: 1200px) {
      .space-adventures-section .gallery {
        width: 40.625vw; } }
    .space-adventures-section .gallery::before, .space-adventures-section .gallery::after {
      content: '';
      display: block;
      width: 100%;
      left: 0;
      position: absolute;
      z-index: 1;
      height: 60px; }
      @media (min-width: 768px) {
        .space-adventures-section .gallery::before, .space-adventures-section .gallery::after {
          height: 100px; } }
      @media (min-width: 1200px) {
        .space-adventures-section .gallery::before, .space-adventures-section .gallery::after {
          height: 160px; } }
    .space-adventures-section .gallery::before {
      top: -2px;
      background: linear-gradient(0deg, rgba(255, 252, 242, 0) 0, #FFFCF2 75%); }
    .space-adventures-section .gallery::after {
      bottom: -2px;
      background: linear-gradient(180deg, rgba(255, 252, 242, 0) 0, #FFFCF2 75%); }
    .space-adventures-section .gallery__inner {
      height: inherit;
      display: flex;
      flex-direction: row;
      margin: 0 -10px; }
    .space-adventures-section .gallery__track {
      width: 50%;
      left: 0;
      top: 0;
      max-height: 100%;
      padding: 0 10px;
      align-self: center; }
      .space-adventures-section .gallery__track:nth-child(1) .gallery__item:last-child {
        margin-bottom: 25%; }
      .space-adventures-section .gallery__track:nth-child(2) .gallery__item:first-child {
        margin-top: 25%; }
      .space-adventures-section .gallery__track img {
        border-radius: 22px;
        display: block;
        aspect-ratio: var(--aspect-ratio);
        width: 100%; }
    .space-adventures-section .gallery__item {
      padding: 10px 0; }
      .space-adventures-section .gallery__item:last-child {
        padding-bottom: 0; }
      .space-adventures-section .gallery__item:first-child {
        padding-top: 0; }

.header {
  font-size: 14px;
  font-family: "Cera Pro";
  text-transform: uppercase;
  font-weight: 700;
  position: absolute;
  z-index: 6;
  left: 0;
  top: 0;
  width: 100%;
  padding: 18px 0;
  color: #C12F0F; }
  @media (min-width: 992px) {
    .header {
      font-size: 1.25vw; } }
  .header__inner {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  @media (min-width: 992px) {
    .header {
      padding: 5vh 0; } }
  .header p {
    margin-bottom: 0; }
  .header__label {
    display: flex;
    align-items: center;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    flex-shrink: 0; }
    .header__label img {
      display: block;
      margin-right: 12px; }
  .header__arrow {
    height: 2px;
    background-color: #C12F0F;
    margin-left: 60px;
    margin-right: 65px;
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end; }
    @media (max-width: 767.98px) {
      .header__arrow {
        display: none; } }
    .header__arrow img {
      position: relative;
      right: -2px; }

.end-great-future-section + .footer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 91px; }
  @media (min-width: 768px) {
    .end-great-future-section + .footer {
      padding-top: 150px; } }
  @media (min-width: 1200px) {
    .end-great-future-section + .footer {
      padding-top: 240px; } }
  @media (min-width: 1200px) {
    .end-great-future-section + .footer {
      padding-top: calc(160px + 11.91355%); } }

.footer {
  background-color: #222222;
  color: #FFFCF2;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 60px;
  overflow: hidden; }
  @media (min-width: 768px) {
    .footer {
      padding-top: 100px; } }
  @media (min-width: 1200px) {
    .footer {
      padding-top: 160px; } }
  .footer img {
    aspect-ratio: var(--aspect-ratio);
    object-fit: contain; }
  .footer__top .footer__title {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 1.1;
    text-transform: uppercase;
    margin-bottom: 0.5em; }
    @media (min-width: 992px) {
      .footer__top .footer__title {
        font-size: 4.16667vw; } }
    .footer__top .footer__title span {
      color: #C12F0F; }
    .footer__top .footer__title span {
      color: #C12F0F; }
  .footer__top .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-left: -20px; }
  .footer__top .col {
    position: relative;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px; }
    @media (min-width: 1200px) {
      .footer__top .col:first-child {
        flex: 0 0 40.83333%;
        max-width: 40.83333%; }
      .footer__top .col:last-child {
        flex: 0 0 59.16667%;
        max-width: 59.16667%; } }
  .footer__top p {
    margin-bottom: 1em; }
  .footer__top .lead {
    font-family: 'Brice';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 1.1;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .footer__top .lead {
        font-size: 1.66667vw; } }
    .footer__top .lead span {
      color: #C12F0F; }
    .footer__top .lead:not(:first-child) {
      margin-top: 1.875em; }
  .footer__bottom {
    position: relative;
    z-index: 1; }
    .footer__bottom .logo-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
      background-image: url(../img/footer/keyhole.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain; }
    .footer__bottom .light {
      position: absolute;
      z-index: -3;
      width: 112.5vw;
      height: 112.5vw;
      left: -50vw;
      top: -45.3125vw;
      border-radius: 50%;
      background: radial-gradient(ellipse at center, #41403f 0%, #3c3c3b 11.42857%, #282828 43.57143%, #222222 71.42857%, rgba(34, 34, 34, 0) 100%); }
    .footer__bottom .footer__title {
      font-family: 'Brice';
      font-style: normal;
      font-weight: 900;
      font-size: 56px;
      line-height: 1.1;
      text-transform: uppercase;
      color: #FFFCF2;
      text-align: center;
      margin-bottom: 2.32558%; }
      @media (min-width: 992px) {
        .footer__bottom .footer__title {
          font-size: 7.29167vw; } }
      .footer__bottom .footer__title span {
        color: #C12F0F; }
      .footer__bottom .footer__title span {
        color: #F8EBC1; }
    .footer__bottom p {
      margin-left: auto;
      margin-right: auto;
      max-width: 65.93023%;
      text-align: center; }
    .footer__bottom .designed-by {
      text-align: center;
      color: #F8EBC1;
      margin-top: 7.55814%; }
      .footer__bottom .designed-by .logo {
        height: 1.2em;
        width: 1.2em; }
  .footer .copy {
    font-size: 16px;
    padding: 16px 0;
    border-top: 1px solid rgba(255, 252, 242, 0.4);
    color: #FFFCF2; }
    @media (max-width: 991.98px) {
      .footer .copy {
        display: none; } }
    .footer .copy .logo {
      width: 1em;
      height: 1em; }
  .footer .logo {
    display: inline-block; }
  .footer .social {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    padding-bottom: 3.02326%; }
    .footer .social__holder {
      position: relative;
      margin-top: 2.32558%;
      margin-bottom: 13.95349%; }
      @media (max-width: 991.98px) {
        .footer .social__holder {
          margin-top: 16%;
          margin-bottom: 0;
          padding-bottom: 16.86047%; } }
      @media (max-width: 575.98px) {
        .footer .social__holder {
          padding-bottom: 40%; } }
      .footer .social__holder .moon {
        position: absolute;
        z-index: -1;
        top: max(70px, 5.41667vw);
        width: 69.58333vw;
        left: 50%;
        transform: translateX(-50%); }
        @media (max-width: 991.98px) {
          .footer .social__holder .moon {
            width: 100%; } }
        @media (max-width: 575.98px) {
          .footer .social__holder .moon {
            width: 150%; } }
        .footer .social__holder .moon img {
          width: 100%;
          display: block; }
    .footer .social__item {
      text-align: center;
      padding: 0 1.97674%; }
    .footer .social li:first-child:nth-last-child(3),
    .footer .social li:first-child:nth-last-child(3) ~ li {
      margin-top: 0.625%;
      margin-bottom: -0.625%; }
      .footer .social li:first-child:nth-last-child(3):nth-child(2),
      .footer .social li:first-child:nth-last-child(3) ~ li:nth-child(2) {
        margin-top: 0%;
        margin-bottom: 0%; }
      .footer .social li:first-child:nth-last-child(3):nth-child(3),
      .footer .social li:first-child:nth-last-child(3) ~ li:nth-child(3) {
        margin-top: 0.625%;
        margin-bottom: -0.625%; }
    .footer .social li:first-child:nth-last-child(4),
    .footer .social li:first-child:nth-last-child(4) ~ li {
      margin-top: 1.35417%;
      margin-bottom: -1.35417%; }
      .footer .social li:first-child:nth-last-child(4):nth-child(2),
      .footer .social li:first-child:nth-last-child(4) ~ li:nth-child(2) {
        margin-top: 0.20833%;
        margin-bottom: -0.20833%; }
      .footer .social li:first-child:nth-last-child(4):nth-child(3),
      .footer .social li:first-child:nth-last-child(4) ~ li:nth-child(3) {
        margin-top: 0.20833%;
        margin-bottom: -0.20833%; }
      .footer .social li:first-child:nth-last-child(4):nth-child(4),
      .footer .social li:first-child:nth-last-child(4) ~ li:nth-child(4) {
        margin-top: 1.35417%;
        margin-bottom: -1.35417%; }
    .footer .social li:first-child:nth-last-child(5),
    .footer .social li:first-child:nth-last-child(5) ~ li {
      margin-top: 2.39583%;
      margin-bottom: -2.39583%; }
      .footer .social li:first-child:nth-last-child(5):nth-child(2),
      .footer .social li:first-child:nth-last-child(5) ~ li:nth-child(2) {
        margin-top: 0.625%;
        margin-bottom: -0.625%; }
      .footer .social li:first-child:nth-last-child(5):nth-child(3),
      .footer .social li:first-child:nth-last-child(5) ~ li:nth-child(3) {
        margin-top: 0%;
        margin-bottom: 0%; }
      .footer .social li:first-child:nth-last-child(5):nth-child(4),
      .footer .social li:first-child:nth-last-child(5) ~ li:nth-child(4) {
        margin-top: 0.625%;
        margin-bottom: -0.625%; }
      .footer .social li:first-child:nth-last-child(5):nth-child(5),
      .footer .social li:first-child:nth-last-child(5) ~ li:nth-child(5) {
        margin-top: 2.39583%;
        margin-bottom: -2.39583%; }
    .footer .social li:first-child:nth-last-child(6),
    .footer .social li:first-child:nth-last-child(6) ~ li {
      margin-top: 3.85417%;
      margin-bottom: -3.85417%; }
      .footer .social li:first-child:nth-last-child(6):nth-child(2),
      .footer .social li:first-child:nth-last-child(6) ~ li:nth-child(2) {
        margin-top: 1.35417%;
        margin-bottom: -1.35417%; }
      .footer .social li:first-child:nth-last-child(6):nth-child(3),
      .footer .social li:first-child:nth-last-child(6) ~ li:nth-child(3) {
        margin-top: 0.20833%;
        margin-bottom: -0.20833%; }
      .footer .social li:first-child:nth-last-child(6):nth-child(4),
      .footer .social li:first-child:nth-last-child(6) ~ li:nth-child(4) {
        margin-top: 0.20833%;
        margin-bottom: -0.20833%; }
      .footer .social li:first-child:nth-last-child(6):nth-child(5),
      .footer .social li:first-child:nth-last-child(6) ~ li:nth-child(5) {
        margin-top: 1.35417%;
        margin-bottom: -1.35417%; }
      .footer .social li:first-child:nth-last-child(6):nth-child(6),
      .footer .social li:first-child:nth-last-child(6) ~ li:nth-child(6) {
        margin-top: 3.85417%;
        margin-bottom: -3.85417%; }
    .footer .social li:first-child:nth-last-child(7),
    .footer .social li:first-child:nth-last-child(7) ~ li {
      margin-top: 5.625%;
      margin-bottom: -5.625%; }
      .footer .social li:first-child:nth-last-child(7):nth-child(2),
      .footer .social li:first-child:nth-last-child(7) ~ li:nth-child(2) {
        margin-top: 2.39583%;
        margin-bottom: -2.39583%; }
      .footer .social li:first-child:nth-last-child(7):nth-child(3),
      .footer .social li:first-child:nth-last-child(7) ~ li:nth-child(3) {
        margin-top: 0.625%;
        margin-bottom: -0.625%; }
      .footer .social li:first-child:nth-last-child(7):nth-child(4),
      .footer .social li:first-child:nth-last-child(7) ~ li:nth-child(4) {
        margin-top: 0%;
        margin-bottom: 0%; }
      .footer .social li:first-child:nth-last-child(7):nth-child(5),
      .footer .social li:first-child:nth-last-child(7) ~ li:nth-child(5) {
        margin-top: 0.625%;
        margin-bottom: -0.625%; }
      .footer .social li:first-child:nth-last-child(7):nth-child(6),
      .footer .social li:first-child:nth-last-child(7) ~ li:nth-child(6) {
        margin-top: 2.39583%;
        margin-bottom: -2.39583%; }
      .footer .social li:first-child:nth-last-child(7):nth-child(7),
      .footer .social li:first-child:nth-last-child(7) ~ li:nth-child(7) {
        margin-top: 5.625%;
        margin-bottom: -5.625%; }
    .footer .social li:first-child:nth-last-child(8),
    .footer .social li:first-child:nth-last-child(8) ~ li {
      margin-top: 7.8125%;
      margin-bottom: -7.8125%; }
      .footer .social li:first-child:nth-last-child(8):nth-child(2),
      .footer .social li:first-child:nth-last-child(8) ~ li:nth-child(2) {
        margin-top: 3.85417%;
        margin-bottom: -3.85417%; }
      .footer .social li:first-child:nth-last-child(8):nth-child(3),
      .footer .social li:first-child:nth-last-child(8) ~ li:nth-child(3) {
        margin-top: 1.35417%;
        margin-bottom: -1.35417%; }
      .footer .social li:first-child:nth-last-child(8):nth-child(4),
      .footer .social li:first-child:nth-last-child(8) ~ li:nth-child(4) {
        margin-top: 0.20833%;
        margin-bottom: -0.20833%; }
      .footer .social li:first-child:nth-last-child(8):nth-child(5),
      .footer .social li:first-child:nth-last-child(8) ~ li:nth-child(5) {
        margin-top: 0.20833%;
        margin-bottom: -0.20833%; }
      .footer .social li:first-child:nth-last-child(8):nth-child(6),
      .footer .social li:first-child:nth-last-child(8) ~ li:nth-child(6) {
        margin-top: 1.35417%;
        margin-bottom: -1.35417%; }
      .footer .social li:first-child:nth-last-child(8):nth-child(7),
      .footer .social li:first-child:nth-last-child(8) ~ li:nth-child(7) {
        margin-top: 3.85417%;
        margin-bottom: -3.85417%; }
      .footer .social li:first-child:nth-last-child(8):nth-child(8),
      .footer .social li:first-child:nth-last-child(8) ~ li:nth-child(8) {
        margin-top: 7.8125%;
        margin-bottom: -7.8125%; }
    .footer .social li:first-child:nth-last-child(9),
    .footer .social li:first-child:nth-last-child(9) ~ li {
      margin-top: 10.83333%;
      margin-bottom: -10.83333%; }
      .footer .social li:first-child:nth-last-child(9):nth-child(2),
      .footer .social li:first-child:nth-last-child(9) ~ li:nth-child(2) {
        margin-top: 5.625%;
        margin-bottom: -5.625%; }
      .footer .social li:first-child:nth-last-child(9):nth-child(3),
      .footer .social li:first-child:nth-last-child(9) ~ li:nth-child(3) {
        margin-top: 2.39583%;
        margin-bottom: -2.39583%; }
      .footer .social li:first-child:nth-last-child(9):nth-child(4),
      .footer .social li:first-child:nth-last-child(9) ~ li:nth-child(4) {
        margin-top: 0.625%;
        margin-bottom: -0.625%; }
      .footer .social li:first-child:nth-last-child(9):nth-child(5),
      .footer .social li:first-child:nth-last-child(9) ~ li:nth-child(5) {
        margin-top: 0%;
        margin-bottom: 0%; }
      .footer .social li:first-child:nth-last-child(9):nth-child(6),
      .footer .social li:first-child:nth-last-child(9) ~ li:nth-child(6) {
        margin-top: 0.625%;
        margin-bottom: -0.625%; }
      .footer .social li:first-child:nth-last-child(9):nth-child(7),
      .footer .social li:first-child:nth-last-child(9) ~ li:nth-child(7) {
        margin-top: 2.39583%;
        margin-bottom: -2.39583%; }
      .footer .social li:first-child:nth-last-child(9):nth-child(8),
      .footer .social li:first-child:nth-last-child(9) ~ li:nth-child(8) {
        margin-top: 5.625%;
        margin-bottom: -5.625%; }
      .footer .social li:first-child:nth-last-child(9):nth-child(9),
      .footer .social li:first-child:nth-last-child(9) ~ li:nth-child(9) {
        margin-top: 10.83333%;
        margin-bottom: -10.83333%; }
    .footer .social__link {
      position: relative;
      width: max(40px, 2.70833vw);
      height: max(40px, 2.70833vw);
      padding: 26.92308%;
      background-color: #F8EBC1;
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: #C12F0F;
      text-decoration: none;
      font-size: max(18px, 1.25vw);
      transition: opacity 0.25s; }
      .footer .social__link:hover {
        opacity: 0.7; }
  .footer .gallery {
    position: relative;
    z-index: 2;
    margin-top: 8.13953%;
    margin-bottom: 8.13953%; }
    @media (max-width: 767.98px) {
      .footer .gallery {
        margin-top: 13.33333%;
        margin-bottom: 16%; } }
    .footer .gallery__row {
      display: flex;
      flex-wrap: wrap;
      margin-right: -10px;
      margin-left: -10px; }
      @media (max-width: 767.98px) {
        .footer .gallery__row {
          margin-right: -4px;
          margin-left: -4px; } }
    .footer .gallery__col {
      position: relative;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px; }
      @media (max-width: 767.98px) {
        .footer .gallery__col {
          padding-right: 4px;
          padding-left: 4px; } }
      .footer .gallery__col:first-child {
        flex: 0 0 26.66667%;
        max-width: 26.66667%; }
      .footer .gallery__col:last-child {
        flex: 0 0 73.33333%;
        max-width: 73.33333%; }
      .footer .gallery__col img {
        width: 100%;
        height: 100%;
        display: block; }
      .footer .gallery__col:first-child .gallery__item {
        border-radius: 52.08333vw 5.20833vw 3.125vw; }
        .footer .gallery__col:first-child .gallery__item img {
          display: block;
          object-fit: cover;
          border-radius: 57.29167vw 4.6875vw 2.60417vw; }
      .footer .gallery__col:last-child .gallery__item {
        border-radius: 5.20833vw 3.125vw 10.41667vw; }
        .footer .gallery__col:last-child .gallery__item img {
          object-fit: cover;
          border-radius: 4.6875vw 2.60417vw 9.89583vw; }
    .footer .gallery__item {
      height: 29.6875vw;
      width: 100%;
      overflow: hidden;
      padding: max(4px, 0.52083vw);
      background-color: #FFFCF2;
      box-shadow: 19px 21px 90px rgba(0, 0, 0, 0.19); }
  .footer .planet {
    position: absolute;
    z-index: -1; }
    .footer .planet img {
      display: block;
      width: 100%;
      aspect-ratio: 1/1; }
    .footer .planet--mars {
      right: 5.20833vw;
      bottom: 11.66667vw;
      width: 13.54167vw; }
      @media (max-width: 767.98px) {
        .footer .planet--mars {
          width: 24.53333vw;
          top: 34.66667vw;
          right: -12.26667vw; } }
    .footer .planet--pluto {
      width: 3.34479vw;
      right: 2.34375vw;
      top: 20.625vw;
      transform: rotate(-8.71deg); }
      @media (max-width: 767.98px) {
        .footer .planet--pluto {
          display: none; } }
    .footer .planet--pluto-grey {
      width: 7.12135vw;
      right: -2.8125vw;
      top: 7.96875vw;
      transform: rotate(-72.8deg); }
      @media (max-width: 767.98px) {
        .footer .planet--pluto-grey {
          display: none; } }
    .footer .planet--neptune {
      opacity: 0.6;
      display: block;
      top: -4.27083vw;
      right: 23.4375vw;
      width: 4.79167vw; }
      @media (max-width: 767.98px) {
        .footer .planet--neptune {
          width: 6.13333vw;
          top: 12.8vw;
          right: 4.26667vw; } }
  .footer .astronaut {
    position: absolute;
    z-index: -1;
    width: 10.92708vw;
    height: 11.1901vw;
    left: 12.29167vw;
    bottom: 17.60417vw;
    transform: rotate(-5deg); }
    @media (max-width: 991.98px) {
      .footer .astronaut {
        left: 0.26042vw;
        width: 24vw; } }
    .footer .astronaut img {
      display: block;
      width: 100%; }

@font-face {
  font-family: 'Brice';
  src: url("../../fonts/brice/Brice-BlackCondensed.ttf") format("opentype");
  font-weight: 900;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Brice';
  src: url("../../fonts/brice/Brice-BoldCondensed.ttf") format("opentype");
  font-weight: 700;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url("../../fonts/cabinetgrotesk/CabinetGrotesk-Black.otf") format("opentype");
  font-weight: 900;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url("../../fonts/cabinetgrotesk/CabinetGrotesk-Bold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url("../../fonts/cabinetgrotesk/CabinetGrotesk-Extrabold.otf") format("opentype");
  font-weight: 800;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url("../../fonts/cabinetgrotesk/CabinetGrotesk-Extralight.otf") format("opentype");
  font-weight: 200;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url("../../fonts/cabinetgrotesk/CabinetGrotesk-Light.otf") format("opentype");
  font-weight: 300;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url("../../fonts/cabinetgrotesk/CabinetGrotesk-Medium.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url("../../fonts/cabinetgrotesk/CabinetGrotesk-Regular.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url("../../fonts/cabinetgrotesk/CabinetGrotesk-Thin.otf") format("opentype");
  font-weight: 100;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Cera Pro';
  src: url("../../fonts/cerapro/CeraPro-Black.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Cera Pro';
  src: url("../../fonts/cerapro/CeraPro-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Cera Pro';
  src: url("../../fonts/cerapro/CeraPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Cera Pro';
  src: url("../../fonts/cerapro/CeraPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal; }

@font-face {
  font-family: 'Cera Pro';
  src: url("../../fonts/cerapro/CeraPro-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal; }
