.karl-marx {
	@extend .section;
	@include section-margin(top);
	min-height: 100vh;
	overflow: hidden;
	.section__background {
		background-color: $cream;

	}
}
.karl-marx__grid {
	z-index: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	@include media-breakpoint-down(md) {
		flex-direction: column;
	}
}
.karl-marx__title {
	@include make-section-title(100px);
	// width: 66%; // 4% margin
	width: get-abstract-size(1060px, 1728px);

	div {
		line-height: inherit;
	}
	
	& > div {
		overflow: hidden;
	}

	@include media-breakpoint-down(md) {
		width: 100%;
	}
}
.karl-marx__wrapper {
	position: relative;
	width: get-abstract-size(678px, 1728px);
	right: -5.6%;
	@include media-breakpoint-down(md) {
		right: 0;
		width: 100%;
		margin-top: 40px;
		padding: 0 6%;
	}
}
.karl-marx__image {
	@include mask-image(2);
	width: get-abstract-size(702px, 678px);
	max-width: get-abstract-size(702px, 678px);
	transform: rotate(14deg);
    position: relative;
	right: -14.8%;
    margin: 7% 0;
	display: block;

	@include media-breakpoint-down(md) {
		width: 100%;
		max-width: 480px;
		right: 0;
		margin-left: auto;
		margin-right: auto;
	}
}