@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

@function get-vw($px-vw, $base-vw: 1920px){
    @return #{strip-unit($px-vw) * 100 / strip-unit($base-vw)}vw;
}

@function get-vh($px-vh, $base-vw: 1080px){
  @return #{strip-unit($px-vh) * 100 / strip-unit($base-vw)}vh;
}

@function get-abstract-size($width, $containerWidth: 1920) {
    @return strip-unit($width) / (strip-unit($containerWidth) / 100) * 1%;
}

@function get-abstract-width($width, $containerWidth: 1920) {
    @return get-abstract-size($width, $containerWidth);
}

@function em($pixels, $context: $browser-context) {
    @return #{strip-unit($pixels)/strip-unit($context)}em;
}

// Math
$pi: 3.14159265359;
$_precision: 10;

@function pow($base, $exp) {
  $value: $base;
  @if $exp > 1 {
    @for $i from 2 through $exp {
      $value: $value * $base;
    }
  }
  @if $exp < 1{
    @for $i from 0 through -$exp {
      $value: $value / $base;
    }
  }
  @return $value;
}

@function fact($num) {
  $fact: 1;
  @if $num > 0{
    @for $i from 1 through $num {
      $fact: $fact * $i;
    }
  }
  @return $fact;
}

@function _to_unitless_rad($angle) {
  @if unit($angle) == "deg" {
    $angle: $angle / 180deg * $pi;
  }
  @if unit($angle) == "rad" {
    $angle: $angle / 1rad;
  }
  @return $angle;
}

@function sin($angle){
  $a: _to_unitless_rad($angle);
  $sin: $a;
  @for $n from 1 through $_precision {
    $sin: $sin + (pow(-1, $n) / fact(2 * $n + 1) ) * pow($a, (2 * $n + 1));
  }
  @return $sin;
}

@function cos($angle){
  $a: _to_unitless_rad($angle);
  $cos: 1;
  @for $n from 1 through $_precision {
    $cos: $cos + ( pow(-1,$n) / fact(2*$n) ) * pow($a,2*$n);
  }
  @return $cos;
}

@function tan($angle){
    @return sin($angle) / cos($angle);
}

@function sqrt($r) {
    $x0: 1;
    $x1: $x0;
   
    @for $i from 1 through 10 {
      $x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0);
      $x0: $x1;
    }
   
    @return $x1;
}


// mask ellipse

$mask-ellipse-1: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384.42 417.59'%3E%3Cellipse transform='rotate(-29.378 192.22 208.792)' cx='192.21' cy='208.79' rx='184' ry='216'/%3E%3C/svg%3E");
$mask-ellipse-2: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 324.06 371.5'%3E%3Cellipse transform='rotate(-15.656 162.015 185.724)' cx='162.03' cy='185.75' rx='159.8' ry='187.59'/%3E%3C/svg%3E");
$mask-ellipse-3: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 466.11 520.67'%3E%3Cpath d='M441.48 171.62c57.52 135.13 10.83 284.4-104.28 333.4S82.14 484.2 24.62 349.06 13.79 64.66 128.9 15.66s255.06 20.82 312.58 155.96z'/%3E%3C/svg%3E");
$mask-ellipse-4: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 357.68 392.15''%3E%3Cellipse transform='rotate(-62.6 178.85 196.083)' cx='178.84' cy='196.08' rx='201.98' ry='172.06'/%3E%3C/svg%3E");

@function get-mask-ellipse($a, $w, $h, $rx: false, $ry: false) {

    // @debug 'cos 0', cos(0deg);
    // @debug 'cos 90', cos(90deg);
    // @debug 'sin 0', sin(0deg);
    // @debug 'sin 90', sin(90deg);
    
    $mask: none;

    $w-half: $w / 2;
    $h-half: $h / 2;
    
    $r: $w-half * $h-half / sqrt( pow($w-half * cos($a), 2) + pow($h-half * sin($a), 2));

    @if $rx != false {
        $r: $rx / 2 * $h-half / sqrt( pow($rx / 2 * cos($a), 2) + pow($h-half * sin($a), 2));
    }
    $scale: ($r / $h-half) - abs(sin($a)) / 100;
    $translate: $h-half * (1 - $scale);
    
    // @debug 'R:',$r;
    // @debug 'W, H:',$w-half, $h-half;
    // @debug 'scale:', $scale;
    // @debug 'translate', $translate;

    @if $rx != false and $ry != false {
        $rx: $rx / 2;
        $ry: $ry / 2;

        $mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 #{$w} #{$h}'%3E%3Cellipse transform='rotate(#{strip-unit($a)} #{$w-half} #{$h-half})' cx='#{$w-half}' cy='#{$h-half}' rx='#{$rx}' ry='#{$ry}'/%3E%3C/svg%3E");
    }
    @else if $rx != false {
        $rx: $rx / 2;
        $ry: $h-half;

        $mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 #{$w} #{$h}'%3E%3Cellipse transform='rotate(#{strip-unit($a)} #{$w-half} #{$h-half})' cx='#{$w / 2}' cy='#{$h / 2}' rx='#{$rx}' ry='#{$ry}'/%3E%3C/svg%3E");
    } 
    @else {
        $rx: $w-half * $scale; //($w-half / $h-half); // scale for a more ellipse look;
        $ry: $h-half;
        // @debug $scale;
        // $scale: $scale + 0.01;
        $mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 #{$w} #{$h}'%3E%3Cellipse transform='rotate(#{strip-unit($a)} #{$w-half} #{$h-half}) scale(#{$scale}) translate(#{$translate} #{$translate})' cx='#{$w-half}' cy='#{$h-half}' rx='#{$rx}' ry='#{$ry}'/%3E%3C/svg%3E");
    }

    @return $mask;
}