.uncle-sam {
	color: $white;
	@include media-breakpoint-down(sm) {
		font-size: 16px;
	}

	&__inner {
		padding: 60px 5% 0;
		background-color: #3C3B6E;
		//background-image: url(../img/uncle-sam/star.svg);
		//background-position: -50%;
		position: relative;

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 183px;
			position: absolute;
			bottom: 0;
			left: 0;
			background: repeating-linear-gradient(180deg, #B22234 0%, #B22234 14.28%, $white 14.29%, $white 28.56%);
			z-index: 1;

			@include media-breakpoint-up(lg) {
				height: 252px;
			}
		}

		@include media-breakpoint-up(lg) {
			padding: 32px 0 0 5%;
			max-width: calc(100% - #{get-abstract-width(100px)});
		}
	}

	&__row {

		@include media-breakpoint-up(lg) {
			display: flex;
			position: relative;
		}
	}

	.futurism-title {
		position: relative;
		z-index: 1;

		@include media-breakpoint-up(lg) {
			width: 66%;
			padding-top: 88px;
			position: absolute;
			top: 0;
			left: 0;
		}

		span {
			color: $white;

			@include media-breakpoint-up(lg) {
				display: block;
			}
		}
	}

	&-col {
		position: relative;

		&__portrait {
			text-align: center;
			margin-top: -60px;

			@include media-breakpoint-up(lg) {
				margin: 0 0 0 auto;
				width: 45%;
			}

			img {
				max-width: 232px;

				@include media-breakpoint-up(lg) {
					max-width: initial;
				}
			}
		}

		&__inner {
			position: relative;
			z-index: 1;
			padding: 30px;
			background-color: $cream;
			color: $primary-dark;
			margin-top: -60px;
			bottom: -5px;

			@include media-breakpoint-up(lg) {
				padding: 80px 0 100px get-vw(80px);
				margin-top: -163px;
				margin-left: auto;
				width: 45%;
			}
		}
	}

	.uncle-sam__stars {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: calc(100% - 158px);
		background-image: url(../img/uncle-sam/stars-bg.png);
		background-size: cover;

		@include media-breakpoint-up(lg) {
			height: calc(100% - 252px);
			width: 55%;
		}
	}
}