// gigantomania section

@function gigantomania-poster-mask($width, $height) {
    $svg-width: 100 * $width / $height;
    $svg-height: 100 * $height / $width;
    $svg-rx: 20 * $width / $height;
    @return url("data:image/svg+xml,%3Csvg viewBox='0 0 #{ $svg-width } #{ $svg-height }' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg' %3E%3Crect width='#{$svg-width}' height='#{ $svg-height }' rx='#{$svg-rx}'/%3E%3C/svg%3E");
}

.gigantomania-section {
    $drop-shadow: drop-shadow(19px 21px 90px rgba(0, 0, 0, 0.19));
    $shadow: 19px 21px 90px rgba(0, 0, 0, 0.19);
    $containerWidth: 1720px;

    @extend .section;
    color: $cream;

    @include media-breakpoint-down(md) {
        margin-bottom: get-abstract-size( 30px, 375px);
    }

    %gigantomania-poster-mask {
        // mask: gigantomania-poster-mask(663, 848);
        mask: gigantomania-poster-mask(553, 667);
        mask-repeat: no-repeat;
        mask-size: 100% 100%;
    }

    .section {
        &__background {
            background-color: $primary-dark;
            
            @include media-breakpoint-up(lg) {
                border-radius: 50%/15%;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }

        &__title {
            $title-indent: 200px;
            $title-width: 1130px - $title-indent;

            @include make-section-title(140px);
            padding-left: get-abstract-size( 60px, 375px);

            @include media-breakpoint-up(lg) {
                padding-left: 0;
                width: get-abstract-size($title-width, $containerWidth);
                margin-left: get-abstract-size(586px + $title-indent, $containerWidth);
            }

            span {
                color: $retro-red;
            }

            & > div {
                &:first-child {
                    margin-left: get-abstract-size( -60px, 375px);
                    @include media-breakpoint-up(lg) {
                        margin-left: get-abstract-size( -200px, 1130px - $title-indent);
                    }
                }
            }
        }

        &__subtitle {
            @include make-section-title(80px);
            margin-bottom: em(60, 80);

            @include media-breakpoint-down(xs) {
                max-width: 90%;
                min-width: 280px;
            }

            span {
                color: $retro-red;
            }
        }

        &__content-item {
            &:not(:last-child) {
                margin-bottom: 200px;
                @include media-breakpoint-up(lg) {
                    margin-bottom: get-abstract-size(410px, $containerWidth);
                }
            }

            @include media-breakpoint-up(lg) {
                margin-left: get-abstract-size(586px, $containerWidth);
                padding-left: get-abstract-size( 200px, $containerWidth);
                padding-right: get-abstract-size( 180px, $containerWidth);
                width: get-abstract-size(1130px, $containerWidth);
            }
        }
    }

    .zeppelin {
        position: absolute;

        &__img {
            display: block;
            width: 100%;
        }

        &--small {
            top: -12px;
            width: #{'min( #{get-abstract-size(82px, 375px)}, 150px)'};
            right: 15px;

            @include media-breakpoint-up(lg) {
                width: get-abstract-size(262px);
                top: get-abstract-size(-80px);
                right: 5%;
            }
        }

        &--big {
            opacity: 0.22;

            top: get-abstract-size(400px);
            // width: get-abstract-size(320px, 375px);
            width: #{'min( #{get-abstract-size(320px, 375px)}, 500px)'};
            right: get-abstract-size(-35px, 375px);

            @include media-breakpoint-up(lg) {
                top: get-abstract-size(380px);
                width: get-abstract-size(1000px);
                right: get-abstract-size(-354px);
            }
            
            @include media-breakpoint-down(xs) {
                top: 32%;
            }
        }
    }

    .poster {
        $poster-holder-width: 660px;
        $poster-holder-height: 1980px;

        &__holder {
            @include media-breakpoint-down(md) {
                display: flex;
                flex-direction: row-reverse;
                margin-top: get-abstract-size(60px, 375px);
                width: 100%;
                align-items: flex-start;
            }
            @include media-breakpoint-up(lg) {
                left: 0;
                top: 0;
                bottom: 0;
                width: get-abstract-size($poster-holder-width); //1720px
                position: absolute;
            }
        }
        
        &__mask {
            overflow: hidden;
            background-color: $cream;
            @extend %gigantomania-poster-mask;
        }
        
        & {
            // filter: $drop-shadow;
            box-shadow: $shadow;
            border-radius: 20%;
            backface-visibility: hidden;

            @include media-breakpoint-up(lg) {
                position: absolute;
            }
        }

        &--1 {
            @include media-breakpoint-down(md) {
                display: none;
            }
            @include media-breakpoint-up(lg) {
                transform: rotate(28.47deg);
                left: -9%;
                top: get-abstract-size(180px);
                z-index: 4;
                width: 100%;
            }
        }
        &--2 {
            width: get-abstract-size(145px, 375px);
            z-index: 2;

            @include media-breakpoint-down(md) {
                margin-right: get-abstract-size(38px, 375px);
            }
            
            @include media-breakpoint-up(lg) {
                transform: rotate(-1.21deg);
                width: get-abstract-size(564px, $poster-holder-width);
                z-index: 3;
                top: get-abstract-size(846px, $poster-holder-height);
                left: get-abstract-size(-316px, $poster-holder-width);
            }
        }
        &--3 {
            width: get-abstract-size(120px, 375px);
            transform: rotate(29.251deg);
            z-index: 1;

            @include media-breakpoint-down(md) {
                margin-top: get-abstract-size(62px, 375px);
            }

            @include media-breakpoint-up(lg) {
                left: get-abstract-size(100px, $poster-holder-width);
                top: get-abstract-size(1310px, $poster-holder-height);
                width: get-abstract-size(387px, $poster-holder-width);
                transform: rotate(29.47deg);
                z-index: 2;
            }
        }
        &--4 {
            width: get-abstract-size(100px, 375px);
            transform: rotate(-6.45deg);
            @include media-breakpoint-down(md) {
                margin-left: get-abstract-size(-12px, 375px);
                margin-top: get-abstract-size(95px, 375px);
            }

            @include media-breakpoint-up(lg) {
                width: get-abstract-size(300px, $poster-holder-width);
                transform: rotate(-6.93deg);
                left: get-abstract-size(470px, $poster-holder-width);
                top: get-abstract-size(1450px, $poster-holder-height);
                z-index: 1;
            }
        }

        &__img {
            // width: 100%;
            display: block;
            width: 100% - (2 * get-abstract-size(20px, $poster-holder-width));
            margin: get-abstract-size(20px, $poster-holder-width);
            // @extend %gigantomania-poster-mask;
            mask: inherit;
            aspect-ratio: var(--aspect-ratio);
        }
    }

    .planet {
        width: #{'min( #{get-abstract-size(290px, 375px)}, 576px)'};
        // width: get-abstract-size(290px, 375px);
        left: get-abstract-size(-88px, 375px);
        position: absolute;
        bottom: 0;
        will-change: transform;
        backface-visibility: hidden;
        
        // filter: $drop-shadow;
        
        @include media-breakpoint-down(md) {
            margin-bottom: get-abstract-size( -30px, 375px);
        }

        @include media-breakpoint-up(lg) {
            width: get-abstract-size(1045px);
            left: get-abstract-size(-340px);
        }

        &__img {
            width: 100%;
            display: block;
            aspect-ratio: 1;
            box-shadow: $shadow;
            background-color: rgba(0, 0, 0, 0.19);
            border-radius: 50%;
        }
    }
}
