.big-world {
  @include make-section(top);
  // @include make-typography();
  text-align: center;
  @include media-breakpoint-down(sm) {
    // padding-top: 160px;
    margin-top: 50px;
  }
  &__container {
    max-width: 68%;
    margin: 0 auto;
    @include media-breakpoint-down(sm) {
      max-width: 90%;
    }
  }
  &__title {
    @include make-section-title();
    margin-bottom: 0.6em;
    padding: 0 9%;
    
    @include media-breakpoint-up(sm) {
      padding: 0 15%;
    }
    
    @include media-breakpoint-up(lg) {
      padding: 0;
      b {
        display: block;
      }
    }
  }
  &__image {
    margin-top: 120px;
    position: relative;
    @include media-breakpoint-down(sm) {
      max-width: 470px;
      margin-left: auto;
      margin-right: auto;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba($cream, 0) 60.02%, $cream 98.01%);
    }
  }
  &__decor {
    position: absolute;
    border-radius: 10%;
    overflow: hidden;
    &::before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
    &--left-top {
      top: 13%;
      left: 5%;
      transform: rotate(27deg);
      width: 10%;
      @include media-breakpoint-down(sm) {
        top: 0;
        width: 25%;
        left: -10%;
      }
    }
    &--left-bottom {
      bottom: 30%;
      left: -2%;
      transform: rotate(-33deg);
      width: 15%;
      @include media-breakpoint-down(sm) {
        width: 20%;
        bottom: 25%;
        left: -5%;
      }
    }
    &--right-top {
      top: 13%;
      right: 5%;
      width: 10%;
      transform: rotate(-40deg);
      @include media-breakpoint-down(sm) {
        top: 0;
        width: 25%;
        right: -10%;
      }
    }
    &--right-center {
      width: 15%;
      top: 45%;
      right: -2%;
      transform: rotate(44deg);
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    &--right-bottom {
      width: 11%;
      bottom: 10%;
      right: 5%;
      transform: rotate(12deg);
      @include media-breakpoint-down(sm) {
        width: 20%;
        bottom: 25%;
        right: -5%;
      }
    }
  }

  p:not(:last-of-type) {
    margin-bottom: em(52, 32);
  }
}