// Hammer and Sickle section
.hammer-and-sickle-section {
    @extend .section;
    
    @include media-breakpoint-down(md) {
        padding-top: 100px;
    }
    @include media-breakpoint-down(xs) {
        padding-top: 20px;
    }

    .section {
        &__background {
            background-color: $cream;
            opacity: 0;
            transition:  opacity 0.25s;
            background-image: url('../img/hammer-and-sickle/bg-hammer-and-sickle.png');
            background-size: 33%;
            $bottom: 200px;
            // clip-path: inset(0 0 #{$bottom} 0);
            mask-image: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) calc(100% - #{$bottom * 1.1}),rgba(255,255,255,0) calc(100% - #{$bottom}));
            mask-size: 100% 100%;
            
            @include media-breakpoint-up(md) {
                opacity: 1;
            }
            @include media-breakpoint-up(xl) {
                $bottom: 480px;
                mask-image: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) calc(100% - #{$bottom * 1.1}),rgba(255,255,255,0) calc(100% - #{$bottom}));
            }
        }

        &__title {
            text-align: center;

            @include media-breakpoint-up(lg) {
                margin-left: auto;
                margin-right: auto;
                width: get-abstract-width(1120px, 1720px);
            }

            span {
                color: $retro-red;
            }
        }

        &__foreground {
            @include media-breakpoint-down(md) {
                padding-top: 300px;
                // padding-top: 42%;//get-abstract-size(300px, 375px);
            }
            @include media-breakpoint-down(xs) {
            // @include media-breakpoint-down(sm) {
                padding-top: get-abstract-size(300px, 375px);
            }
        }
    }

    p {
        @include media-breakpoint-up(lg) {
            margin-left: auto;
            margin-right: auto;
            width: get-abstract-width(840px, 1720px);
        }
    }

    &__img {
        $col-aside: 120px;
        $col-aside-lg: 500px;
        display: flex;

        img {
            display: block;
            aspect-ratio: var(--aspect-ratio);
            backface-visibility: hidden;
        }

        &--center {
            margin: get-abstract-size(60px, 375) auto 0;
            width: get-abstract-size(250, 375);

            @include media-breakpoint-down(md) {
                max-width: 420px;
            }

            @include media-breakpoint-up(lg) {
                margin-top: get-abstract-size(100px);
                width: get-abstract-size(626px);
            }
        }

        &--left,
        &--right {
            
            // @include media-breakpoint-down(md) {
            //     max-width: 420px;
            // }
            
            width: get-abstract-size($col-aside, 375);
            
            display: flex;
            flex-direction: column;

            @include media-breakpoint-down(md) {
                margin-top: get-abstract-size(30px, 375);
            }
            
            @include media-breakpoint-up(lg) {
                width: get-abstract-size($col-aside-lg);
                position: absolute;
                top: 0;
                padding: inherit;
                bottom: 0;
            }

            img {
                mask-size: 100% 100%;
            }
        }

        &--left {
            $width: $col-aside-lg;

            align-items: flex-start;

            @include media-breakpoint-up(lg) {
                left: 0;
            }

            img {
                // mask: get-mask-ellipse(-29.38deg, 384.42, 417.59, 368);
                // mask: get-mask-ellipse(-29.378deg, 384.42, 417.59, 368, 432);
                // mask: $mask-ellipse-1;

                &:nth-child(1) {
                    width: get-abstract-size(383px, $width);
                    mask-image: get-mask-ellipse(-29.38deg, 384.42, 417.59);
                    // mask: $mask-ellipse-1;
                    margin-left: get-abstract-size(-135px, $width);
                    margin-bottom: get-abstract-size(80px, $width);

                    @include media-breakpoint-down(md) {
                        position: absolute;
                        top: 0;
                        left: 0;
                        
                        width: 180px;
                        margin-top: 80px;
                        margin-left: -72px;
                    }

                    @include media-breakpoint-down(xs) {
                        max-width: 280px;
                        margin-top: get-abstract-size(80px, 375px);
                        width: get-abstract-size(180px, 375px);
                        margin-left: get-abstract-size(-72px, 375px);
                    }
                }

                @include media-breakpoint-up(lg) {
                    max-height: 21.8%;
                }

                &:nth-child(2) {
                    width: get-abstract-size(330px, $width);
                    mask-image: get-mask-ellipse(-16deg, 330, 373);
                    // mask-image: $mask-ellipse-2;
                    margin-bottom: get-abstract-size(90px, $width);
                    margin-left: get-abstract-size(52px, $width);

                    @include media-breakpoint-up(lg) {
                        max-height: 20.1%;
                    }
                }
                
                &:nth-child(3) {
                    margin-left: 10px;
                    width: get-abstract-size(467px, $width);
                    mask-image: get-mask-ellipse(-23.06deg, 467, 522);
                    // mask-image: $mask-ellipse-3;
                    margin-bottom: get-abstract-size(70px, $width);
                    margin-left: get-abstract-size(-110px, $width);

                    @include media-breakpoint-up(lg) {
                        max-height: 27.2%;
                    }
                }
                
                &:nth-child(4) {
                    width: get-abstract-size(360px, $width);
                    mask-image: get-mask-ellipse(27.4deg, 360, 393);
                    // mask-image: $mask-ellipse-4;
                    margin-left: get-abstract-size(146px, $width);

                    @include media-breakpoint-up(lg) {
                        max-height: 20.5%;
                    }
                }
            }
        }

        &--right {

            $width: $col-aside-lg;

            align-items: flex-end;

            @include media-breakpoint-up(lg) {
                right: 0;
            }

            img {
                
                &:nth-child(1) {
                    width: get-abstract-size(383px, $width);
                    mask-image: get-mask-ellipse(29.38deg, 384.42, 417.59);
                    margin-right: get-abstract-size(-135px, $width);
                    margin-bottom: get-abstract-size(90px, $width);

                    @include media-breakpoint-down(md) {
                        position: absolute;
                        top: 0;
                        right: 0;

                        margin-right: -50px;
                        width: 185px;
                    }
                    
                    @include media-breakpoint-down(xs) {
                        max-width: 280px;
                        margin-right: get-abstract-size(-50px, 375px);
                        width: get-abstract-size(185px, 375px);
                    }

                    @include media-breakpoint-up(lg) {
                        max-height: 21.7%;
                    }
                }

                &:nth-child(2) {
                    width: get-abstract-size(330px, $width);
                    mask-image: get-mask-ellipse(16deg, 330, 373);
                    margin-bottom: get-abstract-size(142px, $width);
                    margin-left: get-abstract-size(52px, $width);

                    @include media-breakpoint-up(lg) {
                        max-height: 17.6%;
                    }
                }
                
                &:nth-child(3) {
                    margin-left: 10px;
                    width: get-abstract-size(467px, $width);
                    mask-image: get-mask-ellipse(23.06deg, 467, 522);
                    margin-bottom: get-abstract-size(98px, $width);
                    margin-right: get-abstract-size(-110px, $width);

                    @include media-breakpoint-up(lg) {
                        max-height: 24.3%;
                    }
                }
                
                &:nth-child(4) {
                    width: get-abstract-size(360px, $width);
                    mask-image: get-mask-ellipse(-27.4deg, 360, 393);
                    margin-left: get-abstract-size(146px, $width);

                    @include media-breakpoint-up(lg) {
                        max-height: 20.4%;
                    }
                }
            }
        }
    }
}
