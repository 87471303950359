.end-great-future-section + .footer {
    $angle: 12.51deg;
    $margin-top-angle: 50% * (sin($angle) / sin(90deg)) * 1.1;

    @include make-section(top, 240px);
    @include media-breakpoint-up(xl) {
        padding-top: calc(160px + #{$margin-top-angle});
    }
}

.footer {
    $background: $primary-dark;

    background-color: $background;
    color: $cream;
    @include make-section(top);
    overflow: hidden;

    img {
        aspect-ratio: var(--aspect-ratio);
        object-fit: contain;
    }

    &__top {
        .footer__title {
            @include make-section-title(80px);
            margin-bottom: 0.5em;

            span {
                color: $retro-red;
            }
        }

        .row {
            @include make-row(40px);
        }

        .col {
            @include make-col-ready(40px);
            
            @include media-breakpoint-up(xl) {
                &:first-child {
                    @include make-col(4.9);
                }
                &:last-child {
                    @include make-col(7.1);
                }
            }
            // max-width: get-abstract-size(694px, 1720px);
        }

        p {
            margin-bottom: 1em;
        }

        .lead {
            @include make-section-title(32px, 20px);

            &:not(:first-child) {
                margin-top: em(60px, 32px);
            }
        }
    }

    &__bottom {
        position: relative;
        z-index: 1;

        .logo-bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -2;
            background-image: url(../img/footer/keyhole.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }

        .light {
            position: absolute;
            z-index: -3;
            // width: get-vw(873px);
            // height: get-vw(873px);
            width: get-vw(1080px * 2);
            height: get-vw(1080px * 2);
            left: get-vw(-960px);
            top: get-vw(-870px);
            // background: $cream;
            // opacity: 0.64;
            // opacity: 0.44;
            border-radius: 50%;
            // filter: blur( get-vw(820px) );
            $scale: (50 / 70); // Pythagorean theorem ≈70.71

            // $cream: #FFFCF2;
            // $background
            // @for $i from 100*10 through 0*10 {
            //     $mix: mix($cream, $primary-dark, $i/10);
            //     @if $mix == #41403f {
            //         @debug $i/10, $mix;
            //     }
            // }
            // $background: transparent;
            $opacity: 0.6; // 0.44
            background: radial-gradient(ellipse at center, 
                mix($cream, $background, 13.9) 0% * $scale,
                mix($cream, $background, 11.8) 16% * $scale,
                mix($cream, $background, 2.75) 61% * $scale,
                mix($cream, $background, 0) 100% * $scale,
                rgba(mix($cream, $background, 0), 0) 100%
            );
        }

        &::before {
        }

        .footer__title {
            @include make-section-title(140px, 56px);
            color: $cream;

            text-align: center;
            margin-bottom: get-abstract-size(40px, 1720px);

            span {
                color: $retro-paper;
            }
        }

        p {
            margin-left: auto;
            margin-right: auto;
            max-width: get-abstract-size(1134px, 1720px);;
            text-align: center;
        }

        .designed-by {
            text-align: center;
            color: $retro-paper;
            margin-top: get-abstract-size(130px, 1720px);

            .logo {
                height: 1.2em;
                width: 1.2em;
            }
        }
    }

    .copy {
        font-size: 16px;
        padding: 16px 0;
        border-top: 1px solid rgba($cream, 0.4);
        color: $cream;

        @include media-breakpoint-down(md) {
            display: none;
        }

        .logo {
            width: 1em;
            height: 1em;
        }
    }

    .logo {
        display: inline-block;

        // path {
        //     fill: currentColor;
        // }
    }

    $odd: 
        208,
        108,
        46,
        12,
        0,
        12,
        46,
        108,
        208
    ;

    $even: 
        150,
        74,
        26,
        4,
        4,
        26,
        74,
        150
    ;

    @mixin get-social-item-padding($items: 1) {
        $start: 0;
        $is-even: $items % 2 == 0;
        $arr: $odd;
        @if $is-even {
            $arr : $even;
        }
        $start: (floor(length($arr) / 2)) - floor($items / 2);
        
        @for $i from $start + 1 through $start + $items {
            // @debug $i, $start + 1 == $i , nth($arr, $i);
            $margin: get-abstract-size(nth($arr, $i) * 1px);
            @if $start + 1 == $i {
                margin-top: $margin;
                // margin-top: nth($arr, $i) * 1px;
                margin-bottom: $margin * -1;
            }
            @else {
                &:nth-child(#{$i - $start}) {
                    margin-top: $margin;
                    margin-bottom: $margin * -1;
                }
            }
        }
    }

    .social {
        $moon-diameter: 1336px;

        // Law of sines
        // a = b * (sinα / sinβ)
        // $width: 640px;
        // $width: 472px;
        // $width: get-abstract-size($width, 1720);
        // $margin-top-angle: $width / 2 * (sin($angle) / sin(90deg));

        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        
        padding-bottom: get-abstract-size(52px, 1720);

        &__holder {
            position: relative;
            margin-top: get-abstract-size(40px, 1720);
            margin-bottom: get-abstract-size(240px, 1720);
            @include media-breakpoint-down(md) {
                margin-top: get-abstract-size(60px, 375);
                margin-bottom: 0;
                // padding-bottom: get-abstract-size(180px, 375);
                // padding-bottom: get-vw(180px, 372);
                padding-bottom: get-abstract-size(290px, 1720);
            }
            
            @include media-breakpoint-down(xs) {
                padding-bottom: get-abstract-size(150px, 375);
            }

            .moon {
                position: absolute;
                z-index: -1;
                // bottom: 0;
                // top: 100%;
                top: #{'max(70px, #{get-vw(52px * 2)})'};
                
                width: get-vw(1336px);
                // max-width: 1336px;
                // 
                // width: get-abstract-size(1336, 1720);
                left: 50%;
                transform: translateX(-50%);
                // left: 0;
                // right: 0;

                @include media-breakpoint-down(md) {   
                    width: 100%;
                }
                @include media-breakpoint-down(xs) {
                    width: 150%;
                }

                img {
                    width: 100%;
                    display: block;
                }
            }
        }


        &__item {
            // transform: rotate(90deg);

            // margin-top: 32px;
            // margin-bottom: 32px;
            text-align: center;
            padding: 0 get-abstract-size(34px, 1720);
            
        }

        // /* one item */
        // li:first-child:nth-last-child(1) {
        //     /* -or- li:only-child { */
        // }
            
        // /* two items */
        // li:first-child:nth-last-child(2),
        // li:first-child:nth-last-child(2) ~ li {}

        @for $i from 3 through 9  {
            li:first-child:nth-last-child(#{$i}),
            li:first-child:nth-last-child(#{$i}) ~ li {
                @include get-social-item-padding($i);
            }
        }

        &__link {
            position: relative;
            width: #{'max(40px, #{get-vw(52px)})'};
            height: #{'max(40px, #{get-vw(52px)})'};
            padding: get-abstract-size(14px, 52px);
            background-color: $retro-paper;
            display: block;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: $retro-red;
            text-decoration: none;
            font-size: #{'max(18px, #{get-vw(24px)})'};
            transition: opacity 0.25s;
            
            &:hover {
                opacity: 0.7;
            }
        }
    }

    .gallery {
        position: relative;
        z-index: 2;
        $border-width: 10px;

        margin-top: get-abstract-size(140px, 1720px);
        margin-bottom: get-abstract-size(140px, 1720px);
        
        @include media-breakpoint-down(sm) {
            margin-top: get-abstract-size(50px, 375px);
            margin-bottom: get-abstract-size(60px, 375px);
        }

        &__row {
            @include make-row(20px);

            @include media-breakpoint-down(sm) {
                margin-right: -4px;
                margin-left: -4px;
            }
        }

        &__col {
            @include make-col-ready(20px);

            @include media-breakpoint-down(sm) {
                padding-right: 4px;
                padding-left: 4px;
            }
            
            &:first-child {
                @include make-col(3.2);
            }
            &:last-child {
                @include make-col(12 - 3.2);
            }
            img {
                width: 100%;
                height: 100%;
                display: block;
            }
            
            &:first-child {
                .gallery__item {
                    // border-radius: 1000px 100px 60px;
                    border-radius: get-vw(1000px) get-vw(100px) get-vw(60px);
                    // aspect-ratio: (439 / 570);
                    
                    img {
                        display: block;
                        object-fit: cover;
                        border-radius: get-vw(1000px + (10 * $border-width)) get-vw(100px - $border-width) get-vw(60px  - $border-width);
                    }
                }
            }

            &:last-child {
                .gallery__item {
                    // border-radius: 100px 60px 200px;
                    border-radius: get-vw(100px) get-vw(60px) get-vw(200px);
                    // aspect-ratio: (1260 / 570);

                    img {
                        object-fit: cover;
                        
                        border-radius: get-vw(100px - $border-width) get-vw(60px - $border-width) get-vw(200px - $border-width);
                    }
                }
            }
        }

        &__item {
            height: get-vw(570px);
            width: 100%;
            // height: 270px;
            overflow: hidden;
            padding: #{'max(4px, #{get-vw($border-width)})'};

            background-color: $cream;
            // /* Shadow */
            // filter: drop-shadow(19px 21px 90px rgba(0, 0, 0, 0.19));
            box-shadow: 19px 21px 90px rgba(0, 0, 0, 0.19);
        }
    }

    .planet {
        position: absolute;
        z-index: -1;

        img {
            display: block;
            width: 100%;
            aspect-ratio: 1/1;
        }

        &--mars {
            right: get-vw(100px);
            bottom: get-vw(224px);
            width: get-vw(260px);

            @include media-breakpoint-down(sm) {
                width: get-vw(92px, 375);
                top: get-vw(130px, 375);
                right: get-vw(-46, 375);
            }
        }

        &--pluto {
            width: get-vw(64.22px);
            right: get-vw(45px);
            top: get-vw(396px);
            transform: rotate(-8.71deg);
            
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        &--pluto-grey {
            width: get-vw(136.73px);
            right: get-vw(-54px);
            top: get-vw(153px);
            transform: rotate(-72.8deg);

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        &--neptune {
            opacity: 0.6;
            display: block;
            top: get-vw(-82px);
            right: get-vw(450px);
            width: get-vw(92px);
            
            @include media-breakpoint-down(sm) {
                width: get-vw(23px, 375);
                top: get-vw(48px, 375);
                right: get-vw(16px, 375);
            }
        }
    }

    .astronaut {
        position: absolute;
        z-index: -1;
        width: get-vw(209.8px);
        height: get-vw(214.85px);
        left: get-vw(236px);
        bottom: get-vw(338px);
        transform: rotate(-5deg);

        @include media-breakpoint-down(md) {
            left: get-vw(5px);
            width: get-vw(90px, 375);
        }

        img {
            display: block;
            width: 100%;
        }
    }
}