html, body {
	height: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

body {
	//position: relative;
	//min-width: 300px;
	//display: flex;
	//height: auto;
	font-family: $ff-CabinetGrotesk;
	line-height: $lh;
	color: $primary-dark;
	background-color: $cream;

	@include make-typography($fs-md, $fs-xs, $breakpoint: lg);
}

// Preloader animation
.js #smooth-wrapper {
	animation: fadeIn 0.2s;
}

#smooth-content {
    backface-visibility: hidden;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}

img {
	max-width: 100%;
	height: auto
}

.wrapper {
	position: relative;
	//display: flex;
	//flex-direction: column;
	//width: 100%;
	//min-height: 100vh;
	overflow: hidden;
	//
	//.footer {
	//	margin-top: auto
	//}
}


// Usage
.container,
.container-fluid {
	//@include make-container();
	padding: 0 $container-padding;

	@include media-breakpoint-up(lg) {
		padding: 0 $container-padding-desktop;
	}
}

// Responsive containers that are 100% wide until a breakpoint
//@each $breakpoint, $container-max-width in $container-max-widths {
//	.container-#{$breakpoint} {
//		@extend .container-fluid;
//	}
//
//	@include media-breakpoint-up($breakpoint, $grid-breakpoints) {
//		%responsive-container-#{$breakpoint} {
//			max-width: $container-max-width;
//		}
//
//		// Extend each breakpoint which is smaller or equal to the current breakpoint
//		$extend-breakpoint: true;
//
//		@each $name, $width in $grid-breakpoints {
//			@if ($extend-breakpoint) {
//				.container#{breakpoint-infix($name, $grid-breakpoints)} {
//					@extend %responsive-container-#{$breakpoint};
//				}
//
//				// Once the current breakpoint is reached, stop extending
//				@if ($breakpoint == $name) {
//					$extend-breakpoint: false;
//				}
//			}
//		}
//	}
//}

// section default 
.section {
	@include make-section();
    // @include make-section-typography();

    &__title {
        @include make-section-title();
        margin-bottom: 0.5em;
    }

    &__title--sm {
        @include make-section-title(80px);
        margin-bottom: 0.5em;
    }

    &__background {
        @include make-section-background();
    }

    &__foreground {
        @include make-section-foreground();
    }

    &__sand {
        color: $primary-dark;

        .section__background {
            background-color: $sand;
        }
    }

    &__red {
        color: $white;

        .section__background {
            background-color: $retro-red;
        }
    }

    &__dark {
        color: $white;

        .section__background {
            background-color: $primary-dark;
        }
    }
}