.dark-light-sections {
  $container-width: 1920px;
  $container-padding-desktop-px: 96px; // 5%
  $image-width-px: 730px;
  $col-padding: get-abstract-size($container-padding-desktop-px, $container-width - $image-width-px - $container-padding-desktop-px);
  $light-col-padding-right: get-abstract-size(150px, $container-width - $image-width-px - $container-padding-desktop-px);

  display: grid;
  grid-template-columns: auto auto get-abstract-size($image-width-px, $container-width - $container-padding-desktop-px);//repeat(3, 1fr);
  grid-template-rows: auto auto 1fr 2fr auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  @include media-breakpoint-up(md) {
    grid-template-rows: repeat(3, auto);
  }

  @include media-breakpoint-up(lg) {
    padding-right: $container-padding-desktop;
  }

  .dark-section {
    &__background {
      position: relative;
      grid-area: 1 / 1 / 4 / 4;
      z-index: -1;

      background-color: $thunder;
      @include section-noise(0.6);
        margin-right: get-abstract-size(50px, 375);

      @include media-breakpoint-up(sm) {
        margin-right: get-abstract-size(150);
      }
      @include media-breakpoint-up(md) {
        grid-area: 1 / 1 / 3 / 4;
      }
    }
    
    // &__title div,
    // &__desc > div {
    //   width: fit-content;
    // }  

    &__title {
      @include make-section-title();
      margin-bottom: em(60px, 100px);
      color: rgba($white, 0.54);

      span {
        color: $cream;
        display: block;
        // width: fit-content;
      }

      grid-area: 1 / 1 / 2 / 4;
      padding-right: get-abstract-size(15 + 50, 375);
      padding-left: $container-padding;
      @include make-section-padding(top);

      @include media-breakpoint-up(sm) {
        padding-right: #{'calc(#{$container-padding} + #{get-abstract-size(150)})'};
      }

      @include media-breakpoint-up(md) {
        grid-area: 1 / 1 / 2 / 4;
        padding-right: get-abstract-size(150 + 250);
      }

      @include media-breakpoint-up(lg) {
        padding-left: $container-padding-desktop;
      }
    }
    &__desc{
      grid-area: 2 / 1 / 3 / 4;
      color: $white;
      padding-bottom: get-abstract-size(40px, 375);
      @include make-section-padding(bottom, 160px, md);
      @include make-section-padding(bottom, 160px, xl);
      padding-left: $container-padding;
      padding-right: get-abstract-size(15 + 50, 375);

      @include media-breakpoint-up(md) {
        grid-area: 2 / 1 / 3 / 3;
        padding-right: $container-padding;
      }
      @include media-breakpoint-up(lg) {
        padding-right: $col-padding;
        padding-left: $col-padding;
      }
    }
    &__image {
      grid-area: 3 / 1 / 5 / 4;
      position: relative;
      width: get-abstract-size(254px, 375px);
      max-width: 360px;
      margin-left: auto;

      @include media-breakpoint-up(md) {
        width: 100%;
        max-width: 100%;
        margin-bottom: 100px;
        grid-area: 2 / 3 / 4 / 4;
      }
      @include media-breakpoint-up(lg) {
        margin-bottom: 160px;
      }

      img {
        display: block;
        height: auto;
      } 
    }
  }
  
  .light-section {
    grid-area: 6 / 1 / 5 / 4;
    padding-left: $container-padding;
    padding-right: $container-padding;
    padding-top: get-abstract-size(40px, 375);
    @include make-section-padding(bottom, 160px, xs);
    @include make-section-padding(both, 160px, md);
    @include make-section-padding(both, 160px, xl);

    @include media-breakpoint-up(md) {
      grid-area: 3 / 1 / 4 / 3;
    }

    @include media-breakpoint-up(lg) {
      padding-left: $col-padding;
      padding-right: $col-padding;
    }

    @include media-breakpoint-up(xl) {
      padding-right: $light-col-padding-right;
    }

    &__title {
      @include make-section-title(80px);
      
      &:last-child {
        margin-bottom: 0;
      }
    }

    &__content {}
  }
}