.centre-of-the-earth {
	@extend .section;
	background-color: $dark-sand;
	
	@include media-breakpoint-down(sm) {
		padding-bottom: get-vw(60px, 375px);
	}
	
	&__inner {
		@include media-breakpoint-up(md) {
			max-width: 70%;
		}
		
		@include media-breakpoint-up(md) {
			max-width: get-abstract-size(1176px, 1728px);
		}
	}
	
	&__title > div, &__content > div {
		width: fit-content;
	}
	
	&__title {
		@extend .section__title;
		
		@include media-breakpoint-up(md) {
			margin-bottom: 60px;
		}
	}

	&__content {
		@mixin spacer {
			content: '';
			display: inline-block;
			width: get-vw(65px, 375px);
		}

		@include media-breakpoint-down(sm) {
			&:not([style])::after {
				@include spacer;
			}

			&[style] > div[style]:last-child::after {
				@include spacer;
			}
		}
	}
	
	&__image {
		position: absolute;
		bottom: get-vw(-160px, 375px);
		text-align: right;
		width: get-vw(206px + 12px, 375);
		height: get-vw(230px + 12px, 375);
		right: get-vw(-70px, 375);
		background-color: $cream;
		padding: get-vw(6px, 375);
		transform: rotate(32deg);

		mask-image: get-mask-ellipse(0deg, 636, 709);
		mask-repeat: no-repeat;
		mask-position: center;

		img {
			mask-image: inherit;
			mask-repeat: inherit;
			mask-position: inherit;

			width: 	100%;
			height: 100%;
			object-fit: cover;
		}

		@include media-breakpoint-up(sm) {
			bottom: get-vw(-110px, 375px);
			width: get-vw(636px + 40px);
			height: get-vw(710px + 12px);
			right: get-abstract-size(120px);
		}
		
		@include media-breakpoint-up(md) {
			right: get-abstract-size(-120px);
			padding: get-vw(20px);
			bottom: get-vw(-120px);
		}
	}
}