.tech-dev {
  @extend .section;
  @include section-mask();
  @include section-shadow-inset();
  @include media-breakpoint-down(sm) {
    padding-bottom: 200px;
  }
  position: relative;
  text-align: center;
  &__description {
    max-width: 1214px;
    margin: 60px auto 0;
  }
  &__title {
    @include make-section-title()
  }
  #motionPath {
    width: 100%;
  }
  .section {
    &__background {
      background-color: $sand;
      @include section-noise();
    }
  }
  .tech-dev-images {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    @include media-breakpoint-up(sm) {
      position: absolute;
      width: 100%;
      bottom: 0;
    }
    img {
      flex-shrink: 0;
      mix-blend-mode: darken;
      max-width: 20vw;
      @include media-breakpoint-down(sm) {
        position: absolute;
        max-width: 30vw;

        &:nth-child(1) {
          top: 20px;
          left: -50px;
        }

        &:nth-child(2) {
          bottom: 170px;
          left: -20px
        }

        &:nth-child(3) {
          bottom: 50px;
          left: 50%;
          transform: translateX(-50%);
        }

        &:nth-child(4) {
          bottom: 170px;
          right: -20px
        }

        &:nth-child(5) {
          top: 80px;
          right: -70px;
        }
      }
    }
  }
  &__scene {
    @include media-breakpoint-up(sm) {
      position: relative;
      &::after {
        content: '';
        display: block;
        padding-bottom: 10%;
      }
    }
  }
}