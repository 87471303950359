.futurism-article {
	@include section-mask(both);
	@include make-section-typography;
	padding: 278px 0 258px;
	background-color: $sand;
	position: relative;
	@include media-breakpoint-down(xl) {
		padding: 208px 0 208px;
	}
	@include media-breakpoint-down(sm) {
		padding: 200px 0 280px;
	}
	@media screen and (max-width: 360px) {
		padding: 180px 0 280px;
	}
}
.futurism-article__grid {
	padding: 0 7%;
	@include media-breakpoint-down(md) {
		padding: 0 12%;
	}
}
.futurism-article__title {
	z-index: 3;
	position: relative;
	text-align: center;
	font-size: 5.2vmax;
	&--second {
		margin-top: 100px;
		margin-bottom: 0;
	}
	@include media-breakpoint-down(sm) {
		&--second {
			margin-top: 50px;
		}
		font-size: 4.2vmax;
	}
}

.futurism-article .images-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		overflow: hidden;
		.item-image {
			position: absolute;
			@include mask-image(4, 0);
			&--top {
				top: -23%;
				width: 100%;
				max-width: 560px;
				&.item-image--left {
					@include mask-image(1, 0);
					left: -7%;
					transform: translate(-80%, -80%) rotate(-25deg);
				}
				&.item-image--right {
					right: -8%;
					top: -18%;
					transform: translate(80%, -80%) rotate(41deg);
					object-fit: cover;
					object-position: 50% -30%;
				}
				@media screen and (max-width: 1450px){
					max-width: 400px;
					&.item-image--left {
						top: -13%;
						left: -5%;
					}
					&.item-image--right {
						top: -10%;
						right: -5%;
						max-width: 400px;
					}
				}
				@include media-breakpoint-down(lg) {
					max-width: 350px;
					&.item-image--left {
						top: -10%;
						left: -10%;
					}
					&.item-image--right {
						right: -10%;
						max-width: 350px;
					}
				}
				@include media-breakpoint-down(sm) {
					max-width: 300px;
					&.item-image--left {
						left: -13%;
						top: -7%;
					}
					&.item-image--right {
						display: none;
					}
				}
				@include media-breakpoint-down(xs) {
					max-width: 250px;
					&.item-image--left {
						max-width: 225px;
						left: -18%;
						top: -2%;
					}
				}
			}

			&--center {
				top: 40%;
				width: 100%;
				max-width: 500px;
				@include mask-image(1, 0);
				&.item-image--left {
					left: -16%;
					transform: translate(-80%, 0) rotate(-60deg) scaleX(-1);
				}
				&.item-image--right {
					right: -14%;
					transform: translate(80%, 0) rotate(41deg);
				}
				@media screen and (max-width: 2000px){
					max-width: 400px;
					&.item-image--left {
						left: -14%;
					}
					&.item-image--right {
						right: -15%;
					}
				}
				@media screen and (max-width: 1450px){
					top: 45%;
					&.item-image--left {
						left: -22%;
					}
					&.item-image--right {
						right: -22%;
						top: 42%;
					}
				}
				@include media-breakpoint-down(lg) {
					max-width: 300px;
					&.item-image--left {
						left: -20%;
					}
					&.item-image--right {
						right: -20%;
					}
				}
				@media screen and (max-width: 900px){
					max-width: 350px;
					top: 40%;
					&.item-image--left {
						left: -28%;
					}
					&.item-image--right {
						right: -25%;
					}
				}
				@include media-breakpoint-down(sm) {
					top: auto;
					&.item-image--left {
						left: -30%;
						bottom: 8%;
						transform: translate(-80%, 0) rotate(-60deg) scaleX(-1);
						max-width: 350px;
					}
					&.item-image--right {
						top: 7%;
						right: -10%;
						transform: translate(80%, -80%) rotate(41deg);
						max-width: 175px;
					}
				}
				@include media-breakpoint-down(xs) {
					&.item-image--left {
						max-width: 250px;
						bottom: 5%;
						left: -30%;
					}
					&.item-image--right {
						max-width: 155px;
						top: 5%;
						right: -18%;
						transform: translate(80%, -80%) rotate(41deg);
					}
				}
			}

			&--bottom {
				bottom: -15%;
				width: 100%;
				max-width: 500px;
				@include mask-image(1, 0);
				&.item-image--left {
					left: -12%;
					transform: translate(-80%, 80%) rotate(-60deg);
				}
				&.item-image--right {
					right: -6%;
					transform: translate(80%, 80%) rotate(-60deg);
					bottom: -100px;
				}
				@media screen and (max-width: 1450px){
					max-width: 450px;
					bottom: -20%;
				}
				@include media-breakpoint-down(lg) {
					bottom: -30%;
				}
				@include media-breakpoint-down(md) {
					&.item-image--right {
						bottom: -20%;
					}
				}
				@include media-breakpoint-down(sm) {
					max-width: 350px;
					&.item-image--left {
						left: 22%;
						bottom: -10%;
						transform: translate(0, 95%) rotate(-60deg);
					}
					&.item-image--right {
						max-width: 250px;
						bottom: 15%;
						right: -20%;
						transform: translate(70%, 0) rotate(-45deg);
					}
				}
				@include media-breakpoint-down(xs) {
					&.item-image--left {
						left: 25%;
						bottom: -8%;
						max-width: 250px;
					}
					&.item-image--right {
						bottom: 12%;
						right: -34%;
						max-width: 220px;
					}
				}
				@media screen and (max-width: 400px) {
					&.item-image--left {
						left: 21%;
						bottom: -10%;
						max-width: 220px;
					}
				}
			}
		}
}
