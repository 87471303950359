
.flowers-section {
    
    @function getFlowerWidth($width, $containerWidth: 1920) {
        @return strip-unit($width) / (strip-unit($containerWidth) / 100) * 1%;
    }

    @extend .section;
    @include section-margin(top);
    min-height: 100vh;
    
    .section {
        &__background {
            @include section-mask(top);
            background-color: $cream;
        }
    }

    .container {
        position: relative;
    }

    &__title {
        $line-height: 1.1;

        @extend .section__title;

        line-height:  $line-height;
        margin-bottom: em(20, 42);
        
        &::before {
            display: block;
            width: 4.32em;
            margin-top: $line-height * 1em;
            height: (2em *  $line-height);
            float: left;
            shape-outside: polygon(0% 34%, 33% 34%, 33% 68%, 100% 68%, 100% 100%, 0% 100%);
            @include media-breakpoint-up(md) {
                content: '';
            }
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: em(60, 100);
        }

        span {
            color: $retro-red;
        }
        &--desktop {
            display: none;
            @include media-breakpoint-up(md) {
                display: block;
            }
        }
        &--mobile {
            max-width: 500px;
            display: block;
            // text-shadow: 0 0 15px $cream, 0 0 30px $cream;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }

    &__content {
        @include media-breakpoint-down(sm) {
            &::before {
                float: right;
                content: '';
                height: 3em;
                width: getFlowerWidth(120px, 375px); //, $containerWidth
            }
        }

        @include media-breakpoint-up(md) {
            width: 56%;
            margin-left: 26%;
        }
        @include media-breakpoint-up(lg) {
            width: 56%;
            margin-left: 38%;
        }

    }
    
    p {
        &:not(:last-child) {
            margin-bottom: 1em;
        }
    }

    .flower {
        $containerWidth: 1920 - (1920 / 10);

        &__img {
            filter: grayscale(100%);
            aspect-ratio: var(--aspect-ratio);

            &-1 {
                width: getFlowerWidth(146px, 375px); //, $containerWidth
                margin-left: getFlowerWidth(20px, 375px);
                max-width: 250px;
                
                @include media-breakpoint-up(md) {
                    width: getFlowerWidth(421px); //, $containerWidth
                    margin-top: -30%;
                    margin-left: 15px;
                    max-width: 100%;
                }
                @include media-breakpoint-up(lg) {
                    margin-left: 5%;
                }
            }

            &-2 {
                width: getFlowerWidth(143px, 375px); //, $containerWidth
                margin-left: auto;
                margin-right: 0;
                
                @include media-breakpoint-up(md) {
                    margin-left: getFlowerWidth(160px);
                    margin-top: 5%;
                    width: getFlowerWidth(510px); //, $containerWidth
                }
                
                @include media-breakpoint-up(lg) {
                    margin-top: 100px;
                }
            }
            
            &-3 {
                width: getFlowerWidth(120px, 375px); //, $containerWidth
                
                @include media-breakpoint-down(sm) {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    margin-top: -36%;
                }

                @include media-breakpoint-up(md) {
                    margin-left: auto;
                    width: getFlowerWidth(593px); //, $containerWidth
                    margin-right: -5.6%;
                }
            }

            &-4 {
                width: 116px; //getFlowerWidth(116px, 375px);
                position: absolute;
                right: 0;
                top: 0;
                z-index: -1;
                
                @include media-breakpoint-up(md) {
                    top: -5%;
                    width: getFlowerWidth(248px);
                }
            }
        }

        &__wrapper {
            
            width: 100%;
            display: flex;
            align-items: center;

            margin-top: 30px;
            // @include media-breakpoint-up(md) {
            // }
            
            @include media-breakpoint-up(lg) {
                margin-top: 70px;
            }
        }
    }
}