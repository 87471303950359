.dream-technology {
	@include make-section();
	padding-top: #{'min('get-vw(250px + 40px, 375px)', 420px)'};
	padding-bottom: get-vw(278px + 28px, 375px);
	
	background-color: #F2E9D6;

	@include media-breakpoint-up(md) {
		padding-top: get-vw(880px);
	}

	.the-last-frontier + & {
		@include media-breakpoint-up(md) {
			margin-top: -100px;
		}
		@include media-breakpoint-up(xl) {
			margin-top: -160px;
		}
	}

	&__foreground {
		z-index: 1;
	}

	&__background {
		position: absolute;
		width: 100%;
		top: -2px;
		left: 0;
		background-color: $cream;
		height: #{'calc(100% - 'get-vw(180px / 2, 375)' - 50px)'};
	
		@include media-breakpoint-up(md) {
			height: get-vw(630px - (385px / 2));
		}
		
		&::before {
			content: '';
			width: 120%;
			height: get-vw(180px, 375);
			position: absolute;
			bottom: 0;
			left: -10%;
			transform: translateY(50%);
			background-color: $cream;
			border-radius: 50%;
			
			@include media-breakpoint-up(md) {
				height: get-vw(385px);
			}
		}
	}
	
	&__image {
		mask-repeat: no-repeat;
		mask-position: center;
		mask-size: 100% 100%;
		
		padding: get-vw(6px, 375);
		
		position: absolute;
		
		background-color: $cream;

		@include media-breakpoint-up(md) {
			padding: get-vw(20px);
			background-color: $cream;
		}

		img {
			display: block;
			mask-repeat: no-repeat;
			mask-position: center;
			mask-size: 100% 100%;
			mask-image: inherit;

			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&--first {
			mask-image: get-mask-ellipse(-23deg, 580, 630, 565, 636);			
			width: #{'min('get-vw(220px + 12px, 375)', 360px)'};
			height: #{'min('get-vw(250px + 12px, 375)', 410px)'};
			left: 0;
			right: 0;
			top: 0;
			margin: auto;

			@include media-breakpoint-up(md) {
				top: 0;
				right: get-vw(80px);
				left: auto;
				width: get-vw(580px + 40px);
				height: get-vw(630px + 40px);
			}
		}

		&--second {
			mask-image: get-mask-ellipse(37deg, 545, 565, 522, 588);
			width: get-vw(150 + 12px, 375);
			height: get-vw(170 + 12px, 375);
			left: get-vw(-40px, 375);
			bottom: 0;
			
			@include media-breakpoint-up(md) {
				bottom: auto;
				top: get-vw(200);
				left: get-vw(384px);
				width: get-vw(545px + 40px);
				height: get-vw(565px + 40px);
			}
		}

		&--third {
			position: absolute;
			mask-image: get-mask-ellipse(36.78deg, 836, 865, 800, 900);
			mask-repeat: no-repeat;
			mask-position: center;

			width: get-vw(246px + 12px, 375);
			height: get-vw(278px + 12px, 375);
			right: get-vw(-95px, 375);
			bottom: get-vw(20px, 375);
		
			@include media-breakpoint-up(md) {
				width: get-vw(836px + 40px);
				height: get-vw(865px + 40px);
				top: get-vw(770px);
				left: get-vw(-290px);
			}
		}
	}
	
	&__inner {
		@include media-breakpoint-up(md) {
			margin-left: auto;
			width: get-vw(985px);
		}
	}
	
	&__title {
		@include make-section-title(80px);
		
		@include media-breakpoint-up(md) {
			margin-bottom: get-vw(60px);
		}
	}
}