.end-great-future-section {
    @extend .section;
    color: $cream;

    z-index: 2;

    .section {
        &__background {
            background-color: $retro-red;
            @include section-paper;
            // @include make-section-background
        }
        
        &__title {
            @include make-section-title(90px);

            span {
                color: $primary-dark !important;
            }

            // shape
            &::before {
                content: '';
                float: right;
                display: block;
                $width: get-abstract-size(48px, 375);
                width: #{'min(76px, #{$width})'};
                height: 1.1em;
                margin-right: -15px;

                @include media-breakpoint-up(lg) {
                    margin-right: -5.6%;
                }
            }
        }

        &__content {
            @include media-breakpoint-up(lg) {
                width: get-abstract-size(990, 1720px);
            }
        }

    }

    .airplane {
        $width: get-abstract-size(72px, 375);
        width: #{'min(104px, #{$width})'};
        height: (60px + (42 * 1.1));
        position: absolute;
        right: 0;
        top: 0;
        @include media-breakpoint-up(md) {
            height: (100px + 42px);
        }
        @include media-breakpoint-up(lg) {
            width: get-abstract-size(170px);
            height: auto;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: top right;
        }
    }

    .gallery {
        $drop-shadow: drop-shadow(19px 21px 90px rgba(0, 0, 0, 0.19));
        $shadow: 19px 21px 90px rgba(0, 0, 0, 0.19);
        // Law of sines
        // a = b * (sinα / sinβ)
        $angle: 12.51deg;
        $margin-top-angle: 50% * (sin($angle) / sin(90deg));
        $margin-top: get-abstract-size(30px);
        
        // filter: drop-shadow(19px 21px 90px rgba(0, 0, 0, 0.19));
        margin-top: $margin-top + $margin-top-angle;
        margin-bottom: get-abstract-size(-40px, 375px);
        transform: rotate(-#{$angle});
        z-index: -1;
        
        @include media-breakpoint-up(lg) {
            margin-top: get-abstract-size(80px);
            margin-bottom: get-abstract-size(-160px);
        }

        &-inner {
            display: flex;
            align-items: stretch;
            width: 110%;
        }

        &-item {
            padding-left: 5px;
            padding-right: 5px;

            @include media-breakpoint-up(sm) {
                padding-left: 10px;
                padding-right: 10px;
            } 

            @include media-breakpoint-up(lg) {
                padding-left: 20px;
                padding-right: 20px;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
        
        &-img {
            display: block;

            border: 4px solid $cream;
            background-color: $cream;
            border-radius: 12px;
            height: 22vw;
            box-shadow: $shadow;

            aspect-ratio: var(--aspect-ratio);

            @include media-breakpoint-up(sm) {
                border-width: 6px;
                border-radius: 30px;
            }

            @include media-breakpoint-up(lg) {
                border-width: 8px;
                border-radius: 40px;
            }

            @include media-breakpoint-up(xl) {
                border-width: 10px;
                border-radius: 60px;
            }
        }
    }
}