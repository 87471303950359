.usa-flag {
  @include make-section;
  @include make-section-typography();
  overflow: hidden;
  background-color: $sand;
  &__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  &__container {
    @include make-section-foreground;
  }
  &__background {
    @include make-section-background;
    top: 70%;
    height: 30%;
    @include media-breakpoint-up(sm) {
      left: 75%;
      width: 50%;
      height: 100%;
      top: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }
  &__content {
    @include media-breakpoint-up(sm) {
      @include make-col(8)
    }
  }
  &__ussr {
    position: relative;
    margin-top: 60px;
    @include media-breakpoint-up(md) {
      padding-left: 20%;
      &::before {
        content: '';
        background: url(../img/usa-flag/Coat_of_arms_of_the_USSR.png) no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 14%;
        display: block;
      }
    }
    @include media-breakpoint-up(lg) {
      padding-left: 10%;
      &::before {
        width: 7%;
      }
    }
  }
  &__title {
    @include make-section-title(80px);
    &--large {
      @include make-section-title(120px, 56px);
      @include media-breakpoint-down(sm) {
        position: relative;
        &::after {
          content: '';
          background: url(../img/usa-flag/Coat_of_arms_of_the_USSR.png) no-repeat;
          background-size: contain;
          height: 1.1em;
          width: 1.1em;
          vertical-align: bottom;
          display: inline-block;
        }

      }
    }
  }
  &__img {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
    @include media-breakpoint-up(sm) {
      @include make-col(4);
      padding-left: 5%;
      margin-top: 0;
      max-width: none;
    }
  }
  &__img-wrap {
    display: inline-block;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 15px;
      width: 100%;
      height: 65px;
      background: #100300;
      filter: blur(120px);
      transform: skewX(30deg);
    }
    img {
      position: relative;
      z-index: 1;
      border: 10px solid $cream;
    }
  }
}