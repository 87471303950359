.iron-sky {
  @include make-section();
  background-color: #F2E9D6;
  align-items: center;
  
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
  
  &__title {
    @include make-section-title(80px);
    margin-bottom: em(60, 80);
    word-break: break-all;
    
    @include media-breakpoint-up(lg) {
      max-width: 90%;
    }
  }
  & >.container {
    margin-bottom: 40px;
  
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      width: 50.5%;
      padding: 0 4%;
    }
  }
  
  &__image {
    margin-right: -10px;
    max-width: 290px;
    width: 100%;
  
    @include media-breakpoint-up(md) {
      width: 49.5%;
      max-width: none;
      position: relative;
      right: -20px;
    }
    
    &::before {
  
      @include media-breakpoint-up(md) {
		  content: '';
		  display: block;
		  padding-bottom: 79%;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
      border: 7px solid #FFFCF3;
  
      @include media-breakpoint-up(md) {
        border: 20px solid #FFFCF3;
	  	position: absolute;
		top: 0;
	  	left: 0;
      }
    }
  }
}