// boldest dreams section
.boldest-dreams-section {
    $drop-shadow: drop-shadow(19px 21px 90px rgba(0, 0, 0, 0.19));
    $shadow: 19px 21px 90px rgba(0, 0, 0, 0.19);
    $containerWidth: 1920px - (1920px / 10);
    $containerWidth-xs: 375px - (15px * 2);

    @extend .section;
    color: $white;

    @include media-breakpoint-down(md) {
        padding-top: #{'max(130px, #{get-abstract-size(367px + 50px)})'};
    }
    
    .section__title {
        margin-bottom: 20px;
    
        @include media-breakpoint-up(lg) {
            margin-bottom: 60px;
        }
        
        strong {
            display: block;
    
            @include media-breakpoint-up(lg) {
                display: inline;
            }
        }
    }

    .planet {
        // filter: $drop-shadow;
        z-index: -1;
        pointer-events: none;

        @include media-breakpoint-up(lg) {
            position: absolute;
        }

        &::after {
            padding-bottom: 100%;
        }

        img {
            display: block;
            aspect-ratio: 1;
            width: 100%;

            box-shadow: $shadow;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.19);
        }

        &__holder {
            @include media-breakpoint-down(md) {
                margin-top: 24px;
                display: flex;
                justify-content: space-between;
            }

            @include media-breakpoint-up(lg) {
            }
        }


        &--mars {
            width: 3.3em;
            height: 3.3em;
            left: 50%;
            transform: translateX(-100%);
            top: -0.5em;   
        }

        &--venus {
            max-width: 334px;
            width: get-abstract-size(190px, $containerWidth-xs);

            @include media-breakpoint-down(md) {
                margin-top: get-abstract-size(45px, $containerWidth-xs);
                margin-left: get-abstract-size(-35px, $containerWidth-xs);
            }
            
            @include media-breakpoint-up(lg) {
                left: get-abstract-size(250px, $containerWidth);
                top: 50%;
                transform: translateY(-50%);
                width: get-abstract-size(334px, $containerWidth);
            }
        }

        &--moon {
            max-width: 250px;
            width: get-abstract-size(110px, $containerWidth-xs);

            @include media-breakpoint-up(lg) {
                right: 0%;
                bottom: 100%;
                width: get-abstract-size(250px, $containerWidth);
            }
        }
    }

    .section {
        &__background {
            background-image: url(../img/boldest-dreams/boldest-dreams-bg.jpg);
            background-size: cover;
            background-color: $primary-dark;
        }

        &__title {
            position: relative;

            .planet {
                position: absolute;
            }

            @include media-breakpoint-up(lg) {
                padding: 0 get-abstract-size(250px, $containerWidth);
                text-align: center;
            }

            .line-indent {
                padding-left: 1.4em;
    
                @include media-breakpoint-up(lg) {
                    padding-left: 4em;
                }
            }
        }

        &__content {
            position: relative;
            margin-left: auto;
            margin-right: auto;

            @include media-breakpoint-up(lg) {
                padding-right: get-abstract-size(250px, $containerWidth);
                padding-left: get-abstract-size(687px, $containerWidth);
            }
        }
    }

    .diamond {
        z-index: 1;
        position: absolute;
        transform: rotate(-47.2deg);
        // filter: $drop-shadow;
        box-shadow: $shadow;
        background-color: $cream;
        border: 10px solid $cream;
        border-radius: 36px;
        width: #{'max(120px, #{get-abstract-size(367px + 20px)})'}; // 20px border
        overflow: hidden;

        left: -5.6%;
        top: -5%;

        @include media-breakpoint-down(md) {
            border-width: 5px;
            border-radius: 24px;
        }

        @include media-breakpoint-down(xs) {
            border-width: 2px;
            border-radius: 12px;
        }

        &::before {
            padding-bottom: 100%;
            content: '';
            display: block;
        }
        
        &__img {
            $scale: sqrt(2);

            position: absolute;
            left: 0;
            top: 0;
            transform: rotate(47.2deg) scale(#{$scale});
            width: 100%;
            aspect-ratio: 1;
        }
    }
}
